import type { GetLogsParams, LogQueryParams } from '../types';

export const mapParamsToRequestDto = ({
  project,
  date = '',
  email,
  action,
  itemType,
  q,
  page,
  perPage,
}: LogQueryParams): GetLogsParams => {
  const [start_date_from, start_date_to] = date.split(' - ');
  return {
    ...(project.length && { project_id: project.map((p) => p.id) }),
    ...(start_date_from && { start_date_from }),
    ...(start_date_to && { start_date_to }),
    user_email: email,
    action_type: action as string[],
    item_type: itemType as string[],
    item_name: q,
    limit: perPage,
    offset: page * perPage,
  };
};
