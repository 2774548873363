import type React from 'react';
import { useState } from 'react';
import type { InputProps } from '@mui/material';
import { TextField, Chip, Box, Typography, FormControl } from '@mui/material';
import { isEmpty } from 'lodash-es';

interface ChipInputProps extends Omit<InputProps, 'onChange'> {
  label?: string;
  inputLabel?: string;
  name: string;
  required?: boolean;
  onChange: (value: any) => void;
  helperText?: string;
}

const UncontrolledChipInput: React.FC<ChipInputProps> = ({
  label,
  inputLabel,
  name,
  required,
  value,
  onChange,
  helperText,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [chips, setChips] = useState<string[] | undefined>(isEmpty(value) ? undefined : String(value).split(','));

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (inputValue.length) {
        const newChips = isEmpty(chips) ? [inputValue] : [...chips!, inputValue];
        setChips(newChips);
        setInputValue('');
        onChange(newChips);
      }
    } else if (event.key === 'Backspace' && !inputValue.length) {
      const newChips = [...chips!];
      newChips.pop();
      setChips(newChips);
      onChange(newChips);
    }
  };

  const handleDelete = (index: number) => {
    const newChips = [...chips!];
    newChips?.splice(index, 1);
    setChips(newChips?.length ? newChips : []);
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      {label && !inputLabel && (
        <Typography variant="subtitle2" mb={1}>
          {label}
          {required && '*'}
        </Typography>
      )}
      <FormControl sx={{ flex: 1 }}>
        <TextField
          helperText={helperText}
          onChange={handleInputChange}
          value={inputValue}
          onKeyDown={handleKeyPress}
          sx={{
            '& .MuiOutlinedInput-root': {
              maxHeight: 'fit-content',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'start',
              gap: 1,
              py: 1,
              px: 1.25,
            },
            '& .MuiOutlinedInput-root input': {
              display: 'inline-flex',
              width: 'auto',
              minWidth: 120,
              py: 0,
              height: 24,
              borderRadius: 0,
            },
          }}
          InputLabelProps={{
            sx: {
              '&:not(.Mui-focused):not(.MuiInputLabel-shrink)': { transform: 'translate(14px, 8px) scale(1)' },
            },
          }}
          InputProps={{
            startAdornment: chips?.map((value, index) => (
              <Chip
                key={`${name}_chip_${index}`}
                label={value}
                onDelete={() => {
                  handleDelete(index);
                }}
                sx={{ '&': { m: 0, display: 'inline-flex' } }}
              />
            )),
          }}
          label={inputLabel}
          required={required}
        />
      </FormControl>
    </Box>
  );
};

export default UncontrolledChipInput;
