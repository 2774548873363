import type { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useWatch } from 'react-hook-form';
import { toJS } from 'mobx';
import bulkDuplicateStore from '../../stores/bulkDuplicateStore';
import { useRulesFlowMetrics } from '../../api';
import { Box, Tooltip, Typography } from '@mui/material';
import { ArrowForward, InfoOutlined } from '@mui/icons-material';
import SuspenseFallbackSkeleton from './SuspenseFallbackSkeleton';

const FlowMetrics: FC = observer(() => {
  const flowId = useWatch({ name: 'flow' });
  const rulesIds = toJS(bulkDuplicateStore.list).map((r) => r.id);

  const { data, isLoading } = useRulesFlowMetrics({
    params: {
      flow_id: Number(flowId),
      rules_ids: rulesIds ?? [],
    },
    config: {
      enabled: !!flowId,
    },
  });

  const rulesFlowMetrics = data?.data ?? [];

  if (!flowId)
    return (
      <Box sx={{ display: 'flex', gap: 1, width: '100%', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle2">Flow metrics</Typography>
          <Typography
            variant="caption"
            sx={(t) => ({ display: 'flex', alignItems: 'center', gap: 1, color: t.palette.text.secondary })}
          >
            Metrics will be updated automatically based on selected flow
          </Typography>
        </Box>
      </Box>
    );

  return isLoading ? (
    <SuspenseFallbackSkeleton />
  ) : rulesFlowMetrics.length ? (
    <Box sx={{ display: 'flex', gap: 1, width: '100%', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle2">Flow metrics</Typography>
        <Typography variant="caption" sx={(t) => ({ color: t.palette.text.secondary })}>
          {data?.metrics_count} {data && data?.metrics_count > 1 ? 'metrics' : 'metric'} from {data?.rules_count}{' '}
          {data && data?.rules_count > 1 ? 'rules' : 'rule'} will be updated based on the selected flow
        </Typography>
      </Box>
      <Box sx={{ flex: 1, display: 'flex', gap: 1, width: '100%', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'nowrap' }}>
          <Box sx={{ flex: '0 0 50%' }}>
            <Typography variant="subtitle2">Original rule</Typography>
          </Box>
          <Box sx={{ flex: '0 0 20px' }} />
          <Box sx={{ flex: '0 0 50%' }}>
            <Typography variant="subtitle2">New rule</Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', gap: 1, width: '100%', flexDirection: 'column', maxHeight: 400, overflowY: 'auto' }}
        >
          {rulesFlowMetrics.map((r, i) => (
            <Box
              key={`${i}.${r.rule_id}:${r.old_value}.${r.new_value}`}
              sx={{ display: 'flex', gap: 1, width: '100%', justifyContent: 'space-between', flexWrap: 'nowrap' }}
            >
              <Box sx={{ flex: 1, display: 'flex', gap: 0.5, flexWrap: 'nowrap', height: 36 }}>
                <Box sx={{ flex: '0 0 20px', display: 'flex', alignItems: 'center' }}>
                  <Tooltip title={r.rule_name} placement="left" arrow>
                    <InfoOutlined
                      sx={(t) => ({ fontSize: t.typography.h4.fontSize, color: t.palette.action.active, m: 0 })}
                    />
                  </Tooltip>
                </Box>
                <Box sx={{ position: 'relative', flex: 1 }}>
                  <Typography
                    variant="body2"
                    sx={(t) => ({
                      position: 'absolute',
                      color: t.palette.action.disabled,
                      border: `1px solid #e8e8e8`,
                      borderRadius: t.borderRadius.md,
                      padding: 1,
                      display: 'block',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      width: '100%',
                      overflow: 'hidden',
                      pointerEvents: 'none',
                    })}
                  >
                    {r.old_value}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ flex: '0 0 20px', display: 'flex', alignItems: 'center' }}>
                <ArrowForward />
              </Box>
              <Box sx={{ flex: 1, position: 'relative' }}>
                <Typography
                  variant="body2"
                  sx={(t) => ({
                    position: 'absolute',
                    border: `1px solid #e8e8e8`,
                    borderRadius: t.borderRadius.md,
                    padding: 1,
                    display: 'block',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    overflow: 'hidden',
                    pointerEvents: 'none',
                  })}
                >
                  {r.new_value}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  ) : null;
});

export default FlowMetrics;
