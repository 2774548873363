import { Box, Skeleton } from '@mui/material';

const SuspenseFallbackSkeleton = () => (
  <Box sx={{ display: 'flex', flex: 1, height: 40, flexDirection: 'column', gap: 1.5 }}>
    <Skeleton
      width={90}
      height={18}
      sx={(t) => ({
        transform: 'translateY(0)',
        m: 0,
        borderRadius: t.borderRadius.md,
      })}
    />
    <Skeleton
      width={'100%'}
      height={40}
      sx={(t) => ({
        transform: 'translateY(0)',
        m: 0,
        borderRadius: t.borderRadius.md,
      })}
    />
  </Box>
);

export default SuspenseFallbackSkeleton;
