import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import queryKeys from './queryKeys';

interface TriggerRuleResponse {
  data: unknown;
}

interface UpdateRulePayload {
  ruleId: number;
}

export const triggerRule = async ({ ruleId }: UpdateRulePayload): Promise<TriggerRuleResponse> => {
  const res = await apiClient.post<{ data: TriggerRuleResponse }>(`/rules/${ruleId}/trigger`);
  return res.data.data;
};

interface UseTriggerRuleOptions {
  config?: MutationConfig<typeof triggerRule>;
}

export const useTriggerRule = ({ config }: UseTriggerRuleOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.rule(args[1].ruleId),
        }),
        queryClient.invalidateQueries({
          queryKey: ['rules'],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: triggerRule,
  });
};
