import { Box, Table, TableContainer, TablePagination } from '@mui/material';
import { type FC } from 'react';
import { TableBody } from '@aws-amplify/ui-react';
import MetricsTableHead from './MetricsTableHead';
import MetricsTableRow from './MetricsTableRow';
import MetricDetails from './MetricDetails';
import useMetrics from '../hooks/useMetrics';
import { EditButton } from './MetricsModifyButtons';
import { isEmpty } from 'lodash-es';
import MetricsEmptyState from './MetricsComponents';

const MetricsTable: FC = () => {
  const {
    tableHead,
    tableRows,
    meta: { count },
    perPage,
    page,
    handlePageChange,
    handleRowsPerPageChange,
  } = useMetrics();

  return (
    <Box
      sx={{ position: 'relative', display: 'flex', flex: 1, flexDirection: 'column', width: '100%', height: '100%' }}
    >
      <Box sx={{ display: 'flex', position: 'relative', flex: 1, width: '100%' }}>
        <TableContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
        >
          {!isEmpty(tableRows) ? (
            <>
              <Table
                sx={{
                  '&': {
                    position: 'relative',
                    zIndex: 1,
                    border: 'none',
                    overflowX: 'auto',
                    width: '100%',
                  },
                }}
                stickyHeader
                size="small"
              >
                <MetricsTableHead columns={tableHead} />
                <TableBody>
                  {tableRows.map((metric) => (
                    <MetricsTableRow key={`${metric.id}.${metric.name}`} metric={metric} />
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <MetricsEmptyState />
          )}
        </TableContainer>
      </Box>
      <Box sx={{ flex: 1, maxHeight: 80, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <TablePagination
          labelRowsPerPage="Rows per page:"
          component="div"
          count={count}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={perPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[25, 50, 100]}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: 1,
            minHeight: 64,
            maxHeight: 64,
            backgroundColor: 'white',
          }}
        />
        {!isEmpty(tableRows) && <EditButton />}
      </Box>
      {!isEmpty(tableRows) && <MetricDetails />}
    </Box>
  );
};

export default MetricsTable;
