import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { FC, useCallback, useState } from 'react';
import { Box, Divider, ListItemIcon, MenuItem, Typography } from '@mui/material';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { useModal } from 'hooks';
import { useEmergencyContext } from '../context';
import useListParams from 'hooks/useListParams';
import { useToggleEmergency } from '../api';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';

const SidebarEmergencyItem: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { project } = useListParams();
  const { isEmergency, isEmergencyModeAllow, setEmergency } = useEmergencyContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { open, close, isOpen } = useModal();
  const { onShowAlert } = useEnqueueSnackbar();

  const isShow = isEmergencyModeAllow && project === 'all_projects';

  const toggleEmergency = useToggleEmergency({
    config: {
      onSuccess: (data) => {
        const isEnabled = data?.status === 'enabled';
        setEmergency(isEnabled);
        !isEnabled && onShowAlert(`All rules enabled`);
        onClose();
      },
    },
  });

  const handleConfirm = useCallback(() => {
    setErrorMessage(null);
    toggleEmergency.mutate({
      status: isEmergency ? 'disabled' : 'enabled',
    });
  }, [isEmergency, toggleEmergency, setErrorMessage]);

  const handleOpenDialog = useCallback(() => {
    open();
  }, [open]);

  return isShow ? (
    <>
      <Divider />
      <MenuItem onClick={handleOpenDialog}>
        <Box
          sx={(t) => ({
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            width: '100%',
          })}
        >
          <ListItemIcon>
            {isEmergency ? (
              <PlayCircleOutlineOutlinedIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
            ) : (
              <StopCircleOutlinedIcon fontSize="small" color="error" sx={{ mr: 1 }} />
            )}
          </ListItemIcon>
          <Typography
            sx={(t) => ({ fontSize: '0.875rem', color: isEmergency ? t.palette.primary.main : t.palette.error.main })}
          >
            {isEmergency ? 'Enable all rules' : 'Emergency stop'}
          </Typography>
        </Box>
      </MenuItem>
      <ConfirmDialog
        title={`${isEmergency ? 'Enable' : 'Stop'} all rules in all projects?`}
        text={
          <>
            <Typography variant="body1">
              {isEmergency
                ? 'Active rules will run according to their timetable. Paused and archived rules will remain their statuses as well.'
                : 'No rules will work. Statuses will remain the same and you will be able to turn it on again with 1 click'}
            </Typography>
            {!!errorMessage && (
              <Typography variant="body1" sx={(t) => ({ color: t.palette.error.main, pt: 2 })}>
                {errorMessage}
              </Typography>
            )}
          </>
        }
        confirmColorButton={isEmergency ? 'primary' : 'error'}
        confirmButton={isEmergency ? 'Enable all rules' : 'Stop all rules'}
        cancelButton="Cancel"
        isOpen={isOpen}
        isPending={toggleEmergency.isPending}
        close={close}
        onCancel={close}
        onConfirm={handleConfirm}
      />
    </>
  ) : null;
};

export default SidebarEmergencyItem;
