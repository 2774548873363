import { TableHead, TableRow, Typography } from '@mui/material';
import { type FC } from 'react';
import { useWatch } from 'react-hook-form';
import { TableCellStickyStyled } from './MetricsComponents';

const MetricsEditTableHead: FC<{ columns: string[] }> = ({ columns }) => {
  const tab = useWatch({ name: 'tab' });

  return (
    <TableHead sx={{ position: 'relative', zIndex: 3 }}>
      <TableRow sx={{ th: { height: 64, maxHeight: 64, px: 2 } }}>
        <TableCellStickyStyled
          align="left"
          sx={{ '&': { left: 0, top: 48, zIndex: 6, width: 280, minWidth: 280, maxWidth: 280 } }}
        >
          <Typography variant="body2" sx={(t) => ({ fontWeight: t.typography.fontWeightMedium })}>
            Metric
          </Typography>
        </TableCellStickyStyled>
        {tab === 'values' && columns && columns.length > 1 ? (
          columns.map((name) => (
            <TableCellStickyStyled key={name} align="left" sx={{ '&': { left: 0, top: 48 } }}>
              <Typography variant="body2" sx={(t) => ({ fontWeight: t.typography.fontWeightMedium })}>
                {name}
              </Typography>
            </TableCellStickyStyled>
          ))
        ) : (
          <TableCellStickyStyled align="left" sx={{ '&': { left: 0, top: 48, zIndex: 6 } }}>
            <Typography variant="body2" sx={(t) => ({ fontWeight: t.typography.fontWeightMedium })}>
              {tab === 'values' ? 'Value' : 'Descriptions'}
            </Typography>
          </TableCellStickyStyled>
        )}
      </TableRow>
    </TableHead>
  );
};

export default MetricsEditTableHead;
