import { Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { format } from 'date-fns';
import type { FC } from 'react';
import { useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Controller, useFormContext } from 'react-hook-form';

dayjs.extend(timezone);
dayjs.extend(utc);

interface FilterFromToProps {
  setParam: (val: string) => void;
  field: 'timeFrom' | 'timeTo';
}

const FilterFromTo: FC<FilterFromToProps> = ({ field, setParam }) => {
  const { control } = useFormContext();
  const today = dayjs.utc();

  const handleChange = useCallback(
    (date: {} | null, onChange: (...event: any[]) => void) => {
      const time = date ? format(new Date(date.toString()), 'HH:mm') : undefined;
      onChange(time);
      setParam(time!);
    },
    [setParam]
  );

  return (
    <Box sx={{ display: 'flex', width: '100%', minWidth: 100, maxWidth: 100 }}>
      <Controller
        key={`Controller:${field}`}
        name={field}
        control={control}
        render={({ field: { value, onChange } }) => {
          const dateTimeString = (value: string) =>
            dayjs
              .utc(today)
              .set('hour', parseInt(value.split(':')[0]))
              .set('minute', parseInt(value.split(':')[1]))
              .format('YYYY-MM-DDTHH:mm:ss');
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                ampm={false}
                value={value ? dayjs(dateTimeString(value)) : null}
                onChange={(e) => {
                  handleChange(e, onChange);
                }}
                label={field === 'timeFrom' ? 'From' : 'To'}
                closeOnSelect={false}
                name={field}
                minutesStep={15}
                views={['hours', 'minutes']}
                format="hh:mm"
                slotProps={{
                  textField: { size: 'small', disabled: true },
                  actionBar: {
                    actions: ['clear'],
                  },
                  popper: {
                    sx: { '& .Mui-disabled': { display: 'none' } },
                  },
                }}
              />
            </LocalizationProvider>
          );
        }}
      />
    </Box>
  );
};

export default FilterFromTo;
