import { useProjects } from '../api';
import type { ControlledSelectProp } from 'components/common/Select';
import Select from 'components/common/Select';
import type { FC } from 'react';
import type { Option } from 'types/shared';
import { useMe } from '../../users';

type ProjectSelectProps = Omit<ControlledSelectProp, 'options'> & {
  onlyUsersProjects?: boolean;
  filter?: (i: Option) => Boolean;
};

const ProjectSelect: FC<ProjectSelectProps> = (props) => {
  const { data: me } = useMe();

  const { data: projects } = useProjects<Option[]>({
    config: {
      select: (data) => {
        let allProjects = data.map(({ name, id }) => ({ value: id, label: name }));

        // Apply filter if callback exists
        if (props.filter) {
          allProjects = allProjects.filter(props.filter);
        }

        if (props.onlyUsersProjects) {
          if (me?.role === 'admin' || me?.role === 'supervisor') {
            return allProjects;
          }

          const myProjects = me?.projects?.length ? me.projects.map((p) => p.id) : [];
          return allProjects.filter((p) => myProjects.includes(p.value));
        }

        return allProjects;
      },
    },
  });
  return <Select {...props} options={projects} />;
};

export default ProjectSelect;
