import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import type { CheckboxProps, SxProps, Theme } from '@mui/material';
import type { FC } from 'react';

interface CheckboxComponentProps extends Omit<CheckboxProps, 'name' | 'onChange'> {
  name: string;
  label?: string;
  onChange?: (checked: boolean) => void;
  controlLabelSx?: SxProps<Theme>;
}
const Checkbox: FC<CheckboxComponentProps> = ({ name, label, onChange, controlLabelSx, ...restProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <FormControlLabel
            control={
              <MuiCheckbox
                checked={!!field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  field.onChange(event, checked);
                  typeof onChange === 'function' && onChange(checked);
                }}
                inputProps={{ 'aria-label': label }}
                {...restProps}
              />
            }
            label={label}
            sx={controlLabelSx}
          />
        );
      }}
    />
  );
};

export default Checkbox;
