import { Box } from '@mui/material';
import type { InputProps } from 'components/common/Input';
import Input from 'components/common/Input';
import { debounce } from 'lodash-es';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useMemo } from 'react';

interface FilterSearchFieldProps extends InputProps {
  setParam: (val: string) => void;
}

const FilterSearchField: FC<FilterSearchFieldProps> = ({ setParam, ...restProps }) => {
  const setQueryStringDebounce = useMemo(() => debounce(setParam, 300), [setParam]);
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQueryStringDebounce(e.target.value);
    },
    [setQueryStringDebounce]
  );

  return (
    <Input
      containerSx={{ width: '100%' }}
      size="small"
      {...restProps}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        handleChange(e);
      }}
    />
  );
};

export default FilterSearchField;
