import { Box, Typography } from '@mui/material';
import IconAroundStars from 'assets/icon-around-stars.svg?react';

const LogsEmptyState = () => {
  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        gap: 2,
        minHeight: 176,
        p: 2.5,
        background: t.palette.action.hover,
      })}
    >
      <IconAroundStars height={120} width={207} />
      <Typography variant="h4" sx={(t) => ({ color: t.palette.text.secondary })}>
        No logs in history
      </Typography>
    </Box>
  );
};

export default LogsEmptyState;
