import { useMyAccess } from 'features/users';
import { useWatch } from 'react-hook-form';

const useAccessModify = () => {
  const project = useWatch({ name: 'project' });
  const myAccess = useMyAccess({ id: parseInt(project) });

  const accessMetrics = {
    accessModify: myAccess?.metrics.includes('create') || myAccess?.metrics.includes('update'),
    addToGroup: myAccess?.metrics.includes('add-to-group'),
    removeFromGroup: myAccess?.metrics.includes('remove-from-group'),
  };

  return {
    accessMetrics,
    myAccess,
  };
};

export default useAccessModify;
