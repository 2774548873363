import { apiClient } from '../../../lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { isAxiosError } from 'axios';

interface AddMetricsToGroupsPromise {
  metrics_ids: number[];
  groups_ids: number[];
}
interface AddMetricsToGroupsErrorPromise {
  message: string;
}

type QueryPromise = AddMetricsToGroupsPromise | AddMetricsToGroupsErrorPromise;

export const addMetricsToGroups = async (body: AddMetricsToGroupsPromise): Promise<QueryPromise> => {
  const res = await apiClient.post<QueryPromise>(`/groups/add_metrics`, body);
  if (isAxiosError(res)) {
    const error = new Error('Add metrics to groups');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseAddMetricsToGroupsOptions {
  config?: MutationConfig<typeof addMetricsToGroups>;
}

export const useAddMetricsToGroups = ({ config }: UseAddMetricsToGroupsOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['groups'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['metrics'],
        }),
      ]);

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: addMetricsToGroups,
  });
};
