import { Box, Skeleton, Tab, Table, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@mui/material';
import { type FC } from 'react';
import { TableBody } from '@aws-amplify/ui-react';

const MetricsEditSkeleton: FC = () => {
  return (
    <Box sx={{ position: 'relative', flex: 1, flexGrow: 1, width: '100%' }}>
      <TableContainer
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 6,
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%',
            height: 48,
          }}
        >
          <Tabs>
            <Tab label="Values" value={'values'} />
            <Tab label="Details" value={'details'} />
          </Tabs>
        </Box>
        <>
          <Table
            sx={{
              '&': {
                position: 'relative',
                zIndex: 1,
                border: 'none',
              },
            }}
            stickyHeader
            size="small"
          >
            <TableHead sx={{ position: 'relative', zIndex: 3 }}>
              <TableRow sx={{ th: { height: 64, maxHeight: 64, px: 2 } }}>
                <TableCell align="left" sx={{ '&': { width: 340, maxWidth: 340 } }}>
                  <Skeleton width={140} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                </TableCell>
                <TableCell align="left">
                  <Skeleton width={80} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                </TableCell>
                <TableCell align="left">
                  <Skeleton width={80} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                </TableCell>
                <TableCell align="left">
                  <Skeleton width={80} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                </TableCell>
                <TableCell align="left">
                  <Skeleton width={80} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                </TableCell>
                <TableCell align="left">
                  <Skeleton width={80} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from(Array(10).keys()).map((i) => (
                <TableRow sx={{ td: { height: 64, px: 2 } }} key={`metricsEditSkeletonItem_${i}`}>
                  <TableCell>
                    <Skeleton width={90} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={90} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={90} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={90} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={90} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={90} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      </TableContainer>
    </Box>
  );
};

export default MetricsEditSkeleton;
