import type { FC } from 'react';
import type { InputProps } from '@mui/material';
import { TextField } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';

interface RulTimetableInputProps extends Pick<InputProps, 'placeholder'> {
  value: string;
  onChange: (value: string) => boolean;
}

const RuleTimetableInput: FC<RulTimetableInputProps> = observer(({ onChange, value, placeholder }) => {
  const [innerValue, setInnerValue] = useState(value);

  const handleInnerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInnerValue(event.target.value);
  };

  return (
    <>
      <TextField
        onBlur={() => {
          const isSuccess = onChange(innerValue);
          if (!isSuccess) {
            setInnerValue(value);
          }
        }}
        variant="outlined"
        value={innerValue}
        onChange={handleInnerChange}
        fullWidth
        placeholder={placeholder}
        multiline={false}
      />
    </>
  );
});

export default RuleTimetableInput;
