import { Box, Tab, Table, TableContainer, TablePagination, Tabs } from '@mui/material';
import { type FC, useCallback, useEffect } from 'react';
import { TableBody } from '@aws-amplify/ui-react';
import MetricsEditTableRow from './MetricsEditTableRow';
import MetricsEditTableHead from './MetricsEditTableHead';
import useMetrics from '../hooks/useMetrics';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash-es';
import MetricsModifyButtons from './MetricsModifyButtons';
import MetricsEmptyState from './MetricsComponents';
import { useMe, useMyAccess } from 'features/users';
import { Navigate } from 'react-router';
import ProgressSkeleton from 'components/layout/ProgressSkeleton';
import { editMetricsSchema } from '../validation/editMetrics.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import useProjectConditions from '../hooks/useProjectConditions';
import useAccessModify from '../hooks/useAccessModify';

const MetricTabs = () => {
  const { setValue } = useFormContext();
  const value = useWatch({ name: 'tab' });

  const handleChange = useCallback(
    (_: React.SyntheticEvent, value: string) => {
      setValue('tab', value);
    },
    [setValue]
  );

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 6,
        borderBottom: 1,
        borderColor: 'divider',
        width: '100%',
        height: 48,
      }}
    >
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Values" value={'values'} />
        <Tab label="Details" value={'details'} />
      </Tabs>
    </Box>
  );
};

const MetricsEdit: FC = () => {
  const { data: me, isPending } = useMe();
  const { isNotAssigned } = useProjectConditions();
  const { accessMetrics } = useAccessModify();
  const {
    tableHead,
    tableRows,
    meta: { count },
    perPage,
    page,
    handlePageChange,
    handleRowsPerPageChange,
  } = useMetrics();
  const formMethods = useForm({
    resolver: yupResolver(editMetricsSchema),
    defaultValues: {
      tab: 'values',
      metrics_edit: {
        values: Object.assign(
          {},
          ...tableRows.map((metric) => {
            return metric.projects.reduce(
              (accum, prj) => ({
                ...accum,
                [`${metric.id}:${prj.id}`]: prj.value ?? '',
              }),
              {}
            );
          })
        ),
        descriptions: tableRows.reduce(
          (accum, metric) => ({
            ...accum,
            [`${metric.id}`]: metric.description,
          }),
          {}
        ),
      },
    },
  });

  useEffect(() => {
    return () => {
      formMethods.reset();
    };
  }, [formMethods]);

  if (isPending)
    return (
      <Box
        sx={{
          position: 'relative',
          flex: 1,
          flexGrow: 1,
          width: '100%',
          height: '100%',
        }}
      >
        <ProgressSkeleton />
      </Box>
    );

  if ((!accessMetrics.accessModify && !isPending) || isNotAssigned) {
    return <Navigate to={'/metrics?project=all_projects'} />;
  }

  return (
    <FormProvider {...formMethods}>
      <Box
        sx={{ position: 'relative', display: 'flex', flex: 1, flexDirection: 'column', width: '100%', height: '100%' }}
      >
        <Box sx={{ display: 'flex', position: 'relative', flex: 1, width: '100%' }}>
          <TableContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'absolute',
              width: '100%',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <MetricTabs />
            {!isEmpty(tableRows) ? (
              <>
                <Table
                  sx={{
                    '&': {
                      position: 'relative',
                      zIndex: 1,
                      border: 'none',
                      overflowX: 'auto',
                      width: '100%',
                    },
                  }}
                  stickyHeader
                  size="small"
                >
                  <MetricsEditTableHead columns={tableHead} />
                  <TableBody>
                    {tableRows.map((metric) => (
                      <MetricsEditTableRow key={`metrics_edit.${metric.id}.${metric.name}`} metric={metric} />
                    ))}
                  </TableBody>
                </Table>
              </>
            ) : (
              <MetricsEmptyState />
            )}
          </TableContainer>
        </Box>
        <Box sx={{ flex: 1, maxHeight: 80, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <TablePagination
            labelRowsPerPage="Rows per page:"
            component="div"
            count={count}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={perPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[25, 50, 100]}
            sx={{
              position: 'relative',
              zIndex: 4,
              display: 'flex',
              justifyContent: 'flex-end',
              flexGrow: 1,
              minHeight: 54,
              maxHeight: 54,
              backgroundColor: 'white',
            }}
          />
          {!isEmpty(tableRows) && <MetricsModifyButtons />}
        </Box>
      </Box>
    </FormProvider>
  );
};

export default MetricsEdit;
