import type { FC } from 'react';
import type { TextFieldProps, AutocompleteProps, SxProps, Theme } from '@mui/material';
import { Autocomplete, Box, createFilterOptions, TextField, Typography } from '@mui/material';
import type * as React from 'react';
import type { AutocompleteChangeReason, AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete';
import type { Option, OptionValue } from 'types/shared';
import { useMemo } from 'react';
import { useFlows } from '../api/getFlows';
import filterStore from '../stores/filterStore';

interface RuleEditAutocompleteProps
  extends Pick<AutocompleteProps<OptionValue, false, boolean, false>, 'disableClearable' | 'sx'> {
  value?: OptionValue;
  onChange: (value: OptionValue) => void;
  InputProps?: TextFieldProps;
  id: string;
  isLoading?: boolean;
  options: Option[];
  allSelectable?: boolean;
  sx?: SxProps<Theme>;
}

interface RuleEditAutocompleteMultipleProps
  extends Pick<AutocompleteProps<OptionValue, true, boolean, false>, 'disableClearable' | 'sx'> {
  value: OptionValue[];
  onChange: (value: OptionValue[]) => void;
  InputProps?: TextFieldProps;
  id: string;
  isLoading?: boolean;
  options: Option[];
  allSelectable?: boolean;
  sx?: SxProps<Theme>;
}

export const RuleEditAutocomplete: FC<RuleEditAutocompleteProps> = ({
  id,
  options,
  isLoading,
  onChange,
  value,
  InputProps,
  ...props
}) => {
  const filter = createFilterOptions<Option>();

  const derivedValue = useMemo(() => options.find((o) => value === o.value), [value, options]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: AutocompleteValue<Option, false, false, false>,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      onChange(newValue?.value);
    } else if (reason === 'clear') {
      onChange('');
    }
  };

  return (
    <Autocomplete
      {...props}
      size="small"
      sx={{
        '& fieldset': InputProps?.label ? { top: 0 } : {},
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          borderWidth: 1,
        },
        '& .MuiOutlinedInput-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        '& .MuiInputBase-root': { borderRadius: '0 !important' },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
      disabled={isLoading ?? InputProps?.disabled}
      loading={isLoading}
      id={id}
      options={options}
      disableClearable
      value={derivedValue ?? { label: '', value: '' }} // eslint-disable-line
      isOptionEqualToValue={(option: Option, value?: Option) => {
        if (!value || value.value === '') return false; // ignore the default value
        return option.value === value.value;
      }}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      multiple={false}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField {...params} label={InputProps?.label} placeholder={InputProps?.placeholder} />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        return filtered;
      }}
    />
  );
};

export const RuleEditAutocompleteMultiple: FC<RuleEditAutocompleteMultipleProps> = ({
  id,
  options,
  isLoading,
  onChange,
  value,
  InputProps,
  ...props
}) => {
  const filter = createFilterOptions<Option>();

  const derivedValue = useMemo(() => options.filter((o) => value.includes(o.value)), [value, options]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: AutocompleteValue<Option, true, false, false>,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      onChange(newValue.map((o) => o.value));
    } else if (reason === 'clear') {
      onChange([]);
    }
  };

  return (
    <Autocomplete
      {...props}
      size="small"
      sx={{
        '& fieldset': InputProps?.label ? { top: 0 } : {},
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiOutlinedInput-root': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        '& .MuiInputBase-root': { borderRadius: '0 !important' },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
      multiple
      disabled={isLoading ?? InputProps?.disabled}
      loading={isLoading}
      id={id}
      options={options}
      disableClearable
      value={derivedValue ?? []} // eslint-disable-line
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      style={{ width: '100%' }}
      renderOption={(props, option, state, ownerState) => {
        const { ...optionProps } = props;
        return (
          <Box
            sx={{
              borderRadius: 1,
              my: 1,
            }}
            component="li"
            {...optionProps}
          >
            <Typography sx={{ p: 1 }} variant="body2">
              {ownerState.getOptionLabel(option)}
            </Typography>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={InputProps?.label}
          placeholder={derivedValue.length ? '' : InputProps?.placeholder}
        />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        return filtered;
      }}
    />
  );
};
