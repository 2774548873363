import type { TestContext } from 'yup';

export function intervalHoursValidation(this: TestContext, value: (number | undefined)[]) {
  try {
    if (!value.length) return true;
    const condition = (hour: number) => hour >= 0 && hour <= 24 && !isNaN(Number(hour)) && typeof hour === 'number';

    // @ts-expect-error types correction
    const isValid = value.every(condition);
    if (!isValid) {
      throw new Error('Hours should be in range from 0 to 24');
    }

    return true;
  } catch (e) {
    // @ts-expect-error error type correction
    return this.createError({ message: String(e.message) });
  }
}
