import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { FilterOptionsState, AutocompleteProps, SxProps, TextFieldProps, Theme } from '@mui/material';
import { Autocomplete, Box, Button, createFilterOptions, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useModal } from 'hooks';
import AddIcon from '@mui/icons-material/Add';
import { isEmpty } from 'lodash-es';
import type { Option, OptionValue } from '../../../types/shared';
import { useFlows } from '../api/getFlows';
import filterStore from '../stores/filterStore';
import type * as React from 'react';
import type { AutocompleteChangeReason } from '@mui/base/useAutocomplete/useAutocomplete';
import AddFlowDialog from '../api/AddFlowDialog';
import { useFormContext } from 'react-hook-form';

type SingleAutocompleteType = AutocompleteProps<OptionValue, false, boolean, false>;

const filter = createFilterOptions<CustomOption>();
interface FlowAutocompleteProps extends Pick<SingleAutocompleteType, 'disableClearable' | 'sx'> {
  value: OptionValue;
  onChange: (value: OptionValue) => void;
  InputProps?: TextFieldProps;
  id: string;
  isLoading?: boolean;
  allSelectable?: boolean;
  sx?: SxProps<Theme>;
}

interface CustomOption extends Option {
  type?: string;
}

const FlowAutocomplete: FC<FlowAutocompleteProps> = ({ onChange, value }: FlowAutocompleteProps) => {
  const { isOpen: isFocused, toggle: onToggle } = useModal(false);

  const { watch } = useFormContext();

  const projectId = watch('project');

  const [dialogValue, setDialogValue] = useState<string>('');
  const { data: options } = useFlows<CustomOption[]>({
    params: {
      project_id: projectId,
    },
    config: {
      select: (data) => data.map(({ name }) => ({ label: name, value: name })),
    },
  });

  const derivedValue = useMemo(() => options.find((o) => value === o.value), [options, value]);

  useEffect(() => {
    if (!derivedValue) {
      onChange(undefined);
    }
  }, [derivedValue, onChange]);

  const { isOpen: isModalOpen, open: openModal, close: closeModal } = useModal();

  const handleChange = (event: React.SyntheticEvent, newValue: CustomOption, reason: AutocompleteChangeReason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      onChange(newValue.value);
    } else if (reason === 'clear') {
      onChange(undefined);
    }
  };

  const handleSelectNewFlow = (option: CustomOption) => {
    onChange(option.value);
  };

  if (!options) return <span>...Loading</span>;

  return (
    <>
      <Autocomplete
        id="flows-autocomplete"
        size="small"
        sx={{
          '& fieldset': {},
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
          '& .MuiInputBase-root': { borderRadius: '0 !important' },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        options={options}
        filterOptions={(options, params: FilterOptionsState<Option>) => {
          const filtered = filter(options, params);
          if (isEmpty(filtered)) {
            filtered.push({
              type: 'custom',
              value: params.inputValue,
              label: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        // @ts-expect-error incorrect types inheritance
        onChange={handleChange}
        value={derivedValue}
        onFocus={onToggle}
        onBlur={onToggle}
        open={isFocused}
        fullWidth
        disableCloseOnSelect
        disableClearable={false}
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: 'flip',
                enabled: false,
              },
            ],
          },
        }}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option: CustomOption, state, ownerState) => {
          if (option.type === 'custom') {
            return (
              <Stack sx={{ px: 1 }}>
                <Typography color="#000" textAlign="center" marginBottom={1} fontWeight={700}>
                  No matching flows
                </Typography>
                <Tooltip
                  title={
                    value
                      ? 'Please remove selected value before adding new flow, because it will be automatically selected'
                      : ''
                  }
                  placement="top"
                  arrow
                >
                  <Button
                    id="add-tag-button"
                    variant="outlined"
                    onClick={() => {
                      if (!value) {
                        setDialogValue(String(option.value));
                        openModal();
                      }
                    }}
                  >
                    <AddIcon fontSize="small" sx={{ mr: 1 }} /> {option.label}
                  </Button>
                </Tooltip>
              </Stack>
            );
          }

          return (
            <Box sx={{ borderRadius: 1, my: 1 }} component="li" {...props} key={option.value}>
              <Typography sx={{ p: 1 }} variant="body2">
                {ownerState.getOptionLabel(option)}
              </Typography>
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              id: 'flows-autocomplete-field',
            }}
          />
        )}
      />
      <AddFlowDialog dialogValue={dialogValue} isOpen={isModalOpen} close={closeModal} onChange={handleSelectNewFlow} />
    </>
  );
};

export default FlowAutocomplete;
