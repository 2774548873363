import { useQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { UserEntry } from './types';
import type { SynchronousQueryConfig } from 'lib/react-query';

export const getUser = async (id: number, signal?: AbortSignal): Promise<UserEntry> => {
  const res = await apiClient.get<UserEntry>(`/users/${id}`, {
    signal,
  });
  return res.data;
};

interface UseUserOptions<TData> {
  config?: SynchronousQueryConfig<typeof getUser, TData>;
  id: number;
}

export const useUser = <TData = UserEntry>({ id, config }: UseUserOptions<TData>) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getUser(id, signal),
    queryKey: ['user', id] as QueryKey,
  });
};
