import { ListItemIcon, ListItemText, ListItem, Button, Typography } from '@mui/material';
import type { CSSProperties, FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { Link, useLocation, useMatch, useResolvedPath } from 'react-router-dom';

interface SidebarNavItemProps {
  replace?: boolean;
  to?: string;
  icon?: ReactNode;
  title: ReactNode;
  style?: CSSProperties;
  disabled?: boolean;
  onClick?: () => void;
}

const SidebarNavItem: FC<SidebarNavItemProps> = ({ replace, to, icon, title, style, disabled, onClick, ...props }) => {
  const path = useResolvedPath(to ?? '');
  const location = useLocation();
  const match = useMatch({ path: path.pathname, caseSensitive: true });
  const isActive = match != null;
  const component = to ? Link : 'button';

  const onClickProp = useMemo(() => {
    if (to) return undefined;
    if (onClick) return onClick;

    return undefined;
  }, [to, onClick]);

  return (
    <ListItem style={{ ...style }} {...props} sx={{ p: 0 }}>
      <Button
        variant="text"
        component={component}
        to={to}
        replace
        disableElevation
        disableRipple={isActive}
        disableTouchRipple
        disableFocusRipple
        sx={(t) => ({
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          py: 1,
          fontSize: t.typography.caption.fontSize,
          textDecoration: 'none',
          textTransform: 'capitalize',
          borderRadius: 0,
          color: isActive ? t.palette.primary.main : t.palette.text.secondary,
          backgroundColor: isActive ? 'rgba(41, 121, 255, 0.08)' : 'transparent',
          boxShadow: isActive ? `inset 2px 0px 0px ${t.palette.primary.main}` : 'none',

          '&:hover': {
            color: t.palette.primary.main,
            backgroundColor: isActive ? 'rgba(41, 121, 255, 0.08)' : 'transparent',
            boxShadow: isActive ? `inset 2px 0px 0px ${t.palette.primary.main}` : 'none',
          },
          '&:hover .MuiSvgIcon-root': {
            color: t.palette.primary.main,
          },
          '&[disabled]': {
            color: t.palette.text.disabled,
          },
        })}
        onClick={onClickProp}
        disabled={disabled}
      >
        <ListItemIcon
          sx={(t) => ({
            justifyContent: 'center',
            color: isActive ? t.palette.primary.main : t.palette.text.secondary,
          })}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              sx={(t) => ({ fontSize: t.typography.caption.fontSize, fontWeight: 500, letterSpacing: '0.4px' })}
            >
              {title}
            </Typography>
          }
        />
      </Button>
    </ListItem>
  );
};

export default SidebarNavItem;
