import { createRoot } from 'react-dom/client';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import config from 'config';

Sentry.init({
  dsn: config?.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/autorules-admin\.asqq\/.io/, /^https:\/\/autorules-api-admin\.asqq\/.io/], // ['localhost', /^https:\/\/autorules-admin\.asqq\/.io/]
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('app');
if (!container) {
  throw new Error('React container not found');
}

const root = createRoot(container);
root.render(<App />);
