import useEnqueueSnackbar from '../../../../hooks/useEnqueueSnackbar';
import { useCallback } from 'react';
import bulkDuplicateStore from '../../stores/bulkDuplicateStore';
import { Box, Button, Typography } from '@mui/material';

const AddPostfix = () => {
  const { onShowAlert, onShowErrorAlert } = useEnqueueSnackbar();
  const handleAddPostfix = useCallback(async () => {
    try {
      const r = await bulkDuplicateStore.addPostfix();

      onShowAlert(r.message, { delayAlert: 3500 });
    } catch (err) {
      onShowErrorAlert((err as { message: string }).message, { delayAlert: 3500 });
    }
  }, [bulkDuplicateStore]);

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        alignItems: 'center',
        maxHeight: 24,
      }}
    >
      <Button variant="text" size="small" onClick={handleAddPostfix}>
        Add postfix
      </Button>
      <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>{`"duplicated_{date}"`}</Typography>
    </Box>
  );
};

export default AddPostfix;
