import { observer } from 'mobx-react-lite';
import bulkDuplicateStore from '../../stores/bulkDuplicateStore';
import { useFormContext, useWatch } from 'react-hook-form';
import useEnqueueSnackbar from '../../../../hooks/useEnqueueSnackbar';
import type { SyntheticEvent } from 'react';
import { useCallback } from 'react';
import { Box, Button } from '@mui/material';
import Input from '../../../../components/common/Input';
import { CachedOutlined } from '@mui/icons-material';
import AddPostfix from './AddPostfix';

const ReplaceName = observer(() => {
  const isRenamingApplied = bulkDuplicateStore.isRenamingApplied;
  const nameField = useWatch({ name: 'name' });
  const replaceWithField = useWatch({ name: 'replaceWith' });
  const {
    resetField,
    formState: { errors },
  } = useFormContext();
  const { onShowAlert, onShowErrorAlert } = useEnqueueSnackbar();

  const handleResetForm = useCallback(() => {
    resetField('name');
    resetField('replaceWith');
  }, [resetField]);

  const handleRenameRules = useCallback(
    async (e: SyntheticEvent) => {
      e.stopPropagation();
      try {
        const r = await bulkDuplicateStore.renameRules(nameField, replaceWithField);
        onShowAlert(r.message, { delayAlert: 3500 });
        handleResetForm();
      } catch (err) {
        onShowErrorAlert((err as { message: string }).message, { delayAlert: 3500 });
      }
    },
    [nameField, replaceWithField, bulkDuplicateStore, onShowAlert, handleResetForm]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', position: 'relative' }}>
      <AddPostfix />
      <Input
        name="name"
        label={isRenamingApplied ? 'Name' : 'Name*'}
        placeholder="Find"
        helperText="Type part of the name that has to be replaced in each rule"
      />
      <Input name="replaceWith" placeholder="Replace with" containerSx={{ width: '100%' }} />
      <Box>
        <Button
          disabled={!nameField?.length || !replaceWithField?.length}
          startIcon={<CachedOutlined />}
          variant="text"
          size="small"
          onClick={handleRenameRules}
        >
          Rename and update
        </Button>
      </Box>
    </Box>
  );
});

export default ReplaceName;
