import { Table, TableBody, TableCell, TableContainer, Skeleton, TableRow, Typography, TableHead } from '@mui/material';
import useListParams from 'hooks/useListParams';

const nextRunLabel = (
  <Typography variant="body2" fontWeight="500">
    Next run
  </Typography>
);

const RulesTableHeadSkeleton = () => {
  const { tab } = useListParams();
  const isActiveOrAll = tab === 'active' || tab === 'all';
  const isPaused = tab === 'paused';
  const isArchived = tab === 'archived';

  return (
    <TableHead sx={{ position: 'relative', zIndex: 3 }}>
      <TableRow sx={{ th: { height: 56, border: 'none', fontWeight: 'bold', py: 1, px: 1 } }}>
        <TableCell align="center">
          <Skeleton width={20} height={20} sx={{ transform: 'translateY(0)', margin: '0 auto', borderRadius: 1 / 2 }} />
        </TableCell>
        <TableCell align="center" sx={{ width: 52, maxWidth: 52 }}>
          <Skeleton width={32} height={18} sx={{ transform: 'translateY(0)', borderRadius: 1 }} />
        </TableCell>
        <TableCell sx={{ minWidth: '200px', maxWidth: '300px' }}>
          <Typography variant="body2" fontWeight="500">
            Name
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2" fontWeight="500">
            Status
          </Typography>
        </TableCell>
        {isPaused && (
          <TableCell>
            <Typography variant="body2" fontWeight="500">
              Paused
            </Typography>
          </TableCell>
        )}
        {isArchived && (
          <TableCell>
            <Typography variant="body2" fontWeight="500">
              Archived
            </Typography>
          </TableCell>
        )}
        {isActiveOrAll && (
          <TableCell>
            <Typography variant="body2" fontWeight="500">
              Last run
            </Typography>
          </TableCell>
        )}
        <TableCell>{isActiveOrAll ? nextRunLabel : nextRunLabel}</TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="500">
            Rule group
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="500">
            Flow
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" fontWeight="500">
            Level
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Skeleton width={16} height={18} sx={{ transform: 'translateY(0)' }} />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const RulesTableSkeleton = () => {
  return (
    <TableContainer
      sx={{
        position: 'relative',
        height: '92%',
      }}
    >
      <Table
        sx={{
          '&': {
            border: 'none',
            position: 'absolute',
          },
        }}
        stickyHeader
        size="small"
      >
        <RulesTableHeadSkeleton />
        <TableBody>
          {[...Array(15).keys()].map((_, index) => (
            <TableRow
              key={`rulesTableSkeletonItem_${index}`}
              sx={{
                '& td': {
                  p: 1,
                },
                '&:hover': {
                  background: 'rgba(41, 121, 255, 0.08)',
                },
              }}
            >
              <TableCell align="center">
                <Skeleton
                  width={20}
                  height={20}
                  sx={{ transform: 'translateY(0)', margin: '0 auto', borderRadius: 1 / 2 }}
                />
              </TableCell>
              <TableCell align="center">
                <Skeleton width={32} height={18} sx={{ transform: 'translateY(0)', m: 0, p: 0, borderRadius: 1 }} />
              </TableCell>
              <TableCell align="center" sx={{ width: 300, maxWidth: 300 }}>
                <Skeleton width={200} height={18} sx={{ transform: 'translateY(0)' }} />
              </TableCell>
              <TableCell sx={{ maxWidth: 64 }}>
                <Skeleton width={28} height={18} sx={{ transform: 'translateY(0)', margin: '0 auto' }} />
              </TableCell>
              <TableCell sx={{ maxWidth: 110, width: 110 }}>
                <Skeleton width={80} height={18} sx={{ transform: 'translateY(0)' }} />
              </TableCell>
              <TableCell sx={{ maxWidth: 110, width: 110 }}>
                <Skeleton width={80} height={18} sx={{ transform: 'translateY(0)' }} />
              </TableCell>
              <TableCell sx={{ maxWidth: 90 }}>
                <Skeleton width={80} height={18} sx={{ transform: 'translateY(0)' }} />
              </TableCell>
              <TableCell sx={{ maxWidth: 90 }}>
                <Skeleton width={80} height={18} sx={{ transform: 'translateY(0)' }} />
              </TableCell>
              <TableCell>
                <Skeleton width={60} height={18} sx={{ transform: 'translateY(0)' }} />
              </TableCell>
              <TableCell align="right">
                <Skeleton width={16} height={18} sx={{ transform: 'translateY(0)' }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RulesTableSkeleton;
