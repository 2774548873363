import { apiClient } from 'lib/api';
import { useQuery } from '@tanstack/react-query';
import type { SynchronousQueryConfig } from 'lib/react-query';
import { isAxiosError } from 'axios';
import type { EmergencyError, EmergencyParams } from './types';

interface CheckEmergencyPromise extends EmergencyParams, EmergencyError {}

export const checkEmergency = async (): Promise<CheckEmergencyPromise> => {
  const res = await apiClient.get<CheckEmergencyPromise>(`/emergency`);
  if (isAxiosError(res)) {
    const error = new Error('Emergency check');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseToggleEmergencyOptions<TData> {
  config?: SynchronousQueryConfig<typeof checkEmergency, TData>;
}

export const useCheckEmergency = <TData = CheckEmergencyPromise>({ config }: UseToggleEmergencyOptions<TData> = {}) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: checkEmergency,
    queryKey: ['emergency'],
    refetchInterval: 60 * 1000,
  });
};
