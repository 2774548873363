import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import type { CheckboxProps, SxProps, Theme } from '@mui/material';
import type { FC } from 'react';

interface CheckboxComponentProps extends Omit<CheckboxProps, 'name'> {
  name: string;
  label?: string;
  controlLabelSx?: SxProps<Theme>;
}
const RuleEditCheckbox: FC<CheckboxComponentProps> = ({ value, label, onChange, controlLabelSx, ...restProps }) => {
  return (
    <FormControlLabel
      control={
        <MuiCheckbox checked={!!value} onChange={onChange} inputProps={{ 'aria-label': label }} {...restProps} />
      }
      label={label}
      sx={controlLabelSx}
    />
  );
};

export default RuleEditCheckbox;
