import type { FC } from 'react';
import EditLogView from './EditLogView';
import type { LogDetails } from '../types';
import type { RuleData } from '../../rules';
import RuleMetricGroupLog from './RuleMetricGroupLog';
import { Typography } from '@mui/material';

interface LogDetailsContentProps {
  action_type: string;
  data: LogDetails;
}

const LogDetailsContent: FC<LogDetailsContentProps> = ({ action_type, data }) => {
  if (action_type.includes('edit')) {
    return <EditLogView data={data as LogDetails<{ data: RuleData }>} />;
  }

  if (action_type === 'add_metric_to_group' || action_type === 'remove_metric_from_group') {
    return (
      <RuleMetricGroupLog
        // @ts-expect-error type correction
        state={action_type === 'add_metric_to_group' ? data.item_state_new : data.item_state_old}
        itemName={data.item_name}
      />
    );
  }

  return <Typography>Unknown log type</Typography>;
};

export default LogDetailsContent;
