import { Grid, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { type FC } from 'react';
import type { UserRole } from '../api';
import { useUsersList } from '../api';
import useUsersListParams from '../hooks/useUsersListParams';
import { usePagination } from 'hooks';
import UsersTableHead from './UsersTableHead';
import UsersTableRowItem from './UsersTableRowItem';
import { isEmpty } from 'lodash-es';
import UsersTableEmpty from './UsersTableEmpty';

const UsersTable: FC = () => {
  const { queryString, role, orderBy, desc } = useUsersListParams();

  const { perPage, page, handlePageChange, handleRowsPerPageChange } = usePagination(
    [queryString, role.join(', '), orderBy, desc],
    50
  );

  const {
    data: { data: usersList, meta },
  } = useUsersList({
    params: {
      q: queryString,
      order_by: orderBy,
      desc,
      role: role as UserRole[],
      offset: page * perPage,
      limit: perPage,
    },
  });

  if (isEmpty(usersList)) return <UsersTableEmpty />;

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflow: 'auto' }}>
      <TableContainer sx={{ flex: 1, position: 'relative', width: '100%' }}>
        <Table sx={{ position: 'absolute', width: '100%' }}>
          <UsersTableHead />
          <TableBody>
            {usersList.map((u) => (
              <UsersTableRowItem key={u.id} user={u} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage="Rows per page:"
        component="div"
        count={meta.count}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Grid>
  );
};

export default UsersTable;
