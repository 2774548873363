/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Grid, Typography, Box, Tooltip } from '@mui/material';
import { useRuleDetails } from '../api/getRuleDetails';
import { useParams } from 'react-router';
import RuleStatus from './RuleStatus';
import ReactCodeMirror from '@uiw/react-codemirror';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import { lintGutter, linter } from '@codemirror/lint';
import wellPilotTheme from '../utils/codeEditorTheme';
import ActionBlock from './ActionBlock';
import RuleDetailsBlock from './RuleDetailsBlock';
import useRuleForEditor from '../hooks/useRuleForEditor';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RuleEditorContainer from './RuleEditorContainer';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
const RuleDetails = () => {
  const { ruleId } = useParams<{ ruleId: string }>();
  const { data: rule } = useRuleDetails({ id: Number(ruleId) });
  const { code, setCode } = useRuleForEditor(rule);

  return (
    <Grid sx={{ height: '100%', pb: 16 }} container>
      <ActionBlock projectId={rule.project_id} />
      <Grid xs={1} item>
        <Link to="/rules">
          <ArrowBackIcon sx={(t) => ({ color: t.palette.action.active })} />
        </Link>
      </Grid>
      <Grid xs={7} item sx={{ flex: 1, pr: 5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2.5 }}>
          <Box width={24} sx={{ mr: 2 }}>
            {/*// @ts-expect-error type correction */}
            <RuleStatus status={rule.status === 'active' ? rule.state : rule.status} />
          </Box>
          <Tooltip arrow title={<Typography sx={{ fontSize: 10, fontWeight: 500 }}>{rule.name}</Typography>}>
            <Typography
              variant="h3"
              sx={{ width: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {rule.name}
            </Typography>
          </Tooltip>
        </Box>
        <Link
          style={{ display: 'inline-flex', alignItems: 'center', marginBottom: 20, textDecoration: 'none' }}
          target="_blank"
          to={rule.logs_s3_folder_url}
        >
          <OpenInNewIcon fontSize="medium" sx={(t) => ({ mr: 1.5, color: t.palette.indigo.main })} />
          <Typography
            sx={(t) => ({ textTransform: 'uppercase', fontWeight: 500, color: t.palette.indigo.main, fontSize: 14 })}
          >
            View s3 logs
          </Typography>
        </Link>
        <RuleEditorContainer>
          <ReactCodeMirror
            value={code}
            theme={wellPilotTheme}
            editable={false}
            height={`${window.screen.height * 0.74}px`}
            extensions={[lintGutter(), json(), linter(jsonParseLinter())]}
            onChange={setCode}
          />
        </RuleEditorContainer>
      </Grid>
      <Grid item xs={3}>
        <RuleDetailsBlock
          flows={rule.flows}
          project={rule.project}
          team={rule.team}
          level={rule.level}
          facebook_ad_account_ids={rule.data.facebook_ad_account_ids}
          action_types={rule.action_types}
        />
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1.5 }}>
            Description
          </Typography>
          <Box sx={{ px: 2, py: 1.5, background: 'rgba(249, 250, 251, 1)', borderRadius: 1.5 }}>
            <Typography sx={{ whiteSpace: 'pre-line' }}>{rule.description || 'No description'}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RuleDetails;
