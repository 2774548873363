import type { FC } from 'react';
import type { TextFieldProps, AutocompleteProps, SxProps, Theme } from '@mui/material';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import type * as React from 'react';
import type { AutocompleteChangeReason, AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete';
import type { Option, OptionValue } from 'types/shared';
import { useMemo } from 'react';

interface RuleCommonAutocompleteProps
  extends Pick<AutocompleteProps<OptionValue, false, boolean, false>, 'disableClearable'> {
  value?: OptionValue;
  onChange: (value: OptionValue) => void;
  InputProps?: TextFieldProps;
  id: string;
  isLoading?: boolean;
  options: Option[];
  allSelectable?: boolean;
  sx?: SxProps<Theme>;
}

const RuleCommonAutocomplete: FC<RuleCommonAutocompleteProps> = ({
  id,
  options,
  isLoading,
  onChange,
  value,
  InputProps,
  ...props
}) => {
  const filter = createFilterOptions<Option>();

  const derivedValue = useMemo(() => options.find((o) => value === o.value), [value, options]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: AutocompleteValue<Option, false, false, false>,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      onChange(newValue?.value);
    } else if (reason === 'clear') {
      onChange('');
    }
  };

  return (
    <Autocomplete
      {...props}
      size="small"
      multiple={false}
      disabled={isLoading ?? InputProps?.disabled}
      loading={isLoading}
      id={id}
      options={options}
      disableClearable
      value={derivedValue ?? { label: '', value: '' }} // eslint-disable-line
      isOptionEqualToValue={(option: Option, value?: Option) => {
        if (!value || value.value === '') return false; // ignore the default value
        return option.value === value.value;
      }}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField {...params} label={InputProps?.label} placeholder={InputProps?.placeholder} />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        return filtered;
      }}
    />
  );
};

export default RuleCommonAutocomplete;
