import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import queryString from 'query-string';
import queryKeys from './queryKeys';
import type { QueryConfig } from 'lib/react-query';
import type { RuleEntry } from '../types';

export const getRuleDetails = async (id: number, signal?: AbortSignal): Promise<RuleEntry> => {
  const res = await apiClient.get<RuleEntry>(`/rules/${id}`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseRuleData<TData> {
  config?: QueryConfig<typeof getRuleDetails, TData>;
  id: number;
}

export const useRuleDetails = <TData = RuleEntry>({ id, config }: UseRuleData<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getRuleDetails(id, signal),
    queryKey: queryKeys.rule(id),
  });
};
