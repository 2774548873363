import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryConfig } from 'lib/react-query';
import { apiClient } from 'lib/api';
import queryKeys from './queryKeys';
import queryString from 'query-string';
import type { LogItemType } from '../types';

export const getLogsItemTypes = async (signal?: AbortSignal): Promise<LogItemType[]> => {
  const res = await apiClient.get<LogItemType[]>(`/audit_logs/item_types`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseLogsItemTypesOptions<TData> {
  config?: QueryConfig<typeof getLogsItemTypes, TData>;
}

export const useLogsItemTypes = <TData = LogItemType[]>({ config }: UseLogsItemTypesOptions<TData> = {}) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getLogsItemTypes(signal),
    queryKey: queryKeys.getLogsItemTypes,
  });
};
