import type { Rule } from '../types';
import { makeAutoObservable } from 'mobx';

class BulkDuplicateStore {
  private rules: Rule[] = [];
  private defaultStateRules: Rule[] = [];
  private renamedRules: number[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  initialRules(rules: Rule[]) {
    this.rules = rules;
    this.defaultStateRules = rules;
  }
  renameRules(searchTerm: string, replaceTerm: string): Promise<{ message: string } | Error> {
    return new Promise((resolve, reject) => {
      try {
        const escapedString = searchTerm.replace(/[\W_]/g, '\\$&');
        this.rules = this.rules.map((r) => {
          const newName = r.name.replace(new RegExp(escapedString, 'gi'), replaceTerm);
          if (newName !== r.name) {
            this.lastRenamedRules.push(r.id); // Set the last renamed rule
          }
          return { ...r, name: newName };
        });
        resolve({ message: 'Name updated' });
      } catch (err) {
        reject(new Error('Failed to rename rules'));
      }
    });
  }

  addPostfix(): Promise<{ message: string } | Error> {
    return new Promise((resolve, reject) => {
      try {
        const postfix = `duplicated_{date}`;
        this.rules = this.rules.map((r) => {
          if (!r.name.endsWith(postfix)) {
            r.name += `_${postfix}`;
          }
          return r;
        });
        resolve({ message: 'Name updated' });
      } catch (err) {
        reject(new Error('Failed to rename rules'));
      }
    });
  }

  clearRenamedRules() {
    this.renamedRules = [];
  }

  get isRenamingApplied() {
    const defaultRules = this.defaultStateRules.map((r) => r.name);
    return !this.rules.some((r) => defaultRules.includes(r.name));
  }

  get lastRenamedRules() {
    return this.renamedRules;
  }

  get list() {
    return this.rules;
  }

  get defaultStateList() {
    return this.defaultStateRules;
  }
}

const bulkDuplicateStore = new BulkDuplicateStore();

export default bulkDuplicateStore;
