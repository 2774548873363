import { Box, Divider, Skeleton, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const SkeletonSide = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <Box>
      <Skeleton variant="text" sx={{ width: '40%', mb: 2 }} />
      <Skeleton variant="text" sx={{ width: '100%' }} />
      <Divider sx={{ mt: 2 }} />
    </Box>
    <Box>
      <Skeleton variant="text" sx={{ width: '40%', mb: 2 }} />
      <Skeleton variant="text" sx={{ width: '100%' }} />
      <Divider sx={{ mt: 2 }} />
    </Box>
    <Box>
      <Skeleton variant="text" sx={{ width: '40%', mb: 2 }} />
      <Skeleton variant="text" sx={{ width: '100%' }} />
      <Divider sx={{ mt: 2 }} />
    </Box>
  </Box>
);

const LogDetailsSkeleton = () => {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Skeleton variant="rectangular" sx={{ width: '60%' }} />
        <Skeleton variant="circular" sx={{ width: '25px', height: '25px' }} />
      </Box>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
        <AccountCircleIcon sx={{ fontSize: 16 }} />
        <Skeleton variant="text" sx={{ width: '60%' }} />
      </Box>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
        <CalendarTodayIcon sx={{ fontSize: 16 }} />
        <Skeleton variant="text" sx={{ width: '30%' }} />
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', width: '100%', pt: 2 }}>
        <Box sx={{ width: '50%', pr: 4 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Before
          </Typography>
          <SkeletonSide />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={{ width: '50%', pl: 4 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            After
          </Typography>
          <SkeletonSide />
        </Box>
      </Box>
    </>
  );
};

export default LogDetailsSkeleton;
