import type { QueryKey } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { SynchronousQueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { UserEntry } from './types';
import { useQuery } from '@tanstack/react-query';

export const getMe = async (signal?: AbortSignal): Promise<UserEntry> => {
  const res = await apiClient.get<UserEntry>(`/users/me`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  return res.data;
};

interface UseUsersListOptions<TData> {
  config?: SynchronousQueryConfig<typeof getMe, TData>;
}

export const useMe = <TData = UserEntry>({ config }: UseUsersListOptions<TData> = {}) => {
  return useQuery({
    staleTime: 60 * 180 * 1000,
    ...config,
    queryFn: ({ signal }) => getMe(signal),
    queryKey: ['me'] as QueryKey,
  });
};
