import { Box, Skeleton, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { type FC } from 'react';
import { TableBody } from '@aws-amplify/ui-react';
import { TableCellStickyStyled } from './MetricsComponents';

const MetricsTableSkeleton: FC = () => {
  return (
    <Box
      sx={{ position: 'relative', display: 'flex', flex: 1, flexDirection: 'column', width: '100%', height: '100%' }}
    >
      <Box sx={{ display: 'flex', position: 'relative', flex: 1, width: '100%' }}>
        <TableContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
        >
          <>
            <Table
              sx={{
                '&': {
                  position: 'relative',
                  zIndex: 1,
                  border: 'none',
                  overflowX: 'auto',
                  width: '100%',
                },
              }}
              stickyHeader
              size="small"
            >
              <TableHead sx={{ position: 'relative', zIndex: 6 }}>
                <TableRow sx={{ th: { height: 64, maxHeight: 64, px: 1.5 } }}>
                  <TableCellStickyStyled sx={{ '&': { left: 0, width: 52, maxWidth: 52 } }}>
                    <Skeleton width={22} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                  </TableCellStickyStyled>
                  <TableCellStickyStyled
                    align="left"
                    sx={{ '&': { left: 54, width: 280, minWidth: 280, maxWidth: 280 } }}
                  >
                    <Skeleton width={110} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                  </TableCellStickyStyled>
                  {Array.from(Array(8).keys()).map((i) => (
                    <TableCell align="left" sx={{ minWidth: 42 }} key={`metricsTableHeadSkeleton_${i}`}>
                      <Skeleton width={60} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(Array(10).keys()).map((i) => (
                  <TableRow sx={{ td: { height: 64, px: 1.5 } }} key={`metricsTableRowSkeleton_${i}`}>
                    {Array.from(Array(10).keys()).map((i) => (
                      <TableCell align="left" sx={{ minWidth: 42 }} key={`metricsTableHeadSkeleton_${i}`}>
                        <Skeleton
                          width={!i ? 22 : i === 1 ? 220 : 60}
                          height={22}
                          sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        </TableContainer>
      </Box>
      <Box sx={{ flex: 1, maxHeight: 80, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Skeleton width={220} height={32} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
      </Box>
    </Box>
  );
};

export default MetricsTableSkeleton;
