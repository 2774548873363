import type { FC } from 'react';
import { Suspense, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import filterStore from '../stores/filterStore';
import type { Option } from '../../../types/shared';
import { RuleEditAutocompleteMultiple } from './RuleEditAutocomplete';
import type { OptionValue } from '../../../types/shared';
import RuleFilterInput from './RuleFilterInput';
import FlowAutocomplete from './FlowAutocomplete';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { SettingsEntry } from '../api/getRefences';
import { getReferencesOptions } from '../api/getRefences';

interface RuleFilterValueProps {
  groupIndex: number;
  index: number;
}

const RuleFilterValue: FC<RuleFilterValueProps> = observer(({ groupIndex, index }) => {
  const filter = filterStore.getRuleFilter(groupIndex, index);

  const { data: effectiveStatuses } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => {
      const result: Record<string, Option[]> = {};

      Object.entries(data.effective_statuses).map(([k, v]: [k: string, v: SettingsEntry[]]) => {
        result[k] = v.map((v) => ({ value: v.id, label: v.name }));
      });

      return result;
    },
  });

  const isEffectiveStatus = filter?.field.includes('effective_status');
  const isFlow = filter?.field === 'flow';

  const filteredEffectiveStatuses: Option[] = useMemo(() => {
    if (!isEffectiveStatus) return [];

    const key = filter!.field.split('.')[0];

    return effectiveStatuses[key];
  }, [effectiveStatuses, filter, isEffectiveStatus]);

  const handleChangeValue = useCallback(
    (value: OptionValue | OptionValue[]) => {
      filterStore.updateRuleFilterValue(groupIndex, index, value as string[]);
    },
    [groupIndex, index]
  );

  if (isFlow) {
    return (
      <Suspense fallback={<span>Loading...</span>}>
        <FlowAutocomplete
          allSelectable={false}
          value={filter?.value as OptionValue}
          InputProps={{
            placeholder: 'Value',
          }}
          onChange={handleChangeValue}
          id="value"
        />
      </Suspense>
    );
  }

  if (isEffectiveStatus) {
    return (
      <RuleEditAutocompleteMultiple
        allSelectable={false}
        value={filter?.value as OptionValue[]}
        InputProps={{
          placeholder: 'Value',
        }}
        onChange={handleChangeValue}
        id="value"
        options={filteredEffectiveStatuses}
      />
    );
  }

  return (
    <RuleFilterInput
      placeholder="Value"
      value={filterStore.getRuleFilter(groupIndex, index)?.value ?? ''}
      onChange={handleChangeValue}
    />
  );
});

export default RuleFilterValue;
