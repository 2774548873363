import { apiClient } from '../../../lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { isAxiosError } from 'axios';

interface DeleteMetricFromGroupParams {
  metric_id: number;
  group_id: number;
}

export const deleteMetricFromGroup = async ({ metric_id, group_id }: DeleteMetricFromGroupParams): Promise<{}> => {
  const res = await apiClient.delete(`/metrics/${metric_id}/${group_id}`);
  if (isAxiosError(res)) {
    const error = new Error('Remove metric from group');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseDeleteMetricFromGroupOptions {
  config?: MutationConfig<typeof deleteMetricFromGroup>;
}

export const useDeleteMetricFromGroup = ({ config }: UseDeleteMetricFromGroupOptions = {}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['groups'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['metrics'],
        }),
      ]);

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: (params) => deleteMetricFromGroup(params),
  });

  return {
    ...mutation,
    mutate: (params: DeleteMetricFromGroupParams) => {
      mutation.mutate({ ...params });
    },
  };
};
