import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import type { QueryConfig } from 'lib/react-query';
import type { Project } from './types';
import { Teams, TeamsParam } from 'types/shared';
import { apiClient } from 'lib/api';

interface RecordType {
  id: TeamsParam;
  name: Teams;
}

export const getTeams = async (signal?: AbortSignal): Promise<RecordType[]> => {
  const res = await apiClient.get<RecordType[]>(`/teams`, {
    signal,
  });
  return res.data;
};

interface UseTeamsListOptions<TData> {
  config?: QueryConfig<typeof getTeams, TData>;
}

export const useTeams = <TData = Project[]>({ config }: UseTeamsListOptions<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getTeams(signal),
    queryKey: ['teams'] as QueryKey,
  });
};
