import { Box, FormControl, InputLabel } from '@mui/material';
import useListParams from 'hooks/useListParams';
import { useCallback } from 'react';
import { useTeams } from '../api/getTeams';
import Select from 'components/common/Select';

const FilterTeams = () => {
  const { setTeam } = useListParams();

  const { data: teams } = useTeams({
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })),
    },
  });

  const handleChange = useCallback(
    (value: string[]) => {
      setTeam(value.map(Number));
    },
    [setTeam]
  );

  return (
    <Box sx={{ display: 'flex', minWidth: 108, maxWidth: 200, flex: 1 }}>
      <FormControl sx={{ display: 'flex', flexGrow: 1 }}>
        <InputLabel size="small" id="filter-action-type">
          Team
        </InputLabel>
        <Select
          name="team"
          inputLabel="Team"
          size="small"
          multiple
          required
          selectAll={false}
          options={teams}
          handleChange={handleChange}
        />
      </FormControl>
    </Box>
  );
};

export default FilterTeams;
