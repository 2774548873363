import { apiClient } from 'lib/api';
import { useMutation } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { isAxiosError } from 'axios';
import type { EmergencyParams } from './types';

interface EmergencyError {
  message: string;
}

export const toggleEmergency = async (body: EmergencyParams): Promise<EmergencyParams | EmergencyError> => {
  const res = await apiClient.post<EmergencyParams>(`/emergency`, body);
  if (isAxiosError(res)) {
    const error = new Error('Emergency toggle');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseToggleEmergencyOptions {
  config?: MutationConfig<typeof toggleEmergency>;
}

export const useToggleEmergency = ({ config }: UseToggleEmergencyOptions = {}) => {
  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: toggleEmergency,
  });
};
