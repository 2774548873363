import useListParams from 'hooks/useListParams';
import useChosenRules from './useChosenRules';
import type { BulkActionRulesParams, RulesFiltersParams } from '../api/types';
import { isEmpty, isNumber, omit, pickBy } from 'lodash-es';
import useFilterQueryValues from './useFilterQueryValues';

type UseRulesQueryParams = Pick<BulkActionRulesParams, 'filters' | 'project_id' | 'rules_ids'>;

const useRulesQueryParams = (): UseRulesQueryParams => {
  const filtersParams = useFilterQueryValues();
  const { project } = useListParams();
  const { rulesIds, isAllRulesChosen } = useChosenRules();

  const projectId = project === 'all_projects' ? undefined : parseInt(project);
  const filtersValues = {
    filters: {
      ...pickBy(
        omit(filtersParams as RulesFiltersParams, ['order_by', 'desc', 'limit', 'offset', 'paginationRelatedParams']), // exclude fields
        (value) => isNumber(value) || !isEmpty(value) // keep any number and remove empty fields
      ),
      ...(projectId ? { project_id: projectId } : {}),
    },
  };

  return {
    ...filtersValues,
    rules_ids: isAllRulesChosen ? [] : rulesIds,
  };
};

export default useRulesQueryParams;
