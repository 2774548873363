import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNotificationChannels } from '../../api';
import type { Option } from '../../../../types/shared';
import { Box, FormControl, Typography } from '@mui/material';
import Select from '../../../../components/common/Select';

interface NotificationChannelsSelectInputProps {
  label?: string;
  inputLabel?: string;
  name: string;
  required?: boolean;
}

const NotificationChannelsSelect: FC<NotificationChannelsSelectInputProps> = ({
  name,
  label,
  inputLabel,
  required,
}) => {
  const { trigger } = useFormContext();
  const { data: notificationChannels } = useNotificationChannels<Option[]>({
    config: {
      select: (data): Option[] => {
        return data.map(({ id, name }) => ({ value: id, label: name }));
      },
    },
  });

  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      {label && !inputLabel && (
        <Typography variant="subtitle2" mb={1}>
          {label}
          {required && '*'}
        </Typography>
      )}
      <FormControl sx={{ flex: 1 }}>
        <Select
          name={name}
          id={name}
          label={inputLabel}
          required
          selectAll={false}
          options={notificationChannels ?? []}
          onBlur={() => trigger(name)}
        />
      </FormControl>
    </Box>
  );
};

export default NotificationChannelsSelect;
