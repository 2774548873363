import DatePicker from 'components/common/Datepicker';
import useListParams from 'hooks/useListParams';

const FilterPauseDate = () => {
  const { pauseDate, setPauseDate, tab } = useListParams();
  return tab === 'paused' ? (
    <DatePicker
      maxDate={new Date()}
      field="pauseDate"
      label="Pause date"
      actualValue={pauseDate ?? undefined}
      setActualValue={setPauseDate}
    />
  ) : null;
};

export default FilterPauseDate;
