import useListParams from 'hooks/useListParams';
import useChosenRules from './useChosenRules';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { closeSnackbar } from 'notistack';
import { useRunRules } from '../api/runRules';
import useRulesQueryParams from './useRulesQueryParams';

const useBulkRunManually = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void } = {}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { setValue, reset } = useFormContext();
  const { tab } = useListParams();
  const { isChosenRules } = useChosenRules();
  const rulesQueryParams = useRulesQueryParams();

  const { onShowAlert, onShowErrorAlert } = useEnqueueSnackbar({
    delay: 5000,
  });

  const runRulesMutation = useRunRules({
    config: {
      onSuccess: (_, variables) => {
        setValue('all_rules_switched', tab === 'active');
        onShowAlert((variables.rules_ids as number[]).length > 1 ? 'Rules are running' : 'Rule is running');
        reset();
        onSuccess?.();
      },
      onError: (err) => {
        onShowErrorAlert(err.cause?.message || 'Error');
        setErrorMessage(err.cause?.message);
        onError && onError();
      },
    },
  });

  const onRunManually = useCallback(() => {
    closeSnackbar();
    runRulesMutation.mutate({ ...rulesQueryParams });
  }, [closeSnackbar, runRulesMutation]);

  return {
    errorMessage,
    isChosenRules,
    isPending: runRulesMutation.isPending,
    isSuccess: runRulesMutation.isSuccess,
    onRunManually,
  };
};

export default useBulkRunManually;
