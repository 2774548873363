import type { QueryKey } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import type { UserUserRoleEntry } from './types';

export const getUserRoles = async (signal?: AbortSignal): Promise<UserUserRoleEntry[]> => {
  const res = await apiClient.get<UserUserRoleEntry[]>(`/users/roles`, {
    signal,
  });
  return res.data;
};

interface UseUsersListOptions<TData> {
  config?: QueryConfig<typeof getUserRoles, TData>;
}

export const useUserRoles = <TData = UserUserRoleEntry[]>({ config }: UseUsersListOptions<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getUserRoles(signal),
    queryKey: ['users/roles'] as QueryKey,
  });
};
