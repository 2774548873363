import { Box, Button, CircularProgress, IconButton, Skeleton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import type { FC } from 'react';
import { Suspense, useCallback, useState } from 'react';
import type { UseModalResult } from 'hooks';
import { useModal } from 'hooks';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { groupModify } from '../validation/groupModify.schema';
import Input from 'components/common/Input';
import { Close } from '@mui/icons-material';
import useMetrics from '../hooks/useMetrics';
import { isUndefined } from 'lodash-es';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useDeleteGroup } from '../api/deleteGroup';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { useEditGroup } from '../api/editGroup';
import { useCreateGroup } from '../api/createGroup';
import useListParams from 'hooks/useListParams';
import Autocomplete from 'components/common/Autocomplete';
import { MetricDrawer } from './MetricsComponents';
import { useMyAccess } from 'features/users';
import useAccessModify from '../hooks/useAccessModify';

interface GroupCommonProps {
  id: number;
  name: string;
  metrics_ids?: (number | undefined)[] | undefined;
}
type GroupModifyFormFields = Pick<GroupCommonProps, 'name' | 'metrics_ids'>;
interface DeleteGroupItemProps extends GroupCommonProps, Pick<UseModalResult, 'open' | 'close' | 'isOpen'> {}
interface GroupModifyProps extends Partial<GroupCommonProps>, Pick<UseModalResult, 'close' | 'isOpen'> {}
interface GroupFormModifyProps extends Partial<GroupCommonProps>, Pick<UseModalResult, 'close'> {}

const GroupFormModify: FC<GroupFormModifyProps> = ({ id, name, close }) => {
  const { onShowAlert } = useEnqueueSnackbar();
  const isCreateGroup = isUndefined(id);
  const { project, setGroupId } = useListParams();

  const { options: metrics } = useMetrics();
  const { options: allMetrics } = useMetrics({ isAllMetrics: true });

  const formMethods = useForm<GroupModifyFormFields>({
    resolver: yupResolver(groupModify),
    defaultValues: {
      name: name ?? '',
      metrics_ids: id && metrics ? metrics.map((m) => m.value as number) : [],
    },
  });

  const { reset, setError } = formMethods;

  const createGroup = useCreateGroup({
    config: {
      onSuccess: (data) => {
        onShowAlert('New group created');
        reset();
        close();
        data && setGroupId(data.id);
      },
      onError: (err) => {
        setError('name', { message: err.cause?.message }, { shouldFocus: true });
      },
    },
  });
  const editGroup = useEditGroup({
    config: {
      onSuccess: () => {
        onShowAlert('Group edited');
        reset();
        close();
      },
      onError: (err) => {
        setError('name', { message: err.cause?.message }, { shouldFocus: true });
      },
    },
  });

  const onSubmit = useCallback(
    (data: GroupModifyFormFields) => {
      if (isCreateGroup) {
        createGroup.mutate({
          name: data.name,
          metrics_ids: data.metrics_ids ? (data.metrics_ids as number[]) : undefined,
        });
      } else {
        editGroup.mutate({
          group_id: id,
          name: data.name,
          metrics_ids: data.metrics_ids ? (data.metrics_ids as number[]) : undefined,
        });
      }
    },
    [createGroup, editGroup, isCreateGroup, id, project]
  );

  return (
    <FormProvider {...formMethods}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Input name="name" label="Group name" required />
          <Autocomplete
            label="Metrics"
            allSelectable={false}
            id="metrics_ids"
            name="metrics_ids"
            options={allMetrics}
          />
        </Box>
        <Button
          startIcon={
            createGroup.isPending || editGroup.isPending ? <CircularProgress size={16} color="inherit" /> : null
          }
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          disabled={createGroup.isPending || editGroup.isPending}
        >
          {isCreateGroup ? 'Create' : 'Save'}
        </Button>
      </Box>
    </FormProvider>
  );
};

const GroupModify: FC<GroupModifyProps> = ({ isOpen, close, id, name }) => {
  return (
    <>
      <MetricDrawer open={isOpen} onClose={close}>
        <Box sx={{ display: 'flex', flex: 1, flexGrow: 1, flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h2">{!isUndefined(id) ? 'Edit group' : 'New group'}</Typography>
            <IconButton onClick={close}>
              <Close />
            </IconButton>
          </Box>
          <Suspense
            fallback={
              <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Skeleton
                  width={70}
                  height={18}
                  sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1.25 }}
                />
                <Skeleton
                  width={'100%'}
                  height={40}
                  sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 4 }}
                />
                <Skeleton
                  width={70}
                  height={18}
                  sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1.25 }}
                />
                <Skeleton
                  width={'100%'}
                  height={40}
                  sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1 }}
                />
              </Box>
            }
          >
            <GroupFormModify id={id} name={name} close={close} />
          </Suspense>
        </Box>
      </MetricDrawer>
    </>
  );
};

export const AddGroup = () => {
  const { open, close, isOpen } = useModal();
  const project = useWatch({ name: 'project' });
  const { accessMetrics } = useAccessModify();
  const isShow = accessMetrics.addToGroup && project === 'all_projects';

  return isShow ? (
    <>
      <Button
        sx={{ justifyContent: 'start', mb: 1 }}
        size="small"
        variant="text"
        startIcon={<AddIcon />}
        onClick={open}
      >
        Add group
      </Button>
      <GroupModify isOpen={isOpen} close={close} />
    </>
  ) : null;
};

export const EditGroup: FC<GroupModifyProps> = ({ isOpen, close, id, name }) => {
  return <GroupModify isOpen={isOpen} close={close} id={id} name={name} />;
};

export const DeleteGroup: FC<DeleteGroupItemProps> = ({ id, name, isOpen, close }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { onShowAlert } = useEnqueueSnackbar();
  const { setGroupId } = useListParams();
  const deleteGroup = useDeleteGroup({
    config: {
      onSuccess: () => {
        onShowAlert(`${name} group deleted`);
        close();
        setGroupId(undefined);
      },
      onError: (e) => {
        setErrorMessage(e.cause ? e.cause.message : 'Internal Error');
      },
    },
  });

  const onConfirm = useCallback(() => {
    setErrorMessage(null);
    deleteGroup.mutate({ id });
  }, []);

  return (
    <>
      <ConfirmDialog
        title={`Delete group ${name}`}
        text={
          <>
            <Typography variant="body1">Metrics from this group will not be deleted</Typography>
            {!!errorMessage && (
              <Typography variant="body1" sx={(t) => ({ color: t.palette.error.main, pt: 2 })}>
                {errorMessage}
              </Typography>
            )}
          </>
        }
        confirmButton="Delete group"
        cancelButton="Cancel"
        isOpen={isOpen}
        isPending={deleteGroup.isPending}
        close={close}
        onCancel={close}
        onConfirm={onConfirm}
      />
    </>
  );
};
