import { Skeleton, TableCell, TableHead, TableRow } from '@mui/material';

const LogsTableSkeletonHead = () => {
  return (
    <TableHead sx={{ position: 'relative', zIndex: 3, height: 56 }}>
      <TableRow sx={{ th: { height: 56, border: 'none', fontWeight: 'bold', py: 1, px: 1 } }}>
        <TableCell sx={{ minWidth: '200px', maxWidth: '300px' }}>
          <Skeleton width={200} height={32} />
        </TableCell>
        <TableCell sx={{ minWidth: '300px', maxWidth: '400px' }}>
          <Skeleton width={300} height={32} />
        </TableCell>
        <TableCell sx={{ minWidth: '100px', maxWidth: '200px' }}>
          <Skeleton width={100} height={32} />
        </TableCell>
        <TableCell sx={{ minWidth: '300px', maxWidth: '300px' }}>
          <Skeleton width={300} height={32} />
        </TableCell>
        <TableCell sx={{ minWidth: '100px', maxWidth: '100px' }} align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default LogsTableSkeletonHead;
