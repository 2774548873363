import type { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface DiscardChangesDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
}

const DiscardChangesDialog: FC<DiscardChangesDialogProps> = ({ isOpen, onClose, onDiscard }) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ p: '20px 32px' }} fontWeight={500} id="alert-dialog-title" align="center">
        Discard changes?
      </DialogTitle>
      <DialogActions sx={{ justifyContent: 'space-between', p: '20px' }}>
        <Button sx={{ textTransform: 'uppercase' }} disableElevation onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disableElevation
          onClick={onDiscard}
          autoFocus
          variant="contained"
          color="primary"
          sx={{ textTransform: 'uppercase' }}
        >
          Discard
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DiscardChangesDialog;
