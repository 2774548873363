import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import RadioGroup from 'components/common/RadioGroup';
import type { FC } from 'react';
import { Suspense } from 'react';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import { useRuleGroups } from '../api';
import { LabelFormSection } from './RuleEditComponents';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import { ProjectSelect } from 'features/projects';
import { useFormContext } from 'react-hook-form';
import ChipInput from 'components/common/ChipInput';
import { NotificationChannelsAutocomplete } from './RuleEditTasksFormComponents';
import type { RuleEntry, RuleTypeMutation } from '../types';
import { AccessTimeOutlined, ExpandMore } from '@mui/icons-material';
import { useSuspenseQuery } from '@tanstack/react-query';
import { getReferencesOptions } from '../api/getRefences';

const NameRuleChangedMessage = () => {
  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        alignItems: 'start',
        gap: 1,
        borderRadius: t.borderRadius.md,
        p: 1.5,
        backgroundColor: 'rgba(236, 239, 241, 1)',
        color: 'rgba(1, 67, 97, 1)',
      })}
    >
      <AccessTimeOutlined sx={(t) => ({ color: t.palette.info.main })} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          Changing the name will result in the creation of a new rule.
        </Typography>
        <Typography variant="body2">The old rule will be deleted, and it’s logs will be not available</Typography>
      </Box>
    </Box>
  );
};

const RuleStatus = () => {
  return (
    <Box sx={{ display: 'flex', gap: 3 }}>
      <Typography variant="subtitle1" sx={{ lineHeight: 2.5 }}>
        Status
      </Typography>
      <RadioGroup
        row
        name="ruleStatus"
        options={[
          { value: 'active', label: 'Active' },
          { value: 'paused', label: 'Paused' },
          { value: 'archived', label: 'Archived' },
        ]}
      />
    </Box>
  );
};

const LevelSelect = () => {
  const { data: levels } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => data.levels.map(({ name, id }) => ({ value: id, label: name })),
  });

  return <Select name="level" inputLabel="level" required selectAll={false} options={levels} />;
};

const RuleGroupSelect = () => {
  const { data: ruleGroups } = useRuleGroups({
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })),
    },
  });
  return <Select name="groupId" inputLabel="rule group" selectAll={false} options={ruleGroups} />;
};

const NotificationLevelSelect = () => {
  const { data: notificationLevels } = useSuspenseQuery({
    ...getReferencesOptions,
    select: (data) => data.notification_levels.map(({ name, id }) => ({ value: id, label: name })),
  });

  return (
    <Select name="notificationLevel" inputLabel="notification_level" selectAll={false} options={notificationLevels} />
  );
};

const SummaryData: FC<{ rule: RuleEntry }> = ({ rule }) => {
  const ListModel = [
    { title: 'Action type:', value: rule.action_types.map(({ name }) => name).join(', ') },
    { title: 'Flows:', value: rule.flows.map(({ name }) => name).join(', ') },
    { title: 'Team:', value: rule.team },
  ];
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="subtitle1">Summary</Typography>
      <Box sx={{ px: 2, background: 'rgba(249, 250, 251, 1)', borderRadius: 1.5 }}>
        <List sx={{ '&': { display: 'flex', flexDirection: 'column', gap: 1 } }}>
          {ListModel.map((item) => (
            <ListItem disablePadding key={`summaryListItem:${item.value}`}>
              <ListItemText secondary={item.title} sx={(t) => ({ flex: 1, fontSize: t.typography.body1.fontSize })} />
              <ListItemText primary={item.value} sx={(t) => ({ flex: 1, fontSize: t.typography.body1.fontSize })} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

const Description = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="subtitle1">Description</Typography>
      <Box sx={{ py: 1.5, borderRadius: 1.5 }}>
        <Input id="description" name="description" multiline />
      </Box>
    </Box>
  );
};

const RuleEditBasicForm: FC<{ rule: RuleEntry; mode: RuleTypeMutation }> = ({ rule, mode }) => {
  const { getFieldState } = useFormContext();
  const isNameDirty = getFieldState('name').isDirty && mode === 'edit';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4" sx={{ fontWeight: 500 }}>
          Rule settings
        </Typography>
        <RuleStatus />
      </Box>
      <Box
        sx={(t) => ({
          display: 'flex',
          gap: 2,
          width: '100%',
          flexDirection: 'column',
          border: `solid ${`rgba(0, 8, 46, 0.12)`}`,
          borderRadius: t.borderRadius.md,
          p: 2,
        })}
      >
        <Box>
          <LabelFormSection label="Basic" icon={<FeaturedPlayListOutlinedIcon />} />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', gap: 4 }}>
          <Box sx={{ display: 'flex', width: 'calc(60% - 50px)', gap: 3, flexDirection: 'column' }}>
            <Input name="name" label="name" innerLabel />
            {isNameDirty && <NameRuleChangedMessage />}
            <Suspense
              fallback={
                <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
              }
            >
              <ProjectSelect onlyUsersProjects name="project" inputLabel="project" required />
            </Suspense>
            <Suspense
              fallback={
                <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
              }
            >
              <LevelSelect />
            </Suspense>
            <ChipInput name="facebookAdAccountIds" inputLabel="facebook_ad_account_ids" />
            <Suspense
              fallback={
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Skeleton width={90} height={18} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                  <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                </Box>
              }
            >
              <RuleGroupSelect />
            </Suspense>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <Typography variant="subtitle2">Notifications</Typography>
              <Suspense
                fallback={
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Skeleton width={90} height={18} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
                    <Skeleton
                      width={'100%'}
                      height={40}
                      sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }}
                    />
                  </Box>
                }
              >
                <NotificationChannelsAutocomplete name="notificationChannels" inputLabel="name" />
                <NotificationLevelSelect />
                <Accordion
                  disableGutters
                  sx={(t) => ({
                    borderBottom: `1px solid ${t.palette.divider}`,
                    '&:last-of-type': { borderRadius: 0 },
                    pb: 1.5,
                  })}
                >
                  <AccordionSummary
                    sx={{ py: 0, pl: 1.5, pr: 1, minHeight: 32, '& .MuiAccordionSummary-content': { my: 0 } }}
                    expandIcon={<ExpandMore />}
                    id={`accordionPanel_${rule.id}`}
                  >
                    <Typography variant="subtitle2" sx={(t) => ({ color: t.palette.text.secondary })}>
                      Notification message
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 1 }}>
                      <Input name="notificationMessage.title" label="notification title" innerLabel />
                      <Input name="notificationMessage.body" label="notification body" innerLabel multiline />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Suspense>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, gap: 3, flexDirection: 'column' }}>
            <SummaryData rule={rule} />
            <Description />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RuleEditBasicForm;
