import { apiClient } from '../../../lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { CreateFlowParams } from './types';
import { isAxiosError } from 'axios';

interface CreateFlowPromise {
  id: number;
  name: string;
}

interface CreateFlowErrorPromise {
  message: string;
}

type QueryPromise = CreateFlowPromise | CreateFlowErrorPromise;

export const createFlow = async (body: CreateFlowParams): Promise<QueryPromise> => {
  const res = await apiClient.post<QueryPromise>(`/flows`, body);
  if (isAxiosError(res)) {
    const error = new Error('axios error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseCreateFlowOptions {
  config?: MutationConfig<typeof createFlow>;
}

export const useCreateFlow = ({ config }: UseCreateFlowOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['platforms'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['rules'],
        }),
      ]);

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: createFlow,
  });
};
