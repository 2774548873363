import { MoreVert } from '@mui/icons-material';
import { CircularProgress, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { FC, Suspense, useCallback } from 'react';
import MetricMenuGroups from './MetricMenuGroups';
import MetricMenuGroupsSkeleton from './MetricMenuGroupsSkeleton';
import { useMultipleAnchorMenus } from 'hooks/useMultipleAnchorMenus';
import useAccessModify from '../hooks/useAccessModify';
import { useDeleteMetricFromGroup } from '../api/deleteMetricFromGroup';
import useListParams from 'hooks/useListParams';
import { isUndefined } from 'lodash-es';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';

const RemoveFromGroupItem = ({ metricId, onClose }: { metricId: number; onClose: () => void }) => {
  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();
  const { groupId } = useListParams();
  const { accessMetrics } = useAccessModify();
  const deleteMetricFromGroup = useDeleteMetricFromGroup({
    config: {
      onSuccess: () => {
        onClose();
        onShowAlert('Metric removed');
      },
      onError: (e) => {
        onClose();
        onShowInfoAlert(e.cause ? (e.cause?.message as string) : 'Internal Error');
      },
    },
  });

  const handleRemoveFromGroup = useCallback(() => {
    deleteMetricFromGroup.mutate({
      metric_id: metricId,
      group_id: parseInt(groupId as string),
    });
  }, [deleteMetricFromGroup, metricId, groupId]);

  return accessMetrics.removeFromGroup && !isUndefined(groupId) ? (
    <MenuItem
      disabled={deleteMetricFromGroup.isPending}
      onClick={(e) => {
        e.stopPropagation();
        handleRemoveFromGroup();
      }}
      sx={(t) => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: deleteMetricFromGroup.isPending ? t.palette.primary.main : t.palette.text.primary,
        backgroundColor: deleteMetricFromGroup.isPending ? t.palette.action.selected : 'white',
      })}
    >
      <Typography
        sx={(t) => ({
          fontSize: t.typography.body2.fontSize,
          p: 0,
          m: 0,
          color: t.palette.text.primary,
        })}
      >
        Remove from group
      </Typography>
      {deleteMetricFromGroup.isPending && <CircularProgress size={18} />}
    </MenuItem>
  ) : null;
};

const MetricMenu: FC<{ id: number; parentRef: { current: HTMLTableRowElement | null } }> = ({
  id: metricId,
  parentRef,
}) => {
  const {
    accessMetrics: { addToGroup, removeFromGroup },
  } = useAccessModify();
  const isShow = [addToGroup, removeFromGroup].includes(true);
  const { anchors, isOpen, handleOpenMenu, handleCloseMenu, handleCloseAllMenu } = useMultipleAnchorMenus();
  const isOpenMenu = isOpen(metricId);
  const isOpenSubMenu = isOpen(`${metricId}_submenu`);
  const anchor = anchors[metricId];
  const anchorSubmenu = anchors[`${metricId}_submenu`];

  const onCloseMenu = useCallback(() => {
    handleCloseMenu(metricId);
    parentRef.current?.classList.remove('hover');
  }, [parentRef.current, metricId]);

  return isShow ? (
    <>
      <IconButton
        color={'default'}
        size="small"
        sx={{ p: 2, width: 12, mr: 0.5 }}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        onClick={(e) => {
          e.stopPropagation();
          handleOpenMenu(e, metricId);
          parentRef.current?.classList.add('hover');
        }}
      >
        <MoreVert></MoreVert>
      </IconButton>
      <Menu
        slotProps={{
          paper: {
            sx: { width: 218, ml: -1, mt: 0.5 },
          },
        }}
        anchorEl={anchor}
        open={isOpenMenu}
        onClose={onCloseMenu}
      >
        {addToGroup && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleOpenMenu(e, `${metricId}_submenu`);
            }}
            sx={(t) => ({
              color: isOpenSubMenu ? t.palette.primary.main : t.palette.text.primary,
              backgroundColor: isOpenSubMenu ? t.palette.action.selected : 'white',
            })}
          >
            Add to group
          </MenuItem>
        )}
        <RemoveFromGroupItem metricId={metricId} onClose={handleCloseAllMenu} />
      </Menu>
      <Menu
        open={isOpenSubMenu}
        anchorEl={anchorSubmenu}
        onClose={handleCloseAllMenu}
        slotProps={{
          paper: {
            sx: { width: 218, ml: '-218px', mt: '-44px', maxHeight: 300 },
          },
        }}
      >
        <Suspense fallback={<MetricMenuGroupsSkeleton />}>
          <MetricMenuGroups
            metricId={metricId}
            closeMenus={() => {
              parentRef.current?.classList.remove('hover');
              handleCloseAllMenu();
            }}
          />
        </Suspense>
      </Menu>
    </>
  ) : null;
};

export default MetricMenu;
