import { observer } from 'mobx-react-lite';
import { useWatch } from 'react-hook-form';
import bulkDuplicateStore from '../../stores/bulkDuplicateStore';
import type { FC } from 'react';
import { useEffect } from 'react';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import HighlightSearchTerm from './HighlightSearchTerm';

interface RulesListProps {
  mode: 'edit' | 'duplicate';
}

const RulesList: FC<RulesListProps> = observer(({ mode }) => {
  const isDuplicate = mode === 'duplicate';
  const nameField = useWatch({ name: 'name' });
  const replaceWithField = useWatch({ name: 'replaceWith' });
  const rules = bulkDuplicateStore.list;
  const defaultStateList = bulkDuplicateStore.defaultStateList;

  const lastRenamedRules = bulkDuplicateStore.lastRenamedRules;

  const handleHighlightState = () => {
    bulkDuplicateStore.clearRenamedRules();
  };

  useEffect(() => {
    const MuiDialog = document.querySelector('.MuiDialog-container');
    MuiDialog?.addEventListener('click', handleHighlightState);
    return () => {
      MuiDialog?.removeEventListener('click', handleHighlightState);
    };
  }, []);

  return rules.map((r) => {
    const isRenamed = defaultStateList.find((i) => i.id === r.id)?.name !== r.name;

    return (
      <TableRow
        key={`DuplicateRulesItem_${r.id}_${r.name}`}
        hover
        sx={(t) => (lastRenamedRules.includes(r.id) ? { backgroundColor: t.palette.action.focus } : {})}
      >
        <TableCell component="td">
          <HighlightSearchTerm originalText={r.name} searchTerm={nameField} replaceTerm={replaceWithField} />
        </TableCell>
        <TableCell component="td">
          {!isRenamed && isDuplicate && (
            <Tooltip title="Change name to save" placement="left" arrow>
              <InfoOutlined sx={(t) => ({ color: t.palette.warning.main })} />
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    );
  });
});

export default RulesList;
