import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import normalizeObject from '../utils/normalizeObject';
import type { RuleData, RuleEntry } from '../types';
import orderRule from '../utils/orderRule';

interface RuleEditor {
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
  prettify: () => string;
}
const useRuleForEditor = (rule: RuleEntry): RuleEditor => {
  const [code, setCode] = useState('{}');

  const prettify = () => {
    return JSON.stringify(JSON.parse(code), null, 2);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (rule.data) {
      setCode(JSON.stringify(normalizeObject(orderRule(rule.data) as RuleData), null, 2));
    }
  }, [rule.data]);

  return { code, setCode, prettify };
};

export default useRuleForEditor;
