import type { FC } from 'react';
import RuleEditFilterGroup from './RuleEditFilterGroup';
import filterStore from '../stores/filterStore';
import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';

const RuleEditFiltersContainer: FC = observer(() => {
  const addFilterGroup = () => {
    filterStore.addFilterGroup();
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" sx={{ mr: 3 }}>
          Filters
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InfoOutlinedIcon sx={(t) => ({ color: t.palette.primary.main, fontSize: 14, mr: 1 })} />
          <Typography variant="caption">Filter groups have “OR” relation</Typography>
        </Box>
      </Box>
      <Box>
        {filterStore.filterGroups.map((fg, i) => (
          <RuleEditFilterGroup key={i} groupIndex={i} filterGroup={fg} />
        ))}
      </Box>
      <Box sx={{ py: 2.5 }}>
        <Button onClick={addFilterGroup}>
          <AddIcon sx={{ mr: 1 }} /> Add filter group
        </Button>
      </Box>
    </Box>
  );
});

export default RuleEditFiltersContainer;
