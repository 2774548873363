import * as yup from 'yup';
import type { TestContext } from 'yup';

const ruleEditSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(1)
    .label("Can't be empty")
    .when('$duplicateMode', {
      is: (val: boolean) => val,
      then: (schema) =>
        schema.test(
          'uniqueRule',
          'You have to change rule name for duplicating',
          // @ts-expect-error test fn fix
          function (value, context: TestContext<{ initialRuleName: string }>) {
            return context.options.context?.initialRuleName !== value;
          }
        ),
      otherwise: (schema) => schema,
    }),
  project: yup.number().required().label('project').positive('Please select project'),
  flows: yup.array().of(yup.number()).label('flow'),
  level: yup.string().required().oneOf(['adset', 'ad', 'campaign']).label('level'),
  groupId: yup.string().label('rule group'),
  description: yup.string(),
  notificationChannels: yup.array(),
  notificationLevel: yup.string(),
  notificationMessage: yup
    .object()
    .shape({
      title: yup.string().nullable(),
      body: yup.string().nullable(),
    })
    .nullable(),
  facebookAdAccountIds: yup.array(yup.string()),
  ruleStatus: yup.string().required().oneOf(['paused', 'archived', 'active']),
  networkId: yup.number(),
  actionTypesIds: yup.array(yup.number()),
});

export default ruleEditSchema;
