import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';

export interface ActionsTypeEntry {
  id: string;
  name: string;
}

export const getActionsType = async (signal?: AbortSignal): Promise<ActionsTypeEntry[]> => {
  const res = await apiClient.get<ActionsTypeEntry[]>(`/action_types`, {
    signal,
  });
  return res.data;
};

interface UseActionsTypeOptions<TData> {
  config?: QueryConfig<typeof getActionsType, TData>;
}

export const useActionsType = <TData = ActionsTypeEntry[]>({ config }: UseActionsTypeOptions<TData> = {}) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getActionsType(signal),
    queryKey: ['action_types'] as QueryKey,
  });
};
