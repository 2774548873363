import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const LogsTableHead = () => {
  return (
    <TableHead sx={{ position: 'relative', zIndex: 3, height: 56 }}>
      <TableRow sx={{ th: { height: 56, border: 'none', fontWeight: 'bold', py: 1, px: 1 } }}>
        <TableCell sx={{ minWidth: '100px', maxWidth: '200px !important' }}>
          <Typography variant="body2" fontWeight="500">
            Action
          </Typography>
        </TableCell>
        <TableCell sx={{ minWidth: '200px', maxWidth: '400px !important' }}>
          <Typography variant="body2" fontWeight="500">
            Item name
          </Typography>
        </TableCell>
        <TableCell sx={{ minWidth: '100px', maxWidth: '200px !important' }}>
          <Typography variant="body2" fontWeight="500">
            Date and time
          </Typography>
        </TableCell>
        <TableCell sx={{ minWidth: '100px', maxWidth: '200px !important' }}>
          <Typography variant="body2" fontWeight="500">
            User
          </Typography>
        </TableCell>
        <TableCell sx={{ minWidth: '100px', maxWidth: '200px !important' }} align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default LogsTableHead;
