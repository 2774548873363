import { Box, Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import RunningRuleStatus from './RunningRuleStatus';
import type { RuleStateValue, RuleStatusValue } from '../types';

interface Color {
  border: string;
  background: string;
  content?: string;
}

type RuleStatusAppearance = Exclude<RuleStatusValue, 'active'> | Exclude<RuleStateValue, 'running' | ''>;

const color: Record<RuleStatusAppearance, Color> = {
  paused: { border: 'rgba(251, 140, 0, 1)', background: 'rgba(255, 249, 240, 1)', content: 'rgba(251, 140, 0, 1)' },
  archived: { border: 'rgba(158, 158, 158, 1)', background: 'rgba(245, 245, 245, 1)', content: 'rgba(0, 0, 0, 0.54)' },
  failed: { border: 'rgba(244, 67, 54, 0.9)', background: 'rgba(254, 240, 243, 1)' },
  completed: { border: 'rgba(0, 121, 107, 0.76)', background: 'rgba(241, 249, 249, 1)' },
};

interface RuleStatusProps {
  status: Exclude<RuleStatusValue, 'active'> | RuleStateValue;
}

const RuleStatus: FC<RuleStatusProps> = ({ status }) => {
  if (!status) return null;

  if (status === 'running') {
    return (
      <Tooltip
        arrow
        title={<Typography sx={{ fontSize: 10, fontWeight: 500 }}>running</Typography>}
        sx={{ cursor: 'default' }}
      >
        <Box sx={{ width: 24, minWidth: 24, mx: 'auto' }}>
          <RunningRuleStatus />
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      arrow
      title={<Typography sx={{ fontSize: 10, fontWeight: 500 }}>{status}</Typography>}
      sx={{ cursor: 'default' }}
    >
      <Box
        sx={{
          width: 24,
          minWidth: 24,
          height: 15,
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: color[status].border,
          borderRadius: '10px',
          background: color[status].background,
          position: 'relative',
          mx: 'auto',
        }}
      >
        {(status === 'archived' || status === 'paused') && (
          <Box
            sx={{
              width: 4,
              height: 4,
              background: color[status].content,
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default RuleStatus;
