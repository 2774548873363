import { useCallback, useState } from 'react';

export function useMultipleAnchorMenus<T = HTMLElement>() {
  const [anchors, setAnchors] = useState<{ [key: number | string]: null | T }>({});

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<T>, id: number | string) => {
      setAnchors({ ...anchors, [id]: event.currentTarget });
    },
    [anchors]
  );

  const handleCloseMenu = useCallback(
    (id: number | string) => {
      setAnchors({ ...anchors, [id]: null });
    },
    [anchors]
  );

  const handleCloseAllMenu = useCallback(() => {
    setAnchors({});
  }, []);

  const isOpen = useCallback((id: string | number) => !!anchors[id], [anchors]);

  return {
    anchors,
    handleOpenMenu,
    handleCloseMenu,
    handleCloseAllMenu,
    isOpen,
  };
}
