import { Drawer, ModalProps } from '@mui/material';
import { FC, ReactNode } from 'react';

interface FlowsDrawerProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  modalProps?: Partial<ModalProps>;
}
export const FlowsDrawer: FC<FlowsDrawerProps> = ({ open, onClose, modalProps, children }): ReactNode => (
  <Drawer
    anchor={'right'}
    open={open}
    onClose={onClose}
    PaperProps={{ sx: { width: '100%', maxWidth: 500, px: 2.5, py: 3, boxShadow: 'none' } }}
    ModalProps={modalProps}
  >
    {children}
  </Drawer>
);
