import type { TestContext } from 'yup';

export function timeFormatValidation(this: TestContext, value: string) {
  try {
    const parts = value.split(':');

    if (parts.length !== 2) {
      throw new Error('Invalid time format, extra or less ":"');
    }

    const isValidLength = parts.every((p) => p.length === 2);
    if (!isValidLength) {
      throw new Error('Hours, minutes should have 2 numbers, i.e 12:10');
    }

    const isValidNumbers = parts.every((p) => !isNaN(Number(p)));
    if (!isValidNumbers) {
      throw new Error('Not a number value');
    }

    const isHoursValid = Number(parts[0]) <= 24;
    if (!isHoursValid) {
      throw new Error('Hours should be in range from 00 to 24');
    }

    const isMinuteValid = Number(parts[1]) <= 59;
    if (!isMinuteValid) {
      throw new Error('Minutes should be in range from 00 to 59');
    }

    return true;
  } catch (e) {
    // @ts-expect-error error type correction
    return this.createError({ message: String(e.message) });
  }
}
