import { useSuspenseQuery } from '@tanstack/react-query';
import { ListResponse, PaginationParams, apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryKeys from './queryKeys';
import queryString from 'query-string';
import { MetricEntry, MetricParams } from './types';

export const getMetrics = async (
  { params }: { params: MetricParams },
  signal?: AbortSignal
): Promise<ListResponse<MetricEntry>> => {
  const res = await apiClient.get<ListResponse<MetricEntry>>(`/metrics`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseMetrics<TData> {
  config?: QueryConfig<typeof getMetrics, TData>;
  params: MetricParams & PaginationParams;
}

export const useMetricsList = <TData = ListResponse<MetricEntry>>({ params, config }: UseMetrics<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getMetrics({ params }, signal),
    queryKey: queryKeys.byFilterValues('metrics', params),
  });
};
