import { Box, CssBaseline, LinearProgress } from '@mui/material';
import LoginForm from './LoginForm';
import useCheckSession from 'hooks/useCheckSession';
import { GradientBox, TitleStyled } from './LoginComponents';
import IconLogo from 'assets/icon-logo.svg?react';

const Login = () => {
  const { isChecked } = useCheckSession();

  return isChecked ? (
    <>
      <CssBaseline />
      <Box sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        height: '100vh',
        minWidth: 320,
        p: 1,
        background: 'rgba(156, 39, 176, 0.04)',
      }}>
        <GradientBox>
          <IconLogo width={140} />
          <TitleStyled>Campaigns automation</TitleStyled>
        </GradientBox>
        <LoginForm />
      </Box>
    </>
  ) : (
    <LinearProgress />
  );
};

export default Login;
