import { Chip, ListItem, ListItemButton, Theme, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Box, SxProps } from '@mui/system';

const ListItemStyled: FC<{
  dataId?: string;
  name: string;
  quantity: string | number;
  selected: boolean;
  beforeElement?: ReactNode;
  onClick: () => void;
  sx?: SxProps<Theme>;
}> = ({ name, quantity, selected, beforeElement, sx, dataId, onClick }) => (
  <ListItem disablePadding id={dataId}>
    <ListItemButton
      selected={selected}
      onClick={onClick}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '8px',
        px: 1,
        py: 0.75,
        mb: 1,
        '& .beforeElement:not(.visible)': { visibility: 'hidden' },
        '&:hover .beforeElement, & .beforeElement.visible': { visibility: 'visible' },
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        {beforeElement}
        <Typography
          sx={(t) => ({
            fontSize: t.typography.body2.fontSize,
            p: 0,
            m: 0,
            color: selected ? t.palette.primary.main : t.palette.text.primary,
          })}
        >
          {name}
        </Typography>
      </Box>
      <Chip
        size="small"
        label={
          <Typography
            sx={(t) => ({
              fontSize: t.typography.body2.fontSize,
              color: selected ? t.palette.primary.main : t.palette.text.primary,
            })}
          >
            {quantity}
          </Typography>
        }
        sx={(t) => ({
          '&': { p: 0, m: 0, borderRadius: t.shape.borderRadius / 2, backgroundColor: selected ? 'transparent' : '' },
        })}
      />
    </ListItemButton>
  </ListItem>
);

export default ListItemStyled;
