import { TableCell, TableRow } from '@mui/material';
import { type FC } from 'react';
import { MetricEntry } from '../types';
import { useWatch } from 'react-hook-form';
import { MetricChip, MetricInput, TableCellStickyStyled } from './MetricsComponents';
import { isEmpty } from 'lodash-es';

interface MetricsEditTableRowProps {
  metric: MetricEntry;
}

const MetricsEditTableRow: FC<MetricsEditTableRowProps> = ({ metric }) => {
  const tab = useWatch({ name: 'tab' });
  const isTabValues = tab === 'values';

  return (
    <TableRow
      sx={{
        td: { height: 64, px: 2 },
      }}
    >
      <TableCellStickyStyled sx={{ left: 0, zIndex: 2, backgroundColor: 'white' }}>
        <MetricChip label={metric.name} />
      </TableCellStickyStyled>
      {!isEmpty(metric.projects) ? (
        metric.projects.map((prj) => (
          <TableCell key={`${prj.id}.${prj.value}`} sx={{ display: isTabValues ? 'table-cell' : 'none' }}>
            <MetricInput
              name={`metrics_edit.values.${metric.id}:${prj.id}`}
              inputStyle={{ minWidth: 64, height: 40, paddingTop: 0, paddingBottom: 0, appearance: 'none' }}
              sx={{
                '&': { minWidth: 64, maxWidth: 154 },
              }}
              type={'number'}
            />
          </TableCell>
        ))
      ) : (
        <TableCell sx={{ display: isTabValues ? 'table-cell' : 'none' }}></TableCell>
      )}

      <TableCell sx={{ display: !isTabValues ? 'table-cell' : 'none' }}>
        <MetricInput
          name={`metrics_edit.descriptions.${metric.id}`}
          inputStyle={{ minWidth: 200, height: 40, paddingTop: 0, paddingBottom: 0 }}
          sx={{ '&': { maxWidth: 300 } }}
        />
      </TableCell>
    </TableRow>
  );
};

export default MetricsEditTableRow;
