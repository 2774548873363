import { Box } from '@mui/material';
import Input from 'components/common/Input';
import useListParams from 'hooks/useListParams';
import { debounce } from 'lodash-es';
import { ChangeEvent, useCallback, useMemo } from 'react';
import MetricsAddToGroups from './MetricsAddToGroups';

const FiltersPanel = () => {
  const { setQ } = useListParams();

  const setQueryStringDebounce = useMemo(() => debounce(setQ, 500), [setQ]);

  const handleQueryString = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setQueryStringDebounce(e.target.value);
    },
    [setQueryStringDebounce, setQ]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        py: 2,
        justifyContent: 'space-between',
      }}
    >
      <Input
        clearButton={() => {
          setQ(undefined);
        }}
        name="q"
        onChange={handleQueryString}
        placeholder="Search by metric name"
        inputStyle={{ width: '100%' }}
        containerSx={{ maxWidth: '430px', flex: '1 0 0' }}
      />
      <MetricsAddToGroups />
    </Box>
  );
};

export default FiltersPanel;
