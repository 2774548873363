import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import { BulkActionRulesParams } from './types';

interface RunRulesResponse {
  message: string;
}

export const runRules = async (params: BulkActionRulesParams): Promise<RunRulesResponse> => {
  const res = await apiClient.post<{ data: RunRulesResponse }>(`/rules/trigger`, params);
  return res.data.data;
};

interface UseRunRulesOptions {
  config?: MutationConfig<typeof runRules>;
}

export const useRunRules = ({ config }: UseRunRulesOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['platforms'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['rules'],
        }),
      ]);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: runRules,
  });
};
