import { CssBaseline, Box } from '@mui/material';
import type { FC, ReactNode } from 'react';
import Sidebar from './Sidebar';
import type { SystemStyleObject } from '@mui/system';
import { EmergencyLayout } from 'features/emergency';
import HotjarInitial from 'lib/hotjar/HotjarInitial';

interface LayoutProps {
  children: ReactNode;
  hideSidebar?: boolean;
  containerSx?: SystemStyleObject;
}

const Layout: FC<LayoutProps> = ({ children, hideSidebar = false, containerSx }) => {
  return (
    <HotjarInitial>
      <CssBaseline />
      <EmergencyLayout>
        {!hideSidebar && <Sidebar />}
        <Box
          sx={(t) => ({
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            pb: 3,
            px: 3,
            overflow: 'hidden',
            overflowY: 'auto',
            backgroundColor: 'white',
            borderRadius: 1,
            [t.breakpoints.up('sm')]: {
              pt: 3,
              pb: 0,
            },
            ...containerSx,
          })}
        >
          {children}
        </Box>
      </EmergencyLayout>
    </HotjarInitial>
  );
};
export default Layout;
