import * as yup from 'yup';

const bulkDuplicateSchema = yup.object().shape({
  name: yup.string().when('$isRenamingApplied', {
    is: (val: boolean) => val,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required('Changing rule names before saving is required'),
  }),
  replaceWith: yup.string(),
  project: yup.number().required("Can't be empty"),
  flow: yup.string().when('project', {
    is: (val: string) => !val,
    then: (schema) => schema.required('Choose project to select flow'),
    otherwise: (schema) => schema.required("Can't be empty"),
  }),
  description: yup.string().nullable(),
  notificationChannels: yup.string().required("Can't be empty"),
  facebookAdAccountIds: yup.array().min(1, "Can't be empty").required("Can't be empty"),
  status: yup.string().oneOf(['paused', 'archived', 'active']).required(),
});

export default bulkDuplicateSchema;
