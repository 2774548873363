import type { SelectChangeEvent } from '@mui/material';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import useListParams from 'hooks/useListParams';
import { useCallback, useEffect } from 'react';
import type { State, Status } from 'types/shared';
import { useStatuses } from '../api/getStatuses';
import { Controller, useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash-es';

const FilterStatus = () => {
  const { statusState, setStatusState, tab } = useListParams();
  const isShowFilter = tab !== 'paused' && tab !== 'archived';
  const { control } = useFormContext();

  const { data: statuses } = useStatuses({
    params: {
      tab: tab!,
    },
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })),
    },
  });

  const handleChange = useCallback(
    (e: SelectChangeEvent<(string | null)[]>, onChange: (...event: any[]) => void) => {
      setStatusState(e.target.value as (Status | State)[]);
      onChange(e.target.value);
    },
    [setStatusState]
  );

  useEffect(() => {
    if (isEmpty(statusState) && tab !== 'all') {
      setStatusState(['active']);
    }
  }, [statusState]);

  return isShowFilter ? (
    <Box sx={{ display: 'flex', maxWidth: 200, minWidth: 108, flex: 1 }}>
      <FormControl sx={{ display: 'flex', flexGrow: 1 }}>
        <InputLabel size="small" id="filter-action-type">
          Status
        </InputLabel>
        <Controller
          name={'statusState'}
          control={control}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <Select
                disabled={!statuses.length}
                labelId="filter-status"
                id="select-status"
                multiple
                value={!isEmpty(statusState) ? statusState.filter((i) => i !== tab) : []}
                onChange={(e) => {
                  handleChange(e, onChange);
                }}
                input={<OutlinedInput id="select-status-input" label="Status" />}
                renderValue={(selected) => (
                  <Typography
                    sx={(t) => ({
                      display: 'block',
                      fontSize: t.typography.body2.fontSize,
                      textOverflow: 'ellipsis',
                      width: '100%',
                      paddingRight: 6,
                      overflow: 'hidden',
                    })}
                  >
                    {statuses
                      .filter((item) => selected.includes(item.value))
                      .map((item) => item.label)
                      .join(', ')}
                  </Typography>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: 200,
                      marginTop: 2,
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                {statuses.map((item) => (
                  <MenuItem sx={{ px: 0 }} value={item.value} key={`${item.value}:${item.label}`}>
                    <Checkbox checked={statusState.some((i) => i === item.value)} />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </Select>
            );
          }}
        />
      </FormControl>
    </Box>
  ) : null;
};

export default FilterStatus;
