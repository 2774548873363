/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    blueGray: true;
    infoFill: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    blueGray: Palette['primary'];
    infoFill: Palette['primary'];
    blue: Palette['primary'];
    error: Palette['primary'];
    indigo: Palette['primary'];
    emergency: Palette['primary'];
  }

  interface PaletteOptions {
    blueGray?: PaletteOptions['primary'];
    infoFill?: PaletteOptions['primary'];
    blue?: PaletteOptions['primary'];
    error?: PaletteOptions['primary'];
    indigo?: PaletteOptions['primary'];
    emergency?: PaletteOptions['primary'];
  }
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }

  export interface ShapeOverrides {
    borderRadius: {
      sm: 2;
      md: 4;
    };
  }

  export interface Theme {
    borderRadius: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
    };
  }

  export interface ThemeOptions {
    borderRadius?: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
    };
  }
}
export const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  borderRadius: {
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
  },
  palette: {
    blue: { main: '#3D5AFE' },
    blueGray: { main: '#E2E7E9' },
    infoFill: { main: 'rgba(236, 239, 241, 1)' },
    indigo: { main: 'rgba(48, 79, 254, 1)' },
    emergency: { main: 'rgba(255, 23, 68, 1)', light: 'rgba(255, 219, 226, 1)' },
    grey: {
      50: '#ECEFF1',
      // 75: '#E2E7E9',
      100: 'rgba(0, 0, 0, 0.15)',
      200: '#DEE5EC',
      300: '#E0E0E0',
    },

    primary: {
      main: 'rgba(61, 90, 254, 1)',
      dark: 'rgba(37, 57, 189, 1)',
    },

    info: {
      main: 'rgba(2, 136, 209, 1)',
    },
    error: {
      main: 'rgba(198, 40, 40, 1)',
    },

    text: {
      primary: 'rgba(6, 3, 43, 0.87)',
      secondary: 'rgba(10, 5, 72, 0.6)',
      disabled: 'rgba(8, 4, 57, 0.38)',
    },

    action: {
      active: 'rgba(35, 52, 112, 0.56)',
      selected: 'rgba(61, 90, 254, 0.08)',
      hover: 'rgba(41, 121, 255, 0.04)',
      focus: 'rgba(41, 121, 255, 0.12)',
    },
  },
  typography: {
    h1: {
      fontSize: '48px',
      fontWeight: 400,
    },
    h2: {
      fontSize: '34px',
      fontWeight: 400,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 400,
    },
    h4: {
      fontSize: '20px',
      fontWeight: 400,
    },
    h5: {
      fontSize: '20px',
      fontWeight: 400,
    },
    h6: {
      fontSize: '20px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.25,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.13,
    },
    caption: {
      fontSize: '0.813rem',
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      },
    },
    MuiButton: {
      styleOverrides: { root: { boxShadow: 'none', '&:hover': { boxShadow: 'none' } } },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: '56px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          [`& .MuiInputLabel-root`]: {
            fontSize: '0.875rem',
            lineHeight: '1.4rem',
          },
          [`& .MuiInputBase-root`]: {
            minHeight: 40,
          },
          ['& .MuiOutlinedInput-root']: {
            maxHeight: 'fit-content',
          },
        },
        option: {
          fontSize: '0.875rem',
          lineHeight: '1.4rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          [`&`]: {
            width: '100%',
          },
          [`& .MuiInputLabel-root`]: {
            fontSize: '0.875rem',
            lineHeight: '1.4rem',
          },
          [`& .MuiInputBase-root`]: {
            minHeight: 40,
          },
        },
        select: {
          [`&`]: {
            minWidth: 120,
            minHeight: 40,
            padding: '0px 4px 0px 12px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          borderRadius: 12,
          minHeight: 40,
          borderColor: theme.palette.grey[300],
        }),
        notchedOutline: ({ _, theme }) => ({
          borderColor: theme.palette.grey[300],
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          fontSize: '0.875rem',
          lineHeight: '1.4rem',
          '& .MuiOutlinedInput-root': {
            borderRadius: 12,
            minHeight: 40,
            maxHeight: 40,
            '& fieldset': {
              borderColor: theme.palette.grey[100],
            },
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 40,
          maxHeight: 40,
        },
        input: {
          fontSize: '0.875rem',
          lineHeight: '1.4rem',
          borderRadius: 12,
        },
        formControl: {
          '&': {
            borderRadius: 12,
          },
        },
        multiline: {
          maxHeight: 'max-content !important',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          mx: 2,
          [theme.breakpoints.up('md')]: {
            mx: 3,
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          paddingLeft: '12px',
          paddingRight: '12px',
          paddingTop: '4px',
          paddingBottom: '4px',
          minHeight: '36px !important',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '20px !important',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          padding: '6px 8px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          fontSize: theme.typography.body2.fontSize,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        sizeSmall: {
          width: 28,
          height: 16,
          padding: 0,
          [`.Mui-checked`]: {
            transform: 'translateX(12px) !important',
          },
          '& .MuiSwitch-switchBase': {
            width: 12,
            height: 12,
            padding: 0,
          },
          '& .MuiSwitch-thumb': {
            width: 12,
            height: 12,
          },
        },
        sizeMedium: {
          width: 32,
          height: 20,
          padding: 0,
          [`.Mui-checked`]: {
            transform: 'translateX(12px) !important',
          },
          '& .MuiSwitch-switchBase': {
            width: 16,
            height: 16,
            padding: 0,
          },
          '& .MuiSwitch-thumb': {
            width: 16,
            height: 16,
          },
        },
        switchBase: {
          top: 2,
          left: 2,
          padding: 0,
        },
        thumb: {
          background: 'white',
        },
        track: ({ _, theme }) => ({
          borderRadius: 14,
          backgroundColor: theme.palette.grey[400],
          opacity: '1 !important',
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          backgroundColor: theme.palette.grey[300],
        }),
        bar: {
          background: `linear-gradient(90deg, #4BD1FC 0%, #A7ABF5 16.6%, #7F57F2 33.85%, #F62AB9 54.17%, #FDA680 84.86%, #F8DA70 100%)`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ _, theme }) => ({
          borderRadius: theme.borderRadius.sm,
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          boxShadow: 'unset',
          '&::before': { display: 'none' },
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 8px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ _, theme }) => ({
          fontSize: theme.typography.body2.fontSize,
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '0.925em',
          height: '0.925em',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: ({ _, theme: t }) => ({
          backgroundColor: t.palette.primary.main,
          '&': {
            backgroundColor: t.palette.primary.main,
          },

          '& .MuiChip-label, & .MuiChip-label *': {
            color: 'white',
          },

          '& .MuiChip-deleteIcon': {
            color: 'white',
            opacity: '0.9',
          },

          '& .MuiChip-deleteIcon:hover': {
            color: 'white',
            opacity: '0.9',
          },
        }),
        root: ({ _, theme: t }) => ({
          '&': {
            borderRadius: t.borderRadius.md,
            backgroundColor: 'rgba(35, 52, 112, 0.08)',
            height: 24,
            marginRight: 6,
            marginBottom: 6,
          },

          '& .MuiChip-label & .MuiChip-label *': {
            color: t.palette.text.primary,
          },

          '& .MuiChip-deleteIcon': {
            color: t.palette.grey[500],
            opacity: '0.9',
          },

          '& .MuiChip-deleteIcon:hover': {
            color: t.palette.grey[800],
            opacity: '0.9',
          },
        }),
        label: ({ _, theme: t }) => ({
          '&, & *': {
            fontSize: t.typography.caption.fontSize,
          },
          '&': {
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
          },
        }),
        deleteIcon: {
          color: 'white',
          opacity: '0.7',
          fontSize: 18,
          marginLeft: 1,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          px: 0,
          justifyContent: 'flex-start',
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ _, theme: t }) => ({
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.02),0px 8px 10px 1px rgba(0,0,0,0.04),0px 3px 14px 2px rgba(0,0,0,0.06)',
        }),
        root: ({ _, theme: t }) => ({
          '& .MuiBackdrop-root': {
            opacity: '0 !important',
            transition: 'none !important',
          },
          '& .Mui-selected': {
            color: t.palette.primary.main,
          },
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ _, theme: t }) => ({
          '&.MuiAlert-colorInfo .MuiAlert-icon': {
            color: 'rgba(2, 136, 209, 1)',
          },
          fontSize: t.typography.body2.fontSize,
          lineHeight: t.typography.body1.lineHeight,
        }),
        standardInfo: {
          backgroundColor: 'rgba(236, 239, 241, 1)',
          color: 'rgba(1, 67, 97, 1)',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          fontSize: 12,
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
};

const theme = createTheme(themeOptions);

export default theme;
