import { usePagination } from 'hooks';
import { ArrayParam, StringParam, useQueryParam, withDefault } from 'use-query-params';

const useLogsQueryParams = () => {
  const [q, setQ] = useQueryParam('q', withDefault(StringParam, undefined));
  const [email, setEmail] = useQueryParam('item_name', withDefault(StringParam, undefined));
  const [action, setAction] = useQueryParam('action', withDefault(ArrayParam, []));
  const [date, setDate] = useQueryParam('date', withDefault(StringParam, undefined));
  const [itemType, setItemType] = useQueryParam('item_type', withDefault(ArrayParam, []));
  const { perPage, page, handleRowsPerPageChange, resetPagination, setPerPage, handlePageChange } = usePagination(
    [q, email, action, date, itemType],
    100
  );

  return {
    params: {
      q,
      action,
      email,
      itemType,
      perPage,
      page,
      date,
    },
    actions: {
      setQ,
      setEmail,
      setAction,
      setItemType,
      handleRowsPerPageChange,
      resetPagination,
      setPerPage,
      handlePageChange,
      setDate,
    },
  };
};

export default useLogsQueryParams;
