const normalizeObject = (obj: unknown): object | any[] | undefined => {
  if (obj === null) return;

  if (Array.isArray(obj))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return obj.map((v: unknown) => (v && typeof v === 'object' ? normalizeObject(v) : v)).filter((v) => v !== null);

  return Object.entries(obj as object).length
    ? Object.entries(obj as object)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .map(([k, v]) => [k, v && typeof v === 'object' ? normalizeObject(v) : v])
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        .reduce((a, [k, v]) => (v === null ? a : { ...a, [k as string]: v }), {})
    : obj;
};

export default normalizeObject;
