import useListParams from 'hooks/useListParams';

const useProjectConditions = () => {
  const { project } = useListParams();
  const isNotAssigned = project === '0';

  return {
    isNotAssigned,
  };
};

export default useProjectConditions;
