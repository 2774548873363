import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import type { QueryConfig } from 'lib/react-query';
import { apiClient } from 'lib/api';
import { isAxiosError } from 'axios';

interface RecordType {
  id: number;
  name: string;
}

export const getRuleGroups = async (signal?: AbortSignal): Promise<RecordType[]> => {
  const res = await apiClient.get<RecordType[]>(`/rule_groups`, {
    signal,
  });
  if (isAxiosError(res)) {
    const error = new Error('Rule groups');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseRuleGroupsOptions<TData> {
  config?: QueryConfig<typeof getRuleGroups, TData>;
}

export const useRuleGroups = <TData = RecordType[]>({ config }: UseRuleGroupsOptions<TData> = {}) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getRuleGroups(signal),
    queryKey: ['rule_groups'] as QueryKey,
  });
};
