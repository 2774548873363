import { Chip, TableCell, TableRow, Typography } from '@mui/material';
import { useCallback, type FC, useRef } from 'react';
import { MetricEntry } from '../types';
import Checkbox from 'components/common/Checkbox';
import { useFormContext } from 'react-hook-form';
import MetricMenu from './MetricMenu';
import { isEmpty } from 'lodash-es';

const MetricsTableRow: FC<{ metric: MetricEntry }> = ({ metric }) => {
  const rowRef = useRef<HTMLTableRowElement | null>(null);
  const { setValue } = useFormContext();

  const handleIdMetricDetails = useCallback(
    (id: number) => {
      setValue('id_metric_details', id);
    },
    [setValue]
  );

  return (
    <TableRow
      ref={rowRef}
      sx={{
        td: { height: 64, px: 1 },
        '& td': {
          background: 'white',
        },
        '&:hover td, &.hover td': {
          background: 'rgb(239 244 255)',
        },
      }}
    >
      <TableCell sx={{ position: 'sticky', left: 0, top: 0, zIndex: 4 }}>
        <Checkbox name={`metrics.${metric.id}`} controlLabelSx={{ '&': { p: 0, m: 0 } }} />
      </TableCell>
      <TableCell
        sx={{ position: 'sticky', left: 54, top: 0, zIndex: 4 }}
        onClick={() => handleIdMetricDetails(metric.id)}
      >
        <Chip
          label={metric.name}
          sx={{
            '&': {
              height: 32,
            },
            '& .MuiChip-label': {
              display: 'block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: 300,
            },
          }}
        />
      </TableCell>
      {!isEmpty(metric.projects) ? (
        metric.projects.map((prj) => (
          <TableCell key={`${prj.name}.${prj.value}`} onClick={() => handleIdMetricDetails(metric.id)}>
            {prj.value ? (
              <Chip
                label={prj.value}
                sx={{
                  '&': {
                    height: 32,
                  },
                }}
              />
            ) : (
              <Typography variant="caption">-</Typography>
            )}
          </TableCell>
        ))
      ) : (
        <TableCell></TableCell>
      )}
      <TableCell align="right">
        <MetricMenu id={metric.id} parentRef={rowRef} />
      </TableCell>
    </TableRow>
  );
};

export default MetricsTableRow;
