import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import type { QueryConfig } from 'lib/react-query';
import type { Project } from './types';
import { Tabs } from 'types/shared';

interface RecordType {
  id: number;
  name: Tabs;
}

export const getStatusTabs = async (signal?: AbortSignal): Promise<RecordType[]> => {
  return Promise.resolve([
    {
      "id": 1,
      "name": "Active"
    },
    {
      "id": 2,
      "name": "Paused"
    },
    {
      "id": 3,
      "name": "Archived"
    },
    {
      "id": 4,
      "name": "All"
    },
  ])

};

interface UseStatusTabsListOptions<TData> {
  config?: QueryConfig<typeof getStatusTabs, TData>;
}

export const useStatusTabs = <TData = Project[]>({ config }: UseStatusTabsListOptions<TData> = {}) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getStatusTabs(signal),
    queryKey: ['status_tabs'] as QueryKey,
  });
};
