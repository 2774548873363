import type { InfoAlertProps } from '../../../hooks/useEnqueueSnackbar';
import filtersGroupArrayValidationSchema from '../validation/filters.schema';
import filterStore from '../stores/filterStore';
import * as yup from 'yup';
import timeTableStore from '../stores/timeTableStore';
import { intervalSchema, timeTableDaySchema, timeTableUniqueTimeSchema } from '../validation/timeTable.schema';
import { tasksArraySchema } from '../validation/tasks.schema';
import tasksFormStore from '../stores/tasksFormStore';
import type { RuleEntry } from '../types';
import { isEqual } from 'lodash-es';
import { toJS } from 'mobx';

const highlightError = (selector: string) => {
  const searchedElement = document.querySelector(selector);

  if (searchedElement) {
    searchedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    searchedElement.setAttribute('style', 'border: 2px solid red;');
    setTimeout(() => {
      searchedElement.removeAttribute('style');
    }, 3000);
  }
};
export const validateFilters = async (callToast: InfoAlertProps) => {
  try {
    await filtersGroupArrayValidationSchema.validate(filterStore.filterGroups);
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      const [filterGroupIndex, filterIndex] = String(error.path)
        .split('')
        .filter((el) => !Number.isNaN(Number(el)));

      if (!filterGroupIndex || !filterIndex) {
        callToast(error.message);
        highlightError('#filters');
      } else {
        highlightError(`#fg-${filterGroupIndex} #filter-${filterIndex} div`);
      }
    }
    return false;
  }

  return true;
};

export const validateSchedule = async (callToast: InfoAlertProps) => {
  try {
    if (timeTableStore.scheduleInterval) {
      await intervalSchema.validate(timeTableStore.intervalDTO);
    } else {
      const timeArray = timeTableStore.reversedTimetableEntries.map(([k, v]) => v.time);
      const daysArray = timeTableStore.reversedTimetableEntries.map(([k, v]) => v.days);

      await timeTableUniqueTimeSchema.validate(timeArray);
      await timeTableDaySchema.validate(daysArray);
    }
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      if (timeTableStore.scheduleInterval || !error?.path || !String(error.path).length) {
        callToast(error.message);
        highlightError('#timetable');
      } else {
        const index = String(error.path)[1];
        highlightError(`#time-row-${index}`);
      }
    }

    return false;
  }

  return true;
};

export const validateTasks = async (callToast: InfoAlertProps) => {
  try {
    const castedValue = tasksArraySchema.cast(tasksFormStore.tasksFormValues);
    await tasksArraySchema.validate(castedValue);
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      if (String(error.path).includes('condition')) {
        const [task, condition] = String(error.path)
          .split('')
          .filter((el) => !Number.isNaN(Number(el)));

        highlightError(`#task-item-${task} #condition-${condition}`);
      } else if (String(error.path).includes('actions')) {
        const [task, action] = String(error.path)
          .split('')
          .filter((el) => !Number.isNaN(Number(el)));

        highlightError(`#task-item-${task} #action-${action}`);
      } else {
        callToast(error.message);
        highlightError('#tasks');
      }
    }
    return false;
  }

  return true;
};

interface RuleChangesProps {
  rule: RuleEntry;
  callToast: InfoAlertProps;
  isBasicFormDirty: boolean;
}

export const validateRuleForChanges = ({ rule, callToast, isBasicFormDirty }: RuleChangesProps) => {
  const timeTableObj = rule.data.timetable ? timeTableStore.timeTableDTO : timeTableStore.intervalDTO;
  const ruleTimeTableObj = rule.data.timetable ? rule.data.timetable : rule.data.schedule_interval;

  const isEqualStateForm = [
    isEqual(rule.data.filters, toJS(filterStore.filterGroups)),
    isEqual(rule.data.tasks, toJS(tasksFormStore.tasksFormValues)),
    isEqual(ruleTimeTableObj, toJS(timeTableObj)),
  ];

  if (isBasicFormDirty || isEqualStateForm.some((el) => !el)) {
    return true;
  }

  callToast('Please, change rule content before saving');
  return false;
};
