import useListParams from 'hooks/useListParams';
import { useGroupsList } from '../api/getGroups';
import { MetricParams, UseGroupsList } from '../api/types';

const useGroups = (params?: Partial<MetricParams>) => {
  const { q } = useListParams();
  const {
    data: { data, options },
  } = useGroupsList<UseGroupsList>({
    params: {
      name: q,
      ...params,
    },
    config: {
      select: (data) => {
        const { groups } = data;
        return {
          options: groups.map(({ name, id }) => ({ value: id, label: name })),
          data,
        };
      },
    },
  });

  return { data, options };
};

export default useGroups;
