import { createContext, useContext } from 'react';

export interface EmergencyStateValues {
  isEmergencyModeAllow?: boolean;
  isEmergency: boolean;
  setEmergency: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmergencyContext = createContext<EmergencyStateValues>({} as EmergencyStateValues);

export function useEmergencyContext() {
  return useContext(EmergencyContext);
}
