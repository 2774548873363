import { Box, Skeleton } from '@mui/material';

const FiltersSkeleton = () => {
  return (
    <Box
      sx={{
        py: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        flexGrow: 1,
        gap: 1,
        maxHeight: 70,
        overflow: 'hidden',
        overflowX: 'auto',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Skeleton height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px' }} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Skeleton height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px' }} />
      </Box>
      <Box sx={{ width: '10%' }}>
        <Skeleton height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px' }} />
      </Box>
      <Skeleton width={100} height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px' }} />
      <Skeleton width={100} height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px' }} />
      <Box sx={{ flex: 1 }}>
        <Skeleton height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px' }} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Skeleton height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px' }} />
      </Box>
    </Box>
  );
};

export default FiltersSkeleton;
