import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import ListItemStyled from 'components/common/ListItemStyled';
import { useAnchorMenu } from 'hooks';
import useListParams from 'hooks/useListParams';
import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

interface FlowItemProps {
  id: number | string;
  name: string;
  quantity: number;
  platformId: number;
  toggleDelete: (id: number | string) => void;
  toggleEdit: (id: number | string) => void;
}

const FilterPlatformsFlowItem: FC<FlowItemProps> = ({ toggleDelete, toggleEdit, id, name, quantity, platformId }) => {
  const { anchor, isOpen, handleOpenMenu, handleCloseMenu } = useAnchorMenu();
  const { listId, setListId, setPlatform, setNewFlow } = useListParams();
  const { setValue } = useFormContext();

  const isSelected = listId && (listId as string[]).includes(id.toString());
  const isAllowModify = !['not_assigned', 'android', 'ios'].includes(id as string);

  const handleChosen = useCallback(
    (itemId: number | string) => {
      setListId([itemId as string]);
      setPlatform(platformId);

      setValue('platform', platformId);
      setValue('listId', [itemId]);

      setNewFlow(undefined);
      isOpen && handleCloseMenu();
    },
    [isOpen, handleCloseMenu, setPlatform, setListId, setValue, setNewFlow, platformId]
  );

  return (
    <>
      <ListItemStyled
        selected={isSelected}
        dataId={`flow_${id}`}
        name={name}
        quantity={quantity}
        onClick={() => handleChosen(id.toString())}
        sx={!isAllowModify ? { '&': { pl: 3 } } : undefined}
        beforeElement={
          isAllowModify ? (
            <>
              <IconButton
                color={isSelected ? 'primary' : 'default'}
                size="small"
                className={`beforeElement ${isOpen ? 'visible' : ''}`}
                sx={{ p: 0, width: 12, mr: 0.5 }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={handleOpenMenu}
              >
                <MoreVertIcon></MoreVertIcon>
              </IconButton>
              <Menu
                slotProps={{
                  paper: {
                    sx: { width: 218, ml: -1, mt: 0.5 },
                  },
                }}
                anchorEl={anchor}
                open={isOpen}
                onClose={handleCloseMenu}
                hideBackdrop
              >
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCloseMenu();
                    toggleEdit(id);
                  }}
                >
                  Edit flow
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCloseMenu();
                    toggleDelete(id);
                  }}
                >
                  Delete flow
                </MenuItem>
              </Menu>
            </>
          ) : null
        }
      />
    </>
  );
};

export default FilterPlatformsFlowItem;
