import {
  DialogActions,
  DialogTitle,
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  CircularProgress,
  ButtonPropsColorOverrides,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { OverridableStringUnion } from '@mui/types';

export interface DialogProps {
  title: string;
  text?: ReactNode | string;
  isOpen: boolean;
  isPending?: boolean;
  close: () => void;
  onCancel: () => void;
  onConfirm: (e: React.MouseEvent) => void;
  cancelButton: string;
  confirmButton: string;
  confirmColorButton?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
  >;
}

const ConfirmDialog: FC<DialogProps> = ({
  title,
  text,
  isOpen,
  isPending,
  close,
  onCancel,
  onConfirm,
  confirmButton,
  cancelButton,
  confirmColorButton,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => (!isPending ? close() : null)}
      PaperProps={{ sx: { maxWidth: 450, minHeight: 156, width: '90%', px: 1, pb: 1.5 } }}
      sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 8, 51, 0.4)' } }}
      transitionDuration={{
        appear: 0,
        enter: 0,
        exit: 0,
      }}
    >
      <DialogTitle variant="h4" align="center" sx={(t) => ({ py: 3, color: t.palette.text.primary, fontWeight: 500 })}>
        {title}
      </DialogTitle>
      {text && (
        <DialogContent>
          <DialogContentText variant="body2" sx={{ textAlign: 'center' }}>
            {text}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={{ flex: 1, px: 1.5, alignItems: 'end', justifyContent: 'space-between' }}>
        <Button onClick={onCancel} disabled={isPending}>
          {cancelButton}
        </Button>
        <Button
          onClick={onConfirm}
          variant={'contained'}
          autoFocus
          disabled={isPending}
          startIcon={isPending ? <CircularProgress size={16} color="inherit" /> : null}
          color={confirmColorButton ?? 'primary'}
        >
          {confirmButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
