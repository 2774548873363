import type { MouseEvent, ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useQueryParam, NumberParam, withDefault } from 'use-query-params';

export interface UsePaginationResult {
  page: number;
  perPage: number;
  setPerPage: (value: number) => void;
  handlePageChange: (event: MouseEvent<HTMLButtonElement> | null, nextPage: number) => void;
  handleRowsPerPageChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  resetPagination: () => void;
}

// empty array params change their links each time any other param changes
// and causes pagination to reset even if empty array was changed to new empty array
// so decision is to convert keys to primitive (string)
const getResetPaginationKey = (deps: unknown[]) => {
  return deps.map((val) => (Array.isArray(val) && val.length === 0 ? '' : val)).join(',');
};

export function usePagination(resetDependencies: unknown[], defaultPerPage = 25): UsePaginationResult {
  const [isMounted, setIsMounted] = useState(false);
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0));
  const [perPage, setPerPage] = useQueryParam('perPage', withDefault(NumberParam, defaultPerPage));

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, nextPage: number) => {
      setPage(nextPage);
    },
    [setPage]
  );

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPerPage(Number(event.target.value));
      setPage(0);
    },
    [setPerPage, setPage]
  );

  const resetPagination = useCallback(() => {
    setPage(0);
  }, [setPage]);

  useEffect(() => {
    isMounted && resetPagination();
    if (!isMounted) setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResetPaginationKey(resetDependencies)]);

  return {
    page, // : parseInt(page, 10),
    perPage, // : parseInt(perPage, 10),
    setPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    resetPagination,
  };
}
