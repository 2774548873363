import { isEmpty } from 'lodash-es';
import { ParseMetricsFormDirtyParams, ParseMetricsFormStateParams } from '..';
import { UpdateMetricsPayload } from '../api/types';

export default function parseMetricsFormState(
  formState: ParseMetricsFormStateParams,
  dirtyField: ParseMetricsFormDirtyParams
): UpdateMetricsPayload[] {
  const { values: dirtyValues, descriptions: dirtyDescriptions } = dirtyField || {};

  if (!dirtyValues && !dirtyDescriptions) return [];

  const { descriptions, values } = formState;

  const getValue = (value: string | number | null | undefined): number | null =>
    !isEmpty(value) ? parseFloat(`${value}`) : null;

  return Object.entries(values).reduce((acc, [id, value]) => {
    const [metricId, projectId] = id.split(':');
    const description = descriptions[metricId] || '';
    const currentMetricId = parseInt(metricId);
    const project = { id: parseInt(projectId), value: getValue(value) };

    const existingMetricIndex = acc.findIndex((item) => item.id === currentMetricId);
    const existingMetric = acc[existingMetricIndex];

    if (dirtyValues && dirtyValues[id]) {
      if (existingMetric) {
        existingMetric.projects = existingMetric.projects || [];
        existingMetric.projects.push(project);
      } else {
        acc.push({ id: currentMetricId, projects: [project] });
      }
    }

    if (dirtyDescriptions && dirtyDescriptions[currentMetricId]) {
      if (existingMetric) {
        existingMetric.description = description;
      } else {
        acc.push({ id: currentMetricId, description });
      }
    }

    return acc;
  }, [] as UpdateMetricsPayload[]) as UpdateMetricsPayload[];
}
