import { Login } from 'features/login_autotest';
import type { FC } from 'react';
import { Titled } from 'react-titled';

const LoginPage: FC = () => {
  return (
    <>
      <Titled title={(title) => `Autorules | ${title}`} />
      <Login />
    </>
  );
};

export default LoginPage;
