import type { CircularProgressProps, SxProps } from '@mui/material';
import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/material';
import type { FC } from 'react';

interface CircularSpinnerProps {
  spinnerProps?: CircularProgressProps;
  containerSx?: SxProps;
  title?: string;
}
const CircularSpinner: FC<CircularSpinnerProps> = ({ spinnerProps, containerSx, title }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'center',
        alignItems: 'center',
        ...containerSx,
      }}
    >
      <CircularProgress {...spinnerProps} />
      {title && (
        <Typography variant="h3" sx={(t) => ({ color: t.palette.text.primary })}>
          {title}
        </Typography>
      )}
    </Box>
  );
};

export default CircularSpinner;
