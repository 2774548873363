import { usePagination } from 'hooks';
import useListParams from 'hooks/useListParams';
import type { UseMetricsList } from '../types';
import { useMetricsList } from '../api';
import { isEmpty } from 'lodash-es';

const useMetrics = ({ isAllMetrics }: { isAllMetrics?: boolean } = {}) => {
  const { perPage, page, handlePageChange, handleRowsPerPageChange } = usePagination([], 50);
  const { q, project, groupId } = useListParams();

  const {
    data: { tableHead, tableRows, meta, options },
    isSuccess,
  } = useMetricsList<UseMetricsList>({
    params: isAllMetrics
      ? {}
      : {
          name: q,
          project_id: project === 'all_projects' ? undefined : parseInt(project),
          group_id: groupId ?? undefined,
          limit: perPage,
          offset: page * perPage,
        },
    config: {
      select: ({ data, meta }) => {
        const processedData =
          project === 'all_projects'
            ? [...data].map((metric) => ({ ...metric, projects: [...metric.projects].filter((prj) => prj.id !== 0) }))
            : data;
        return {
          tableHead: !isEmpty(processedData) ? processedData[0].projects.map((prj) => prj.name) : [],
          tableRows: processedData,
          options: processedData.map(({ name, id }) => ({ value: id, label: name })),
          meta,
        };
      },
    },
  });

  return { isSuccess, tableHead, tableRows, options, meta, perPage, page, handlePageChange, handleRowsPerPageChange };
};

export default useMetrics;
