/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Box } from '@mui/material';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import { useFormContext } from 'react-hook-form';
import type { UserFormData } from '../types';
import { useUser, useUserRoles } from '../api';
import type { Option } from 'types/shared';
import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { NumberParam, useQueryParam } from 'use-query-params';
import type { Project } from 'features/projects';
import UsersFormSkeleton from './UserFormSkeleton';
import { useProjects } from 'features/projects';
import { PROJECT_DEPENDANT_ROLES } from '../hooks/useMyAccess';

const UserForm = () => {
  const [userId] = useQueryParam('userId', NumberParam);
  const { data: user, isFetching } = useUser({ id: userId!, config: { enabled: !!userId } });

  const { data: roles } = useUserRoles<Option[]>({
    config: {
      select: (data) => data.map(({ id, name }) => ({ value: id, label: name })),
    },
  });

  const { data: projects } = useProjects<Option[]>({
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })),
    },
  });
  const { watch, setValue } = useFormContext<UserFormData>();

  const role = watch('role');

  const showProject = PROJECT_DEPENDANT_ROLES.some((r) => r === role);

  useEffect(() => {
    if (!isEmpty(user)) {
      const { email, role, projects } = user;
      setValue('email', email);
      if (role) {
        setValue('role', role);
      }
      if (!isEmpty(projects) && PROJECT_DEPENDANT_ROLES.some((r) => r === role)) {
        setValue(
          'projects',
          (projects as Project[]).map((p) => p.id)
        );
      }
    }
  }, [setValue, user, roles]);

  if (isFetching) {
    return <UsersFormSkeleton />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px 0' }}>
      <Input name="email" label="User email" size="small" required />
      <Select name="role" size="small" label="Access" required options={roles} />
      {showProject && <Select name="projects" size="small" label="Project" multiple required options={projects} />}
    </Box>
  );
};

export default UserForm;
