import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import GlobalErrorBoundaryFallback from 'components/GlobalErrorBoundaryFallback';
import type { ErrorInfo, FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

const GlobalErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  const errorId = crypto.randomUUID();
  const { reset } = useQueryErrorResetBoundary();

  const logError = (error: Error, info: ErrorInfo) => {
    Sentry.captureException(error, { data: info, event_id: errorId });
  };

  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={(props) => <GlobalErrorBoundaryFallback {...props} errorId={errorId} />}
      onError={logError}
    >
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
