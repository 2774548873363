import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import queryString from 'query-string';
import type { QueryConfig } from 'lib/react-query';
import type { MetricDetails } from '../types';
import queryKeys from './queryKeys';

export const getMetricDetails = async (id: number, signal?: AbortSignal): Promise<MetricDetails> => {
  const res = await apiClient.get<MetricDetails>(`/metrics/${id}`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseMetricDetailsData<TData> {
  config?: QueryConfig<typeof getMetricDetails, TData>;
  id: number;
}

export const useMetricDetails = <TData = MetricDetails>({ id, config }: UseMetricDetailsData<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getMetricDetails(id, signal),
    queryKey: queryKeys.getMetricDetails(id),
  });
};
