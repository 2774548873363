import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { FlowEntry } from './types';
import queryKeys from './queryKeys';
import type { SynchronousQueryConfig } from 'lib/react-query';

interface Params {
  project_id: number;
}
const getFlows = async (params: Params, signal?: AbortSignal): Promise<FlowEntry[]> => {
  const res = await apiClient.get<FlowEntry[]>(`/flows`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseFlows<TData> {
  config?: QueryConfig<typeof getFlows, TData>;
  params: Params;
}

interface UseSyncFlows<TData> {
  params: Params;
  config?: SynchronousQueryConfig<typeof getFlows, TData>;
}

export const useFlows = <TData = FlowEntry[]>({ config, params }: UseFlows<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getFlows(params, signal),
    queryKey: queryKeys.flows(params.project_id),
  });
};

export const useSyncFlows = <TData = FlowEntry[]>({ config, params }: UseSyncFlows<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getFlows(params, signal),
    queryKey: queryKeys.flows(params.project_id),
  });
};
