import type { FC } from 'react';
import type { InputProps } from '@mui/material';
import { Box, Chip, TextField } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useRef, useState } from 'react';

interface RuleEditInputProps extends Pick<InputProps, 'placeholder'> {
  value: string | string[];
  onChange: (value: string) => void;
}

const RuleFilterInput: FC<RuleEditInputProps> = ({ onChange, value, placeholder }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [innerValue, setInnerValue] = useState(value.toString());
  const ref = useRef<HTMLInputElement>(null);

  const handleInnerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInnerValue(event.target.value);
  };

  return (
    <>
      <TextField
        inputRef={ref}
        sx={{
          width: isEditMode || !value ? '100%' : 0,
          '& .MuiInputBase-input': { height: '24px !important', py: 0 },
          '& .MuiOutlinedInput-notchedOutline': { border: !isEditMode ? 'none' : '1px solid red', borderRadius: 0 },
        }}
        onBlur={() => {
          setIsEditMode(false);
          onChange(innerValue);
        }}
        onFocus={() => {
          setIsEditMode(true);
        }}
        variant="outlined"
        value={innerValue}
        onChange={handleInnerChange}
        fullWidth
        placeholder={placeholder}
        multiline={false}
      />
      {!isEditMode && value && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            width: '100%',
            cursor: 'pointer',
            height: '100%',
            pl: '12px',
          }}
          onClick={() => {
            setIsEditMode(true);
            ref.current?.focus();
          }}
        >
          {typeof value === 'string' ? value : value.map((v) => <Chip sx={{ m: '0 !important' }} key={v} label={v} />)}
        </Box>
      )}
    </>
  );
};

export default RuleFilterInput;
