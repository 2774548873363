import * as yup from 'yup';
import { lazy } from 'yup';

const subActionWithValue = yup
  .object()
  .shape({
    action: yup.string().required().default(''),
    value: yup.string().default('').required(),
  })
  .defined();

const subActionWithoutValue = yup
  .object()
  .shape({
    action: yup.string().required().default(''),
  })
  .defined();

const action = yup.object().shape({
  action: yup.string().required(),
  sub_actions: yup.array().when('action', {
    is: (action: string) => action.includes('duplicate'),
    then: () => yup.array().of(subActionWithValue).min(1),
    otherwise: () => yup.array().of(subActionWithoutValue),
  }),
});

export const actionArraySchema = yup.array().of(action);

const DEPENDANT_METRICS = ['constant', 'flow'];

const type = yup.string().oneOf(['condition']).required();
const operator = yup.string().required().default('');
const metric = yup.string().required().label("Can't be empty").default('');

const period = (key: string) =>
  yup
    .string()
    .default('')
    .when(key, {
      is: (metric: string) => DEPENDANT_METRICS.includes(metric),
      then: (schema) => schema.strip(),
      otherwise: (schema) => schema.required(),
    });
const metric_level = (key: string) =>
  yup
    .string()
    .default('')
    .when(key, {
      is: (metric: string) => DEPENDANT_METRICS.includes(metric),
      then: (schema) => schema.strip(),
      otherwise: (schema) => schema.required(),
    });
const constant_name = (key: string) =>
  yup
    .string()
    .default('')
    .when(key, {
      is: (metric: string) => DEPENDANT_METRICS.includes(metric),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.strip(),
    });

const addPrefix = (prefix: string, key: string) => prefix + key;

const createConditionSchemaObj = (conditionType: 'first' | 'second') => {
  const isSingle = conditionType === 'first';
  const prefix = isSingle ? '' : 'second_';

  const critical_value = yup
    .string()
    .default('')
    .when('$critical_value', {
      is: () => isSingle,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.strip(),
    });

  const metricKey = addPrefix(prefix, 'metric');

  return {
    type,
    operator,
    metric,
    critical_value,
    [addPrefix(prefix, 'period')]: period(metricKey),
    [addPrefix(prefix, 'metric_level')]: metric_level(metricKey),
    [addPrefix(prefix, 'constant_name')]: constant_name(metricKey),
  };
};

const singleConditionItemSchema = yup
  .object()
  .shape({
    ...createConditionSchemaObj('first'),
  })
  .noUnknown();
const multipleConditionItemSchema = yup
  .object()
  .shape({
    ...createConditionSchemaObj('first'),
    ...createConditionSchemaObj('second'),
  })
  .noUnknown();

export const conditionsArraySchema = yup.array().of(
  lazy((item: Record<string, string | number>) => {
    const isMultipleCondition = Object.keys(item).some((key) => key.includes('second'));

    return isMultipleCondition ? multipleConditionItemSchema : singleConditionItemSchema;
  })
);

const conditionSchema = yup.object().shape({
  type: yup.string().required().oneOf(['group']),
  operator: yup.string().required().oneOf(['AND', 'OR']),
  conditions: conditionsArraySchema,
});

const taskItemSchema = yup.object().shape({
  condition: conditionSchema,
  actions: actionArraySchema,
});

export const tasksArraySchema = yup.array().of(taskItemSchema);
