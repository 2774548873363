import DatePicker from "components/common/Datepicker";
import useListParams from "hooks/useListParams";

const FilterArchiveDate = () => {
  const { archiveDate, setArchiveDate, tab } = useListParams();
  return tab === "archived" ? (
    <DatePicker label="Archive date" field="archiveDate" actualValue={archiveDate ?? undefined} setActualValue={setArchiveDate} />
  ) : null;
}

export default FilterArchiveDate;
