import * as yup from 'yup';

export const filterParamsSchema = yup.object().shape({
  project: yup.string(),
  q: yup.string(),
  all_metrics: yup.bool(),
  metrics: yup.object().shape({}),
  id_metric_details: yup.number(),
  group_id: yup.string(),
});
