import type { QueryKey } from '@tanstack/react-query';
import type { PaginationParams } from 'lib/api';
import type { GetLogsParams } from '../types';

export default {
  getLogs: (params: Partial<GetLogsParams & PaginationParams>) =>
    [
      'getLogs',
      params.project_id,
      params.item_name,
      params.item_type,
      params.action_type,
      params.user_email,
      params.offset,
      params.limit,
      params.start_date_from,
      params.start_date_to,
    ] as QueryKey,
  getLogsActionTypes: ['getLogsActionTypes'],
  getLogsItemTypes: ['getLogsItemTypes'],
  logDetails: (id: number) => ['details', id],
};
