import {
  Box,
  LinearProgress,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import type { FC } from 'react';

const UsersTableSkeleton: FC = () => {
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <LinearProgress
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            height: 4,
            mb: 2,
            zIndex: 10,
            width: '100%',
          }}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: 200, width: 500 }} style={{ paddingLeft: '4px' }}>
                  <Skeleton width={120} height={32} />
                </TableCell>
                <TableCell sx={{ minWidth: 200, width: 300 }}>
                  <Skeleton width={120} height={32} />
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Skeleton width={100} height={32} />
                </TableCell>
                <TableCell sx={{ minWidth: 100 }}>
                  <Skeleton width={60} height={32} />
                </TableCell>
                <TableCell sx={{ minWidth: 200 }}>
                  <Skeleton width={60} height={32} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(15).keys()].map((i) => (
                <TableRow key={i}>
                  <TableCell sx={{ minWidth: 200, width: 500 }} style={{ paddingLeft: '4px' }}>
                    <Skeleton width={120} height={32} />
                  </TableCell>
                  <TableCell sx={{ minWidth: 200, width: 300 }}>
                    <Skeleton width={120} height={32} />
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>
                    <Skeleton width={100} height={32} />
                  </TableCell>
                  <TableCell sx={{ minWidth: 100 }}>
                    <Skeleton width={60} height={32} />
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>
                    <Skeleton width={60} height={32} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default UsersTableSkeleton;
