import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import type { Log } from '../types';
import { format, parseISO } from 'date-fns';
import { NumberParam, useQueryParam } from 'use-query-params';
import { SIMPLE_LOGS } from '../../../constants';

interface LogsTableRowProps {
  log: Log;
}

const getActionName = (action: string) => {
  const text = action.split('_').join(' ');

  return text.charAt(0).toUpperCase() + text.slice(1);
};

const LogsTableRow: FC<LogsTableRowProps> = ({ log }) => {
  const [_, setLogId] = useQueryParam('logId', NumberParam);

  const isSimpleLog = SIMPLE_LOGS.includes(log.action_type);

  return (
    <TableRow
      onClick={() => {
        if (!isSimpleLog) {
          setLogId(log.id);
        }
      }}
      sx={{
        '& td': {
          ...(!isSimpleLog && { cursor: 'pointer' }),
          p: 1,
        },
        ...(!isSimpleLog && {
          '&:hover': {
            background: 'rgba(41, 121, 255, 0.08)',
          },
        }),
      }}
    >
      <TableCell sx={{ minWidth: '100px', maxWidth: '200px !important' }}>
        <Typography variant="body2">{getActionName(log.action_type)}</Typography>
      </TableCell>
      <TableCell sx={{ minWidth: '200px', maxWidth: '400px !important' }}>
        <Tooltip
          arrow
          title={<Typography sx={{ fontSize: 10, fontWeight: 500 }}>{log.item_name}</Typography>}
          sx={{ cursor: 'default' }}
        >
          <Typography
            variant="body2"
            sx={{
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {log.item_name}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ minWidth: '100px', maxWidth: '200px !important' }}>
        <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
          {format(parseISO(log.finished_at), 'dd.MM.yy HH:mm')}
        </Typography>
      </TableCell>
      <TableCell sx={{ minWidth: '100px', maxWidth: '200px !important' }}>
        <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
          {log.user_email}
        </Typography>
      </TableCell>
      <TableCell sx={{ minWidth: '100px', maxWidth: '200px !important' }} align="right">
        {/*<Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>*/}
        {/*  Undo*/}
        {/*</Typography>*/}
      </TableCell>
    </TableRow>
  );
};

export default LogsTableRow;
