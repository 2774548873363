/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useAuthenticator } from '@aws-amplify/ui-react';
import hotjarIdentify from './hotjarIdentify';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';

const HotjarInitial: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (user) {
      const email = user.getSignInUserSession()?.getIdToken().payload.email as string;
      const fullName = email
        .split('@')[0]
        .split('.')
        .map((word: string) => [word[0].toUpperCase(), word.slice(1)].join(''))
        .join(' ');

      if (!isEmpty(fullName) && !isEmpty(email)) {
        hotjarIdentify(fullName, email);
      }
    }
  }, [user]);

  return children;
};

export default HotjarInitial;
