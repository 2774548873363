import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

const useChosenMetrics = () => {
  const { watch, reset, setValue } = useFormContext();
  const chosenMetrics = watch('metrics');
  const isChosenMetrics = Object.values(chosenMetrics).includes(true);

  const onResetMetrics = useCallback(() => {
    reset({
      all_metrics: false,
      metrics: {},
    });
  }, [reset]);

  const onSetMetrics = useCallback(
    (value: Record<string, boolean>) => {
      setValue('metrics', { ...value });
    },
    [setValue]
  );

  const onSetIsAllMetrics = useCallback(
    (value: boolean) => {
      setValue('all_metrics', value);
    },
    [setValue]
  );

  return {
    isChosenMetrics,
    chosenMetrics,
    onResetMetrics,
    onSetMetrics,
    onSetIsAllMetrics,
  };
};

export default useChosenMetrics;
