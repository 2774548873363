import * as yup from 'yup';
import { PROJECT_DEPENDANT_ROLES } from '../hooks/useMyAccess';

const emailRegex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

export const userSchema = yup.object().shape({
  email: yup.string().required().matches(emailRegex, 'Email is not valid').label('Email'),
  role: yup.string().required().label('Access level'),
  projects: yup
    .array()
    .of(yup.number().required())
    .required()
    .when('role', {
      is: (role: string) => {
        return !PROJECT_DEPENDANT_ROLES.some((r) => r === role);
      },
      then: (schema) => schema.notRequired().transform(() => []),
      otherwise: (schema) =>
        schema.test({
          message: 'Select at least 1 project',
          test: (arr) => arr.length >= 1,
        }),
    })
    .label('Project'),
});
