import type { TestContext } from 'yup';

export function intervalMinutesValidation(this: TestContext, value: (number | undefined)[]) {
  try {
    if (!value.length) return true;

    const condition = (minute: number) => minute >= 0 && minute <= 59 && !isNaN(minute) && typeof minute === 'number';
    // @ts-expect-error types correction
    const isValid = value.every(condition);
    if (!isValid) {
      throw new Error('Minutes should be in range from 0 to 59');
    }

    return true;
  } catch (e) {
    // @ts-expect-error error type correction
    return this.createError({ message: String(e.message) });
  }
}
