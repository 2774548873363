import { Box, Button, Paper, Typography } from '@mui/material';
import { useEffect, type FC, useState, useMemo } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { Layout } from './layout';
import { Titled } from 'react-titled';
import { useLocation, useNavigate } from 'react-router';
import Accordion from './common/Accordion';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';

interface GlobalErrorBoundaryFallbackProps extends FallbackProps {
  errorId: string;
}

const GlobalErrorBoundaryFallback: FC<GlobalErrorBoundaryFallbackProps> = ({ resetErrorBoundary, error, errorId }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [locationKey, setLocationKey] = useState('');
  const location = useLocation();
  const navigation = useNavigate();

  const handleReset = () => {
    resetErrorBoundary();
  };

  useEffect(() => {
    if (isMounted && !locationKey.length) {
      setLocationKey(location.key);
      return;
    }

    if (isMounted && locationKey !== location.key) {
      // App routers are not available here,
      // so we need to follow location changes manually,
      // and apply a synchronous page reload when using some navigation...
      window.location.href = location.pathname;
      return;
    }

    if (!isMounted) {
      setIsMounted(true);
      return;
    }

    return () => {
      setIsMounted(false);
      setLocationKey('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, locationKey, setIsMounted, setLocationKey, navigation]);

  const errorDetails = useMemo(() => {
    return [
      String(error),
      `URL: ${window.location.href}`,
      `error id: ${errorId}`,
      `Datetime: ${new Date().toISOString()}`,
    ];
  }, [error, errorId]);

  const handleCopyDetails = () => {
    copy(errorDetails.join('\n'));
  };

  return (
    <Titled title="Rules">
      <Layout>
        <Box sx={{ display: 'flex', p: 4, justifyContent: 'center' }}>
          <Paper sx={{ p: 4, display: 'block', width: '50%' }} variant="outlined">
            <Typography variant="h2" component="h1">
              An unexpected error happened.{' '}
              <Typography sx={{ display: 'inline' }} fontSize={34}>
                &#128532;
              </Typography>
            </Typography>
            <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
              I hope this won&apos;t happen to you again.{' '}
              <Typography sx={{ display: 'inline' }} fontSize={20}>
                &#128556;
              </Typography>{' '}
              <br />
              <Typography sx={{ display: 'inline', fontWeight: 500 }} fontSize={16}>
                If you would like to contact dev team, kindly ask you to copy details and pass them to the technical
                specialist.{' '}
                <Typography variant="body1" fontSize={20} sx={{ display: 'inline' }}>
                  &#129303;
                </Typography>
              </Typography>
            </Typography>
            <Accordion
              details={
                <Box>
                  {errorDetails.map((ed, index) => (
                    <pre style={{ textWrap: 'wrap' }} key={index}>
                      {ed}
                    </pre>
                  ))}
                </Box>
              }
              summary={<Typography variant="body1">Expand the details</Typography>}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
              <Button variant="contained" size="large" onClick={handleReset} sx={{ mr: 4 }}>
                Reload page
              </Button>
              <Button variant="contained" size="large" onClick={handleCopyDetails}>
                <ContentCopyIcon sx={{ mr: 2 }} />
                Copy details
              </Button>
            </Box>
          </Paper>
        </Box>
      </Layout>
    </Titled>
  );
};

export default GlobalErrorBoundaryFallback;
