import * as yup from 'yup';
import { MULTIPLE_FILTER_OPERATORS } from '../constants';

const filterItemSchema = yup.object().shape({
  field: yup.string().required().min(1).label("Can't be empty"),
  operator: yup.string().required().min(1).label("Can't be empty"),
  value: yup
    .string()
    .label("Can't be empty")
    .when('operator', {
      is: (operator: string) => MULTIPLE_FILTER_OPERATORS.includes(operator),
      then: () => yup.array(yup.string()).min(1),
      otherwise: () => yup.string().required().min(1),
    }),
});

const filtersArraySchema = yup.array().of(filterItemSchema).min(1);

const filtersGroupArrayValidationSchema = yup.array().of(filtersArraySchema).min(1);

export default filtersGroupArrayValidationSchema;
