import { apiClient } from '../../../lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { CreateMetricParams } from './types';
import { isAxiosError } from 'axios';
interface CreateMetricPromise {
  id: number;
}
interface CreateMetricErrorPromise {
  message: string;
}

type QueryPromise = CreateMetricPromise | CreateMetricErrorPromise;

export const createMetric = async (body: CreateMetricParams): Promise<QueryPromise> => {
  const res = await apiClient.post<QueryPromise>(`/metrics`, body);
  if (isAxiosError(res)) {
    const error = new Error('axios error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseCreateMetricOptions {
  config?: MutationConfig<typeof createMetric>;
}

export const useCreateMetric = ({ config }: UseCreateMetricOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['groups'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['metrics'],
        }),
      ]);

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: createMetric,
  });
};
