import { Controller, useFormContext } from 'react-hook-form';
import type { RadioGroupProps as MuiRadioGroupProps, SxProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import {
  FormControlLabel,
  FormControl,
  RadioGroup as MuiRadioGroup,
  FormLabel,
  Radio,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import type { Option, OptionValue } from 'types/shared';

export interface RadioOption extends Omit<Option, 'value'> {
  value: OptionValue | boolean;
}

interface RadioGroupProps extends Omit<MuiRadioGroupProps, 'name'> {
  name: string;
  label?: string;
  options: RadioOption[];
  labelProps?: {
    sx?: SxProps<Theme> | undefined;
  };
  controlProps?: {
    sx?: SxProps<Theme> | undefined;
  };
}
const RadioGroup: FC<RadioGroupProps> = ({ name, label, options, labelProps, controlProps, ...restProps }) => {
  const { control, formState } = useFormContext();
  const error = formState.errors[name];

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <FormControl {...controlProps}>
            {label && (
              <FormLabel id="demo-controlled-radio-buttons-group" {...labelProps}>
                {label}
              </FormLabel>
            )}
            <MuiRadioGroup {...field} value={String(field.value)} {...restProps}>
              {options.map(({ value, label }) => (
                <FormControlLabel key={String(value)} value={value} control={<Radio />} label={label} />
              ))}
            </MuiRadioGroup>
            {error?.message && (
              <Typography variant="subtitle1" fontSize={12} sx={{ mt: '3px' }} color="error">
                {error.message as string}
              </Typography>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default RadioGroup;
