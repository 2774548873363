import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import type { QueryConfig } from 'lib/react-query';
import { apiClient } from 'lib/api';
import { isAxiosError } from 'axios';
import type { NotificationChannel } from '../types';

interface NotificationChannelsResponse {
  data: NotificationChannel[];
}

export const getNotificationChannels = async (signal?: AbortSignal): Promise<NotificationChannel[]> => {
  const res = await apiClient.get<NotificationChannelsResponse>(`/notification_channels`, {
    signal,
  });
  if (isAxiosError(res)) {
    const error = new Error('Notification level');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data.data;
};

interface UseNotificationLevelOptions<TData> {
  config?: QueryConfig<typeof getNotificationChannels, TData>;
}

export const useNotificationChannels = <TData = NotificationChannel[]>({
  config,
}: UseNotificationLevelOptions<TData> = {}) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getNotificationChannels(signal),
    queryKey: ['notification_channels'] as QueryKey,
  });
};
