import type { ChangeEvent, FC, ReactElement, ReactNode } from 'react';
import { useMemo, useCallback, useState, forwardRef } from 'react';
import type { TextFieldProps, AutocompleteProps, SxProps, Theme } from '@mui/material';
import {
  Autocomplete as MuiAutocomplete,
  Checkbox,
  createFilterOptions,
  TextField,
  FormControl,
  Typography,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import type {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete/Autocomplete';
import type { AutocompleteChangeReason, AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete';
import type { Option, OptionValue } from 'types/shared';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash-es';

export interface ControlAutocompleteProps
  extends Pick<AutocompleteProps<OptionValue, true, boolean, false>, 'disableClearable'> {
  value?: OptionValue[];
  onChange: (value: OptionValue[]) => void;
  InputProps?: TextFieldProps;
  id: string;
  isLoading?: boolean;
  options: Option[];
  allSelectable?: boolean;
  label?: string;
  name: string;
  required?: boolean;
  tooltipTitle?: string;
  additionalLabel?: ReactElement;
  renderTags?: (tagValue: Option[], getTagProps: AutocompleteRenderGetTagProps) => ReactNode | undefined;
  sx?: SxProps<Theme>;
  innerLabel?: boolean;
  error?: string;
}

const ControlAutocomplete: FC<ControlAutocompleteProps> = forwardRef(
  (
    {
      id,
      options,
      isLoading,
      onChange,
      value,
      InputProps,
      allSelectable = true,
      tooltipTitle,
      sx,
      innerLabel,
      ...props
    },
    ref // Controller is passing down a ref to the ControlAutocomplete component. Since functional components don't accept refs by default.
  ) => {
    const [inputValue, setInputValue] = useState('');
    const { setValue } = useFormContext();
    const isIndeterminate = useWatch({ name: 'indeterminate' });

    const derivedValue = useMemo(() => options.filter((o) => value?.includes(o.value)), [value, options]);

    const allSelected = options.length === derivedValue.length;

    const handleSelectAll = () => {
      if (allSelected) {
        onChange([]);
      } else {
        onChange(options.filter((o) => o.value !== 'select-all').map((o) => o.value));
      }
    };

    const filter = createFilterOptions<Option>();

    const optionRenderer = (onChange?: (option: Option) => void, isIndeterminate?: boolean) => {
      return (
        renderProps: React.HTMLAttributes<HTMLLIElement>,
        option: Option,
        { selected }: AutocompleteRenderOptionState
      ) => {
        const selectAllProps = option.value === 'select-all' ? { checked: allSelected } : {};
        return (
          // Override key because mui puts to the key value from getOptionLabel i.e label which is not unique
          <li {...renderProps} key={`${props.name}:${option.label}.${option.value}`}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
              checked={selected}
              indeterminate={option.indeterminate}
              onChange={!isEmpty(isIndeterminate) ? () => onChange?.(option) : undefined}
              {...selectAllProps}
            />
            {option.label}
          </li>
        );
      };
    };

    const handleChange = (
      _: React.SyntheticEvent,
      newValue: AutocompleteValue<Option, true, false, false>,
      reason: AutocompleteChangeReason
    ) => {
      if (reason === 'selectOption' || reason === 'removeOption') {
        if (newValue.find((option) => option.value === 'select-all')) {
          handleSelectAll();
        } else {
          onChange(newValue.map((o) => o.value));
        }
      } else if (reason === 'clear') {
        onChange([]);
        setValue('indeterminate', {});
      }
    };

    const onChangeCheckbox = useCallback(
      (option: Option) => {
        setValue(`indeterminate.${option.value}`, false);
      },
      [setValue]
    );

    const handleInputValue = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
      },
      [setInputValue]
    );

    const handleClose = useCallback(() => {
      setInputValue('');
    }, [setInputValue]);

    const disabled = !!isLoading || !!InputProps?.disabled;

    return (
      <Tooltip title={disabled && tooltipTitle ? tooltipTitle : ''} placement="top" arrow>
        <MuiAutocomplete
          {...props}
          ref={ref}
          size="small"
          multiple
          disabled={disabled}
          loading={isLoading}
          id={id}
          options={options}
          disableCloseOnSelect
          value={derivedValue}
          getOptionLabel={(option) => option.label ?? ''}
          renderOption={optionRenderer(onChangeCheckbox, isIndeterminate)}
          onChange={handleChange}
          onClose={handleClose}
          style={{ width: '100%', maxHeight: 'max-content' }}
          sx={{ '.MuiAutocomplete-inputRoot': { maxHeight: 'max-content !important' }, ...(sx ?? null) }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={InputProps?.label}
              placeholder={InputProps?.placeholder}
              onChange={handleInputValue}
            />
          )}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (allSelectable) {
              return [{ label: 'Select all', value: 'select-all' }, ...filtered];
            }

            return filtered;
          }}
          inputValue={inputValue}
        />
      </Tooltip>
    );
  }
);

const Autocomplete: FC<
  Pick<
    ControlAutocompleteProps,
    | 'id'
    | 'options'
    | 'label'
    | 'allSelectable'
    | 'name'
    | 'required'
    | 'InputProps'
    | 'isLoading'
    | 'tooltipTitle'
    | 'renderTags'
    | 'sx'
    | 'innerLabel'
    | 'error'
  > &
    Partial<Pick<ControlAutocompleteProps, 'onChange'>>
> = ({
  id,
  options,
  label,
  allSelectable,
  name,
  required,
  InputProps,
  tooltipTitle,
  isLoading,
  renderTags,
  sx,
  innerLabel,
  error,
  onChange,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const innerError = errors[name];

  const anyError = error ?? innerError?.message?.toString();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { onChange: fieldChange } = field;
        return (
          <FormControl sx={{ width: '100%' }}>
            {label && !innerLabel && (
              <Typography
                variant="subtitle2"
                sx={(t) => ({ color: t.palette.text.primary, mb: 1, display: 'flex', alignItems: 'center' })}
              >
                {label}
                {required && '*'}
                {isLoading && <CircularProgress sx={{ ml: 1 }} size={16} color="inherit" />}
              </Typography>
            )}
            <ControlAutocomplete
              allSelectable={allSelectable}
              id={id}
              options={options}
              InputProps={InputProps}
              isLoading={isLoading}
              tooltipTitle={tooltipTitle}
              renderTags={renderTags}
              sx={sx}
              innerLabel={innerLabel}
              {...field}
              onChange={(value: OptionValue[]) => {
                fieldChange(value);
                onChange ? onChange(value) : null;
              }}
            />
            {anyError && (
              <Typography sx={{ mt: 0.5, pl: 0.5 }} variant="caption" color="red">
                {anyError}
              </Typography>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default Autocomplete;
