import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useModal } from 'hooks';
import { FC, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useUpdateMetrics } from '../api/updateMetrics';
import parseMetricsFormState from '../utils/parseMetricsFormState';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useMyAccess } from 'features/users';
import { ParseMetricsFormDirtyParams } from '..';
import { delay, has, isEmpty } from 'lodash-es';
import useProjectConditions from '../hooks/useProjectConditions';
import useRouteBlocker from 'hooks/useRouteBlocker';
import useListParams from 'hooks/useListParams';
import useAccessModify from '../hooks/useAccessModify';

export const EditButton = () => {
  const { isNotAssigned } = useProjectConditions();
  const navigate = useNavigate();
  const location = useLocation();
  const { accessMetrics } = useAccessModify();

  return accessMetrics.accessModify && !isNotAssigned ? (
    <Box
      sx={(t) => ({
        zIndex: 6,
        height: 56,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        boxShadow: t.shadows[10],
        p: 1,
        borderRadius: '100px',
        ml: 2,
      })}
    >
      <Button
        onClick={() => navigate(`edit${location.search}`, { replace: true })}
        variant="contained"
        color="primary"
        endIcon={<ModeEditIcon />}
        sx={{ borderRadius: '100px' }}
      >
        Edit
      </Button>
    </Box>
  ) : null;
};

const SaveButtons: FC<{ onUnblockRoute: () => void }> = ({ onUnblockRoute }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();
  const {
    reset,
    getValues,
    formState: { dirtyFields },
  } = useFormContext();
  const mutateUpdateMetrics = useUpdateMetrics({
    config: {
      onSuccess: () => {
        reset({ keepDirty: false });
        onShowAlert('Metrics updated');
        onUnblockRoute();
        navigate(`/metrics${location.search}`, { replace: true });
      },
    },
  });

  const handleSave = useCallback(() => {
    const formState = getValues('metrics_edit');
    const payload = parseMetricsFormState(formState, dirtyFields['metrics_edit'] as ParseMetricsFormDirtyParams);
    if (isEmpty(payload)) {
      onShowInfoAlert('No changes');
      navigate(`/metrics${location.search}`, { replace: true });
      return;
    }

    mutateUpdateMetrics.mutate(payload);
  }, [dirtyFields, mutateUpdateMetrics, parseMetricsFormState, getValues]);
  return (
    <Button
      startIcon={mutateUpdateMetrics.isPending ? <CircularProgress size={16} color="inherit" /> : null}
      disabled={mutateUpdateMetrics.isPending}
      variant="contained"
      color="primary"
      onClick={handleSave}
      sx={{ borderRadius: '100px' }}
    >
      Save
    </Button>
  );
};

const MetricsModifyButtons = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, open, close } = useModal();
  const { isNotAssigned } = useProjectConditions();
  const { setNewMetric } = useListParams();
  const {
    resetField,
    formState: { isDirty },
  } = useFormContext();

  const { onBlockRoute, onUnblockRoute, routeBlocker } = useRouteBlocker({ callback: open });
  useEffect(() => {
    isDirty ? onBlockRoute() : onUnblockRoute(); // Block navigation if form is dirty
  }, [isDirty]);

  const handleDiscard = useCallback(() => {
    resetField('metrics_edit');
    close();
    if (routeBlocker.state === 'blocked' && routeBlocker.proceed) {
      routeBlocker.proceed();
    }
    onUnblockRoute();
    !routeBlocker.proceed && navigate(`/metrics${location.search}`, { replace: true });
  }, [resetField, close, setNewMetric, onUnblockRoute]);

  const handleKeepEditing = useCallback(() => {
    setNewMetric(false);
    close();
  }, [close]);

  const handleOpen = useCallback(() => {
    if (isDirty) {
      open();
      return;
    }
    resetField('metrics_edit');
    navigate(`/metrics${location.search}`, { replace: true });
  }, [isDirty, resetField, navigate, open]);

  return !isNotAssigned ? (
    <Box
      sx={(t) => ({
        height: 56,
        display: 'flex',
        gap: 1,
        flexDirection: 'row',
        backgroundColor: 'white',
        boxShadow: t.shadows[8],
        p: 1,
        ml: 2,
        borderRadius: '100px',
      })}
    >
      <Button
        sx={(t) => ({
          border: 'none',
          borderRadius: `${t.shape.borderRadius * 2}px`,
          '&:hover': { border: 'none' },
          px: 0.66,
        })}
        size="medium"
        color="error"
        variant="outlined"
        onClick={handleOpen}
      >
        Discards
      </Button>
      <SaveButtons onUnblockRoute={onUnblockRoute} />
      <Dialog
        open={isOpen}
        onClose={close}
        PaperProps={{ sx: { maxWidth: 440, minHeight: 156, width: '90%', py: 1 } }}
        transitionDuration={{
          appear: 0,
          enter: 0,
          exit: 0,
        }}
      >
        <DialogTitle variant="h4" align="center" sx={(t) => ({ color: t.palette.text.primary, fontWeight: 500 })}>
          Discard changes?
        </DialogTitle>
        <DialogActions sx={{ flex: 1, alignItems: 'end', justifyContent: 'space-between' }}>
          <Button onClick={handleKeepEditing}>Cancel</Button>
          <Button onClick={handleDiscard} variant={'contained'} autoFocus>
            Discards
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  ) : null;
};

export default MetricsModifyButtons;
