import type { ChangeEvent, SyntheticEvent } from 'react';
import type React from 'react';
import { useState } from 'react';
import { TextField, Chip, Box, Typography, FormControl } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

interface ChipInputProps {
  label?: string;
  inputLabel?: string;
  name: string;
  required?: boolean;
}

const ChipInput: React.FC<ChipInputProps> = ({ label, inputLabel, name, required }) => {
  const { control, setValue, formState, trigger } = useFormContext();
  const formError = formState.errors[name];
  const formValue = useWatch({ name });
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const value = (event.target as HTMLInputElement).value;
      if (value.length) {
        setValue(name, [...(formValue ?? []), value], { shouldDirty: true });
        setInputValue('');
        trigger(name);
      }
    } else if (event.key === 'Backspace' && !inputValue.length) {
      const newValues = [...(formValue ?? [])];
      newValues.pop();
      setValue(name, newValues.length ? newValues : undefined, { shouldDirty: true });
      trigger(name);
    }
  };

  const handleOnBlur = (event: SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value;
    if (value.length) {
      setValue(name, [...(formValue ?? []), value], { shouldDirty: true });
      setInputValue('');
      trigger(name);
    }
  };

  const handleDelete = (index: number) => {
    const newValues = [...formValue];
    newValues.splice(index, 1);
    setValue(name, newValues.length ? newValues : [], { shouldDirty: true });
    trigger(name);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            {label && !inputLabel && (
              <Typography variant="subtitle2" mb={1}>
                {label}
                {required && '*'}
              </Typography>
            )}
            <FormControl sx={{ flex: 1 }}>
              <TextField
                {...field}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(e);
                }}
                onBlur={handleOnBlur}
                value={inputValue}
                onKeyDown={handleKeyPress}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    maxHeight: 'fit-content',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'start',
                    gap: 1,
                    py: 1,
                    px: 1.25,
                  },
                  '& .MuiOutlinedInput-root input': {
                    display: 'inline-flex',
                    width: 'auto',
                    minWidth: 120,
                    py: 0,
                    height: 24,
                    borderRadius: 0,
                  },
                }}
                InputLabelProps={{
                  sx: {
                    '&:not(.Mui-focused):not(.MuiInputLabel-shrink)': { transform: 'translate(14px, 8px) scale(1)' },
                  },
                }}
                InputProps={{
                  startAdornment: (formValue as string[])?.map((value, index) => (
                    <Chip
                      key={`${name}_chip_${index}`}
                      label={value}
                      onDelete={() => {
                        handleDelete(index);
                      }}
                      sx={{ '&': { m: 0, display: 'inline-flex' } }}
                    />
                  )),
                }}
                label={inputLabel}
                error={!!formError}
              />
              {formError?.message && (
                <Typography variant="subtitle1" fontSize={12} sx={{ mt: '3px' }} color="error">
                  {formError.message as string}
                </Typography>
              )}
            </FormControl>
          </Box>
        );
      }}
    />
  );
};

export default ChipInput;
