import { useSuspenseQuery } from '@tanstack/react-query';
import type { ListResponse } from 'lib/api';
import { apiClient } from 'lib/api';
import queryString from 'query-string';
import type { RulesListParams, Rule } from '../types';
import queryKeys from './queryKeys';
import type { QueryConfig } from 'lib/react-query';

export const getRulesList = async (
  { params }: { params: RulesListParams },
  signal?: AbortSignal
): Promise<ListResponse<Rule>> => {
  const res = await apiClient.get<ListResponse<Rule>>(`/rules`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  return res.data;
};

interface UseRulesList<TData> {
  config?: QueryConfig<typeof getRulesList, TData>;
  params: RulesListParams;
}

export const useRulesList = <TData = ListResponse<Rule>>({ params, config }: UseRulesList<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getRulesList({ params }, signal),
    queryKey: queryKeys.byFilterValues('rules', params),
  });
};
