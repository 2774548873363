import { Controller, useFormContext } from 'react-hook-form';
import type { SxProps, Theme } from '@mui/material';
import { FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';

interface SwitchProps {
  name: string;
  label?: string;
  sx?: SxProps<Theme>;
  size?: 'small' | 'medium';
  disabled?: boolean;
  disabledTitle?: string;
  handleChange?: () => void;
}

const SwitchInput: FC<SwitchProps> = ({
  label,
  name,
  sx,
  size,
  disabled,
  disabledTitle,
  handleChange,
  ...restProps
}) => {
  const { control } = useFormContext();

  const formElement = (
    <FormControlLabel
      control={
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => (
            <Switch
              sx={{ mr: 1, opacity: disabled ? 0.5 : 1 }}
              disabled={disabled}
              checked={value as boolean}
              onChange={(event, checked) => {
                onChange(event, checked);
                handleChange && handleChange();
              }}
              size={size}
            />
          )}
        />
      }
      label={label ? <Typography sx={(t) => ({ fontSize: t.typography.body2.fontSize })}>{label}</Typography> : null}
      sx={{ mx: 0, mb: 1, py: 1 / 2, ...sx }}
      {...restProps}
    />
  );

  if (disabledTitle) {
    return (
      <Tooltip title={disabledTitle} placement={'top'}>
        {formElement}
      </Tooltip>
    );
  }

  return formElement;
};

export default SwitchInput;
