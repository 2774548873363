import { type FC, type ReactNode, useMemo, useState, useEffect } from 'react';
import { EmergencyContext } from './EmergencyContext';
import { useCheckEmergency } from '../api';
import { useMyAccess } from 'features/users';

interface AuthContextProviderProps {
  children: ReactNode;
}

const EmergencyProvider: FC<AuthContextProviderProps> = ({ children }: AuthContextProviderProps) => {
  const [isEmergency, setEmergency] = useState(false);
  const myAccess = useMyAccess();
  const { data: emergency } = useCheckEmergency();

  useEffect(() => {
    setEmergency(emergency?.status === 'enabled');
  }, [emergency]);

  const contextValue = useMemo(
    () => ({
      isEmergencyModeAllow: myAccess?.general.includes('emergency-mode'),
      isEmergency,
      setEmergency,
    }),
    [myAccess, isEmergency, setEmergency]
  );

  return <EmergencyContext.Provider value={contextValue}>{children}</EmergencyContext.Provider>;
};

export default EmergencyProvider;
