import { Box, Button, CircularProgress } from '@mui/material';
import type { Actions } from '../../users/hooks/useMyAccess';
import useMyAccess from '../../users/hooks/useMyAccess';
import type { FC } from 'react';
import type { Project } from '../api/types';
import { observer } from 'mobx-react-lite';

interface RuleUIFormActionBlockProps {
  onDiscard: () => void;
  onSave: () => void;
  isPending: boolean;
  project: Project;
  hasChanges: boolean;
  mode: Extract<Actions, 'update' | 'create' | 'create-to-some'>[];
  discardTitle?: string;
  submitTitle?: string;
}
const RuleUIFormActionBlock: FC<RuleUIFormActionBlockProps> = observer(
  ({ onDiscard, onSave, isPending, project, mode, hasChanges, discardTitle, submitTitle }) => {
    const access = useMyAccess(project);

    // @ts-expect-error because not all Actions can be in mode
    if (!access || !access.rules.some((a) => mode.includes(a))) {
      return null;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          borderRadius: 5,
          position: 'fixed',
          right: 32,
          bottom: 40,
          zIndex: 10,
          backgroundColor: 'white',
          p: 1,
          width: 246,
          height: 56,
          boxShadow:
            '0px 3px 5px -1px rgba(0, 0, 0, 0.10), 0px 6px 10px 0px rgba(0, 0, 0, 0.07), 0px 1px 18px 0px rgba(0, 0, 0, 0.06)',
        }}
      >
        {isPending ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', height: 36, alignItems: 'center' }}>
            <CircularProgress size={28} sx={(t) => ({ color: t.palette.primary.dark })} />
          </Box>
        ) : (
          <>
            <Button
              sx={(t) => ({
                width: '35%',
                border: 'none',
                borderRadius: `${t.shape.borderRadius * 2.5}px`,
                '&:hover': { border: 'none' },
                px: 1,
              })}
              size="medium"
              color="error"
              variant="outlined"
              disabled={!hasChanges}
              onClick={onDiscard}
            >
              {discardTitle ?? 'Discard'}
            </Button>
            <Button
              sx={(t) => ({
                flex: 1,
                border: 'none',
                borderRadius: `${t.shape.borderRadius * 2.5}px`,
                '&:hover': { border: 'none' },
                px: 0.66,
              })}
              size="medium"
              variant="contained"
              disabled={!hasChanges}
              onClick={onSave}
            >
              {submitTitle ?? 'Save changes'}
            </Button>
          </>
        )}
      </Box>
    );
  }
);

export default RuleUIFormActionBlock;
