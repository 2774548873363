import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import type { QueryConfig } from 'lib/react-query';
import type { Project } from './types';
import { StatusStateType, Statuses, StatusesParam, TabsParam } from 'types/shared';
import { apiClient } from 'lib/api';

interface StatusEntry {
  id: StatusesParam;
  name: Statuses;
  type: StatusStateType;
}

interface GetStatusesParams {
  tab: TabsParam
}

export const getStatuses = async (params: GetStatusesParams, signal?: AbortSignal): Promise<StatusEntry[]> => {
  const res = await apiClient.get<StatusEntry[]>(`/statuses`, {
    signal,
  });

  if (params.tab === 'paused' || params.tab === 'archived')
    return [];

  if (params.tab === 'active')
    return res.data.filter(i => ["completed", "running", "failed"].includes(i.id));

  return res.data;

};

interface UseStatusesListOptions<TData> {
  config?: QueryConfig<typeof getStatuses, TData>;
  params: GetStatusesParams
}

export const useStatuses = <TData = Project[]>({ config, params }: UseStatusesListOptions<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getStatuses({ tab: params.tab }, signal),
    queryKey: ['statuses', params.tab] as QueryKey,
  });
};
