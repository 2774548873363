import { Box, Typography } from '@mui/material';
import useListParams from 'hooks/useListParams';
import { useEffect } from 'react';
import { useRuleGroups } from '../api';
import Autocomplete from 'components/common/Autocomplete';
import { useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash-es';

const FilterRuleGroups = () => {
  const groupsField = useWatch({ name: 'groups' });
  const { setGroups } = useListParams();

  const { data: ruleGroups } = useRuleGroups({
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })),
    },
  });

  useEffect(() => {
    setGroups(groupsField ?? undefined);
  }, [groupsField]);

  return (
    <Box sx={{ display: 'flex', minWidth: 180, flex: 1 }}>
      <Autocomplete
        name="groups"
        InputProps={{ disabled: !ruleGroups, placeholder: isEmpty(groupsField) ? 'Rule group' : '' }}
        options={ruleGroups ?? []}
        allSelectable={false}
        id="groups"
        tooltipTitle="Please select rule group"
        renderTags={(tagValue) => (
          <Box sx={{ overflow: 'hidden', flex: 1 }}>
            <Typography
              variant="body2"
              sx={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {tagValue.map((t) => t.label).join(', ')}
            </Typography>
          </Box>
        )}
        sx={{
          '&.MuiInputBase-root': { position: 'relative' },
          '.MuiAutocomplete-inputRoot input.MuiInputBase-input': {
            position: 'absolute',
            zIndex: 2,
            left: 4,
            right: 0,
            width: 'calc(100% - 74px)',
            borderRadius: 0,
          },
          '&.Mui-focused input.MuiInputBase-input': {
            backgroundColor: 'white',
            width: 'calc(100% - 24px)',
          },
        }}
      />
    </Box>
  );
};

export default FilterRuleGroups;
