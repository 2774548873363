import { Box, Button, CircularProgress, IconButton, ModalProps, Skeleton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FC, Suspense, useCallback, useState } from 'react';
import { UseModalResult, useModal } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { flowsModify } from '../validation/flowsModify.schema';
import Input from 'components/common/Input';
import { Close } from '@mui/icons-material';
import { isUndefined } from 'lodash-es';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useCreateFlow } from '../api/createFlow';
import { useEditFlow } from '../api/editFlow';
import { useDeleteFlow } from '../api/deleteFlow';
import ConfirmDialog from 'components/common/ConfirmDialog';
import useListParams from 'hooks/useListParams';
import { FlowsDrawer } from './FlowsComponents';

interface FlowCommonProps {
  id: number;
  name: string;
}
type FlowsModifyFormFields = Pick<FlowCommonProps, 'name'>;
interface DeleteFlowItemProps extends FlowCommonProps, Pick<UseModalResult, 'open' | 'close' | 'isOpen'> {}
interface FlowModifyProps extends Partial<FlowCommonProps>, Pick<UseModalResult, 'close' | 'isOpen'> {
  modalProps?: Partial<ModalProps>;
  children?: React.ReactNode;
}
interface FlowsFormModifyProps extends Partial<FlowCommonProps>, Pick<UseModalResult, 'close'> {}

export const FlowsFormModify: FC<FlowsFormModifyProps> = ({ id, name, close }) => {
  const { onShowAlert } = useEnqueueSnackbar();
  const isCreateFlow = isUndefined(id);
  const { project, setPlatform, setNewFlow, setListId } = useListParams();

  const formMethods = useForm<FlowsModifyFormFields>({
    resolver: yupResolver(flowsModify),
    defaultValues: {
      name: name ?? '',
    },
  });

  const { reset, setError } = formMethods;

  const createFlow = useCreateFlow({
    config: {
      onSuccess: (data) => {
        onShowAlert('New flow created');
        reset();
        close();

        if (data) {
          setListId(data.id);
          setPlatform(1);
          setNewFlow(data.id);
        }
      },
      onError: (err) => {
        setError('name', { message: err.cause?.message }, { shouldFocus: true });
      },
    },
  });
  const editFlow = useEditFlow({
    config: {
      onSuccess: () => {
        onShowAlert('Flow edited');
        reset();
        close();
      },
      onError: (err) => {
        setError('name', { message: err.cause?.message }, { shouldFocus: true });
      },
    },
  });

  const onSubmit = useCallback(
    ({ name }: FlowsModifyFormFields) => {
      if (isCreateFlow) {
        createFlow.mutate({
          project_id: project === 'all_projects' ? null : parseInt(project as string),
          name,
        });
      } else {
        editFlow.mutate({
          id,
          name,
        });
      }
    },
    [createFlow, editFlow, isCreateFlow, id, project]
  );

  return (
    <FormProvider {...formMethods}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Input name="name" label="Flow name" required />
        </Box>
        <Button
          startIcon={createFlow.isPending || editFlow.isPending ? <CircularProgress size={16} color="inherit" /> : null}
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          disabled={createFlow.isPending || editFlow.isPending}
        >
          {isCreateFlow ? 'Create' : 'Save'}
        </Button>
      </Box>
    </FormProvider>
  );
};

export const FlowsModify: FC<FlowModifyProps> = ({ isOpen, close, id, name, modalProps, children }) => {
  return (
    <>
      <FlowsDrawer open={isOpen} onClose={close} modalProps={modalProps}>
        <Box sx={{ display: 'flex', flex: 1, flexGrow: 1, flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h2">{!isUndefined(id) ? 'Edit flow' : 'New flow'}</Typography>
            <IconButton onClick={close}>
              <Close />
            </IconButton>
          </Box>
          <Suspense
            fallback={
              <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <Skeleton
                  width={70}
                  height={18}
                  sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1.25 }}
                />
                <Skeleton
                  width={'100%'}
                  height={40}
                  sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 4 }}
                />
                <Skeleton
                  width={70}
                  height={18}
                  sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1.25 }}
                />
                <Skeleton
                  width={'100%'}
                  height={40}
                  sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1 }}
                />
              </Box>
            }
          >
            {children ?? <FlowsFormModify id={id} name={name} close={close} />}
          </Suspense>
        </Box>
      </FlowsDrawer>
    </>
  );
};

export const AddFlow = () => {
  const { open, close, isOpen } = useModal();

  return (
    <>
      <Button
        sx={{ justifyContent: 'start', mb: 1 }}
        size="small"
        variant="text"
        startIcon={<AddIcon />}
        onClick={open}
      >
        Add flow
      </Button>
      <FlowsModify isOpen={isOpen} close={close} />
    </>
  );
};

export const EditFlow: FC<FlowModifyProps> = ({ isOpen, close, id, name }) => {
  return <FlowsModify isOpen={isOpen} close={close} id={id} name={name} />;
};

export const DeleteFlow: FC<DeleteFlowItemProps> = ({ isOpen, close, id, name }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { onShowAlert } = useEnqueueSnackbar();
  const { setPlatform, setListId } = useListParams();
  const deleteFlow = useDeleteFlow({
    config: {
      onSuccess: () => {
        onShowAlert(`"${name}" flow deleted`);
        close();
        setPlatform(undefined);
        setListId(undefined);
      },
      onError: (e) => {
        setErrorMessage(e.cause ? (e.cause?.message as string) : 'Internal Error');
      },
    },
  });

  const onConfirm = useCallback(() => {
    setErrorMessage(null);
    deleteFlow.mutate({ id });
  }, []);

  return (
    <>
      <ConfirmDialog
        title={`Delete flow ${name}?`}
        text={
          <>
            <Typography variant="body1">
              Rules from this folder will continue to perform their actions. Rules will be moved to “Not-assigned”
              folder
            </Typography>
            {!!errorMessage && (
              <Typography variant="body1" sx={(t) => ({ color: t.palette.error.main, pt: 2 })}>
                {errorMessage}
              </Typography>
            )}
          </>
        }
        confirmButton="Delete flow"
        cancelButton="Cancel"
        isOpen={isOpen}
        isPending={deleteFlow.isPending}
        close={() => (!deleteFlow.isPending ? close() : null)}
        onCancel={() => (!deleteFlow.isPending ? close() : null)}
        onConfirm={onConfirm}
      />
    </>
  );
};
