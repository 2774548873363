import type { SxProps, Theme } from '@mui/material';
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material';
import Checkbox from 'components/common/Checkbox';
import type { FC } from 'react';
import { useEffect, useCallback } from 'react';
import useChosenRules from '../hooks/useChosenRules';
import { mapValues } from 'lodash-es';
import useListParams from 'hooks/useListParams';
import SwitchInput from 'components/common/SwitchInput';
import { useFormContext } from 'react-hook-form';
import ConfirmDialog from 'components/common/ConfirmDialog';
import type { UseModalResult } from 'hooks';
import { useModal } from 'hooks';
import { useProjects } from 'features/projects';
import useBulkPause from '../hooks/useBulkPause';
import type { ArchiveStatusValue } from '..';
import { useAccessModify } from '..';
import { Close as CloseIcon } from '@mui/icons-material';
import type { BulkActionRulesParams } from '../api/types';
import AddToFlowForm from './AddToFlowForm';
import BulkDuplicateForm from './BulkDuplicateForm';
import RadioGroup from 'components/common/RadioGroup';
import BulkEditForm from './BulkEditForm';

interface ConfirmBulkDialog extends Pick<UseModalResult, 'close' | 'isOpen'> {
  errorMessage?: string;
  isPending: boolean;
  onConfirm: () => void;
  status?: ArchiveStatusValue;
}
interface ConfirmBulkAddToFlowDialog
  extends Pick<UseModalResult, 'close' | 'isOpen'>,
    Pick<ConfirmBulkDialog, 'errorMessage' | 'isPending'> {
  onConfirm: (params: Pick<BulkActionRulesParams, 'flows'>) => void;
}

export const AllRulesCheckbox = () => {
  const { chosenRules, isIndeterminate, onSetIsAllRules, onSetRules } = useChosenRules();

  const handleOnChange = useCallback(
    (checked: boolean) => {
      const allMetricsValue = mapValues(chosenRules, () => checked);
      onSetRules(allMetricsValue);
    },
    [mapValues, onSetRules, chosenRules]
  );

  useEffect(() => {
    onSetIsAllRules(!Object.values(chosenRules ?? {}).includes(false));
  }, [chosenRules]);

  return (
    <Checkbox
      indeterminate={isIndeterminate}
      name={`all_rules_chosen`}
      controlLabelSx={{ '&': { p: 0, m: 0 } }}
      onChange={handleOnChange}
    />
  );
};

export const ConfirmBulkPauseDialog: FC<ConfirmBulkDialog> = ({
  errorMessage,
  isOpen,
  isPending,
  close,
  onConfirm,
}) => {
  const { rulesIds, isIndeterminate } = useChosenRules();
  const { tab, project } = useListParams();
  const { data: listProjects } = useProjects<Record<string, string>>({
    config: {
      select: (data) => data.reduce((accum, { name, id }) => ({ ...accum, [id]: name }), {}),
    },
  });

  const generateTitle = (tab: string | null | undefined, rulesIds: number[], project: string) => {
    const projectTitle = project === `all_projects` ? `all projects` : `${listProjects[project]} project ?`;
    const actionTitle = tab === 'active' ? 'Pause' : 'Unpause';

    return `${actionTitle} ${!isIndeterminate ? `all rules in ${projectTitle}` : `${rulesIds.length} rules in ${projectTitle}`}`;
  };

  return (
    <ConfirmDialog
      title={generateTitle(tab, rulesIds, project)}
      text={
        <>
          {tab === 'active' ? (
            <Typography variant="body1">
              You will be able to find particular paused rules using the “Pause date“ date filter in the “Paused” tab.
            </Typography>
          ) : (
            <Typography variant="body1">Rules will become active and perform their actions.</Typography>
          )}
          {!!errorMessage && (
            <Typography variant="body1" sx={(t) => ({ color: t.palette.error.main, pt: 2 })}>
              {errorMessage}
            </Typography>
          )}
        </>
      }
      confirmButton={tab === 'active' ? 'Pause rules' : 'Unpause rules'}
      cancelButton="Cancel"
      isOpen={isOpen}
      isPending={isPending}
      close={close}
      onCancel={close}
      onConfirm={onConfirm}
    />
  );
};

export const ConfirmRunManuallyDialog: FC<ConfirmBulkDialog> = ({
  errorMessage,
  isOpen,
  isPending,
  close,
  onConfirm,
}) => {
  const { rulesIds, isIndeterminate } = useChosenRules();
  const { project } = useListParams();
  const { data: listProjects } = useProjects<Record<string, string>>({
    config: {
      select: (data) => data.reduce((accum, { name, id }) => ({ ...accum, [id]: name }), {}),
    },
  });

  const generateTitle = useCallback(() => {
    const projectTitle = project === `all_projects` ? `in all projects` : `in ${listProjects[project]} project ?`;
    return !isIndeterminate
      ? `Run all rules in ${projectTitle}`
      : rulesIds.length === 1
        ? `Run rule manually ${projectTitle}`
        : `Run ${rulesIds.length} rules manually ${projectTitle}`;
  }, [isIndeterminate, rulesIds]);

  return (
    <ConfirmDialog
      title={generateTitle()}
      text={
        !!errorMessage && (
          <Typography variant="body1" sx={(t) => ({ color: t.palette.error.main, pt: 2 })}>
            {errorMessage}
          </Typography>
        )
      }
      confirmButton={`Run ${rulesIds.length === 1 ? `rule` : `rules`} manually`}
      cancelButton="Cancel"
      isOpen={isOpen}
      isPending={isPending}
      close={close}
      onCancel={close}
      onConfirm={onConfirm}
    />
  );
};

export const ConfirmBulkArchivingDialog: FC<ConfirmBulkDialog> = ({
  status,
  errorMessage,
  isOpen,
  isPending,
  close,
  onConfirm,
}) => {
  const { rulesIds, isIndeterminate } = useChosenRules();
  const { tab, project } = useListParams();
  const { data: listProjects } = useProjects<Record<string, string>>({
    config: {
      select: (data) => data.reduce((accum, { name, id }) => ({ ...accum, [id]: name }), {}),
    },
  });

  const generateTitle = (tab: string | null | undefined, rulesIds: number[], project: string) => {
    const projectTitle = project === `all_projects` ? `all projects` : `${listProjects[project]} project ?`;
    const actionTitle = tab === 'archived' ? 'Unarchive' : 'Archive';
    const actionStatus = status ? `as ${status}` : '';

    return `${actionTitle} ${!isIndeterminate ? `all rules ${actionStatus} in ${projectTitle}` : rulesIds.length === 1 ? `rule ${actionStatus} in ${projectTitle}` : `${rulesIds.length} rules ${actionStatus} in ${projectTitle}`}`;
  };

  return (
    <ConfirmDialog
      title={generateTitle(tab, rulesIds, project)}
      text={
        <>
          {tab === 'archived' ? (
            <Typography variant="body1">
              {status === 'active' && 'Rules will become active and perform their actions'}
              {status === 'paused' &&
                'You will be able to find particular paused rules using the “Pause date“ date filter in the “Paused” tab.'}
            </Typography>
          ) : (
            <Typography variant="body1">You can unarchive them any time.</Typography>
          )}
          {!!errorMessage && (
            <Typography variant="body1" sx={(t) => ({ color: t.palette.error.main, pt: 2 })}>
              {errorMessage}
            </Typography>
          )}
        </>
      }
      confirmButton={
        tab === 'archived' ? `Unarchive as ${status}` : `Archive ${rulesIds.length === 1 ? 'rule' : 'rules'} `
      }
      cancelButton="Cancel"
      isOpen={isOpen}
      isPending={isPending}
      close={close}
      onCancel={close}
      onConfirm={onConfirm}
    />
  );
};

export const ConfirmBulkAddToFlowDialog: FC<ConfirmBulkAddToFlowDialog> = ({ isOpen, close }) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={close}
      sx={{ p: 0 }}
      PaperProps={{ sx: { pt: 2, pl: 10, pr: 2, boxShadow: 'none' } }}
      autoFocus
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          pb: 3,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <DialogTitle variant="h3" sx={{ p: 0, mb: 1 }}>
            Add to flow folder
          </DialogTitle>
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            Select flows to add rules to
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={close}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider component={Box} sx={{ ml: '-80px', mr: '-16px' }} />
      <DialogContent sx={{ p: 0 }}>
        <AddToFlowForm close={close} />
      </DialogContent>
    </Dialog>
  );
};

export const ConfirmBulkDuplicateDialog: FC<Pick<UseModalResult, 'close' | 'isOpen'>> = ({ isOpen, close }) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={close}
      sx={{ p: 0 }}
      PaperProps={{ sx: { pt: 2, pl: 10, pr: 2, boxShadow: 'none' } }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          pb: 3,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <DialogTitle variant="h3" sx={{ p: 0, mb: 1 }}>
            Duplicate rules
          </DialogTitle>
          <Typography variant="body2" sx={(t) => ({ color: t.palette.text.secondary })}>
            There cannot be two rules with the same name and conditions. Make changes to save new rules.
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={close}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider component={Box} sx={{ ml: '-80px', mr: '-16px' }} />
      <DialogContent sx={{ p: 0 }}>
        <BulkDuplicateForm close={close} />
      </DialogContent>
    </Dialog>
  );
};

export const ConfirmBulkEditDialog: FC<Pick<UseModalResult, 'close' | 'isOpen'>> = ({ isOpen, close }) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={close}
      sx={{ p: 0 }}
      PaperProps={{ sx: { pt: 2, pl: 10, pr: 2, boxShadow: 'none' } }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          pb: 3,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <DialogTitle variant="h3" sx={{ p: 0, mb: 1 }}>
            Edit rules
          </DialogTitle>
        </Box>
        <Box>
          <IconButton
            onClick={close}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider component={Box} sx={{ ml: '-80px', mr: '-16px' }} />
      <DialogContent sx={{ p: 0 }}>
        <BulkEditForm close={close} />
      </DialogContent>
    </Dialog>
  );
};

const SwitchChosenRules = () => {
  const { reset } = useFormContext();
  const { open, close, isOpen } = useModal();

  const handleClose = useCallback(() => {
    close();
    reset();
  }, [close, reset]);
  const { onPause, isPending, isSuccess, isChosenRules } = useBulkPause({ onError: handleClose });

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      <Box sx={{ width: 38, height: 26, alignItems: 'center', display: 'flex', position: 'relative' }}>
        <SwitchInput
          handleChange={open}
          name="all_rules_switched"
          size="small"
          disabled={!isChosenRules || isPending}
          sx={{ mb: 0, m: 0 }}
        />
      </Box>
      <ConfirmBulkPauseDialog isOpen={isOpen} isPending={isPending} close={handleClose} onConfirm={onPause} />
    </Box>
  );
};

export const SwitchChosenRulesVisibility = () => {
  const { accessRules } = useAccessModify();
  const { tab } = useListParams();
  const isVisible = accessRules.bulk && tab && ['active', 'paused'].includes(tab);
  return isVisible ? <SwitchChosenRules /> : null;
};

export const RuleStatusParameters: FC<{ name?: string; sx?: SxProps<Theme> }> = ({ name, sx }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', mb: 4, ...sx }}>
      <Typography variant="subtitle1" sx={{ lineHeight: 2.5 }}>
        Status
      </Typography>
      <RadioGroup
        row
        name={name ?? 'ruleStatus'}
        options={[
          { value: 'active', label: 'Active' },
          { value: 'paused', label: 'Paused' },
          { value: 'archived', label: 'Archived' },
        ]}
      />
    </Box>
  );
};
