import { QueryKey, useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import { MetricEntry } from './types';
import { prefetchData } from './prefetchData';

const getConditionsFlows = async ({ signal }: { signal?: AbortSignal }): Promise<MetricEntry[]> => {
  const res = await apiClient.get<{ data: MetricEntry[] }>(`/conditions/flows`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data.data;
};

interface UseConditionsFlows<TData> {
  config?: QueryConfig<typeof getConditionsFlows, TData>;
}

export const useConditionsFlows = <TData = MetricEntry[]>({ config }: UseConditionsFlows<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getConditionsFlows({ signal }),
    queryKey: ['conditions', 'flows'] as QueryKey,
  });
};

export const prefetchConditionsFlows = async () =>
  await prefetchData(getConditionsFlows, ['conditions', 'flows'] as const);
