import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useAnchorMenu, useModal } from 'hooks';
import { MoreVert } from '@mui/icons-material';
import useBulkPause from '../hooks/useBulkPause';
import { useFormContext } from 'react-hook-form';
import {
  ConfirmBulkArchivingDialog,
  ConfirmBulkDuplicateDialog,
  ConfirmBulkEditDialog,
  ConfirmBulkPauseDialog,
  ConfirmRunManuallyDialog,
} from './RulesComponents';
import useListParams from 'hooks/useListParams';
import useBulkRunManually from '../hooks/useBulkRunManually';
import useChosenRules from '../hooks/useChosenRules';
import { useAccessModify } from '../hooks';
import useBulkArchive from '../hooks/useBulkArchive';
import type { ArchiveStatusValue } from '../types';
import { useEmergencyContext } from 'features/emergency';

interface BulkArchiveMenuItemProps {
  closeMenu?: () => void;
  status?: ArchiveStatusValue;
  label: string;
}

const BulkArchiveMenuItem: FC<BulkArchiveMenuItemProps> = ({ closeMenu, status, label }) => {
  const { reset } = useFormContext();
  const { open, close: closeArchiveManually, isOpen } = useModal();
  const { isChosenRules } = useChosenRules();

  const handleClose = () => {
    closeArchiveManually();
    closeMenu?.();
    reset();
  };
  const { onArchiveManually, isPending } = useBulkArchive({
    status,
    onSuccess: handleClose,
    onError: handleClose,
  });

  return (
    <>
      <MenuItem disabled={!isChosenRules} onClick={open}>
        <ListItemText>{label}</ListItemText>
      </MenuItem>
      <ConfirmBulkArchivingDialog
        status={status}
        isOpen={isOpen}
        isPending={isPending}
        close={() => (!isPending ? handleClose() : null)}
        onConfirm={onArchiveManually}
      />
    </>
  );
};

const ArchiveRulesMenuItem: FC<Pick<BulkArchiveMenuItemProps, 'closeMenu'>> = ({ closeMenu }) => {
  const { accessRules } = useAccessModify();
  return accessRules.archive ? <BulkArchiveMenuItem closeMenu={closeMenu} label="Archive" /> : null;
};

const UnarchiveRulesMenuItem: FC<Pick<BulkArchiveMenuItemProps, 'closeMenu' | 'status'>> = ({ closeMenu, status }) => {
  const { accessRules } = useAccessModify();
  return accessRules.unarchive ? (
    <BulkArchiveMenuItem closeMenu={closeMenu} status={status} label={`Unarchive as ${status}`} />
  ) : null;
};

const RunManuallyMenuItem: FC<Required<Pick<BulkArchiveMenuItemProps, 'closeMenu'>>> = ({ closeMenu }) => {
  const { isEmergency } = useEmergencyContext();
  const { accessRules } = useAccessModify();
  const { isChosenRules } = useChosenRules();
  const { reset } = useFormContext();
  const { open: openRunManually, close: closeRunManually, isOpen: isOpenRunManually } = useModal();

  const handleClose = () => {
    closeRunManually();
    closeMenu();
    reset();
  };

  const { onRunManually, isPending: isPendingRunManually } = useBulkRunManually({
    onSuccess: handleClose,
    onError: handleClose,
  });
  return (
    accessRules.manually && (
      <>
        <MenuItem disabled={!isChosenRules || isEmergency} onClick={openRunManually}>
          <ListItemText>Run manually</ListItemText>
        </MenuItem>

        <ConfirmRunManuallyDialog
          isOpen={isOpenRunManually}
          isPending={isPendingRunManually}
          close={() => (!isPendingRunManually ? handleClose() : null)}
          onConfirm={onRunManually}
        />
      </>
    )
  );
};

const PauseMenuItem: FC<Pick<BulkArchiveMenuItemProps, 'closeMenu'>> = ({ closeMenu }) => {
  const { accessRules } = useAccessModify();
  const { isChosenRules } = useChosenRules();
  const { tab } = useListParams();

  const { reset } = useFormContext();
  const { open: openPause, close: closePause, isOpen: isOpenPause } = useModal();

  const handleClosePauseDialog = useCallback(() => {
    closeMenu?.();
    closePause();
    reset();
  }, [closeMenu, closePause, reset]);

  const { onPause, isPending: isPendingPause } = useBulkPause({
    onSuccess: handleClosePauseDialog,
    onError: handleClosePauseDialog,
  });

  return (
    <>
      {accessRules.bulk && ['active', 'paused'].includes(tab as string) ? (
        <MenuItem disabled={!isChosenRules} onClick={openPause}>
          <ListItemText>{tab === 'active' ? 'Pause' : 'Unpause'}</ListItemText>
        </MenuItem>
      ) : null}

      <ConfirmBulkPauseDialog
        isOpen={isOpenPause}
        isPending={isPendingPause}
        close={() => (!isPendingPause ? handleClosePauseDialog() : null)}
        onConfirm={onPause}
      />
    </>
  );
};

const RulesTableActionMenu = () => {
  const { accessRules } = useAccessModify();
  const { anchor, isOpen, handleCloseMenu, handleOpenMenu } = useAnchorMenu();

  const { isChosenRules } = useChosenRules();

  const { reset } = useFormContext();
  const { open: openBulkDuplicateModal, close: closeBulkDuplicateModal, isOpen: isBulkDuplicateModalOpen } = useModal();
  const { open: openBulkEditModal, close: closeBulkEditModal, isOpen: isBulkEditModalOpen } = useModal();

  const handleCloseBulkDuplicateDialog = useCallback(() => {
    closeBulkDuplicateModal();
    reset();
  }, [closeBulkDuplicateModal, reset]);

  const handleOpenBulkDuplicateDialog = useCallback(() => {
    openBulkDuplicateModal();
    handleCloseMenu();
  }, [openBulkDuplicateModal, handleCloseMenu]);

  const handleCloseBulkEditDialog = useCallback(() => {
    closeBulkEditModal();
    reset();
  }, [closeBulkEditModal, reset]);

  const handleOpenBulkEditDialog = useCallback(() => {
    openBulkEditModal();
    handleCloseMenu();
  }, [openBulkEditModal, handleCloseMenu]);

  return (
    <>
      <IconButton disabled={!accessRules.bulk} onClick={handleOpenMenu} sx={{ width: 36, height: 36 }}>
        <MoreVert sx={{ fontSize: 24 }} />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={isOpen}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: {
              width: 220,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableAutoFocusItem
        disableAutoFocus
      >
        <PauseMenuItem closeMenu={handleCloseMenu} />
        {accessRules.bulk && (
          <MenuItem disabled={!isChosenRules} onClick={handleOpenBulkDuplicateDialog}>
            <ListItemText>Duplicate</ListItemText>
          </MenuItem>
        )}
        {accessRules.bulk && (
          <MenuItem disabled={!isChosenRules} onClick={handleOpenBulkEditDialog}>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        <RunManuallyMenuItem closeMenu={handleCloseMenu} />
        <ArchiveRulesMenuItem closeMenu={handleCloseMenu} />
        <UnarchiveRulesMenuItem status={'active'} closeMenu={handleCloseMenu} />
        <UnarchiveRulesMenuItem status={'paused'} closeMenu={handleCloseMenu} />
      </Menu>
      <ConfirmBulkDuplicateDialog isOpen={isBulkDuplicateModalOpen} close={handleCloseBulkDuplicateDialog} />
      <ConfirmBulkEditDialog isOpen={isBulkEditModalOpen} close={handleCloseBulkEditDialog} />
    </>
  );
};

export default RulesTableActionMenu;
