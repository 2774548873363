import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';

const wellPilotTheme = createTheme({
  theme: 'light',
  settings: {
    background: '#ffffff',
    fontSize: '14px',
  },
  styles: [
    { tag: t.name, color: '#008080' },
    { tag: t.string, color: '#DD1144' },
    { tag: t.punctuation, color: 'black' },
    { tag: t.number, color: '#008080' },
  ],
});

export default wellPilotTheme;
