import { Box } from '@mui/system';
import { Divider, Drawer, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import type { FC } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useLogsDetails } from '../api/getLogDetails';
import LogDetailsContent from './LogDetailsContent';
import LogDetailsSkeleton from './LogDetailsSkeleton';
interface LogDetailsDrawerProps {
  open: boolean;
  onClose: () => void;
}

const LogDetailsDrawer: FC<LogDetailsDrawerProps> = ({ onClose, open }) => {
  const t = useTheme();
  const [logId] = useQueryParam('logId', NumberParam);

  const { data } = useLogsDetails({ id: Number(logId), config: { enabled: !!logId } });

  // @ts-expect-error any
  const ruleDataView = !!data?.item_state_old?.data;

  return (
    <Drawer
      PaperProps={{ sx: { boxShadow: 'none', borderLeft: `1px solid ${t.palette.grey[50]}` } }}
      sx={{ boxShadow: 'none' }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          width: ruleDataView ? '100vw' : 500,
          px: 4,
          py: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {data ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="h2" sx={{ textTransform: 'capitalize' }}>
                {data.action_type.split('_').join(' ')}
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
              <AccountCircleIcon sx={{ fontSize: 16 }} />
              <Typography variant="body2" color="text.secondary">
                {data.user_email}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
              <CalendarTodayIcon sx={{ fontSize: 16 }} />
              <Typography variant="body2" color="text.secondary">
                {data.finished_at}
              </Typography>
            </Box>
            <Divider />
            <LogDetailsContent action_type={data.action_type} data={data} />{' '}
          </>
        ) : (
          <LogDetailsSkeleton />
        )}
      </Box>
    </Drawer>
  );
};

export default LogDetailsDrawer;
