import { Box } from '@mui/material';
import useLogsQueryParams from '../hooks/useLogsQueryParams';
import FilterSearchField from 'components/common/FilterSearchField';
import Select from '../../../components/common/Select';
import { useLogsActionTypes } from '../api/getLogsActionTypes';
import { useLogsItemTypes } from '../api/getLogsItemTypes';
import DatePicker from '../../../components/common/Datepicker';

const Filters = () => {
  const {
    actions: { setDate, setEmail, setQ, setAction, setItemType },
    params: { date },
  } = useLogsQueryParams();

  const { data: actionTypes } = useLogsActionTypes({
    config: { select: (data) => data.map((t) => ({ value: t.id, label: t.name })) },
  });

  const { data: itemTypes } = useLogsItemTypes({
    config: { select: (data) => data.map((t) => ({ value: t.id, label: t.name })) },
  });

  return (
    <Box
      sx={{
        py: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        flexGrow: 1,
        gap: 1,
        maxHeight: 70,
        overflow: 'hidden',
        overflowX: 'auto',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <FilterSearchField fullWidth setParam={setEmail} name="email" placeholder="Search by email" />
      </Box>
      <Box sx={{ flex: 1 }}>
        <FilterSearchField fullWidth setParam={setQ} name="q" placeholder="Search by item name" />
      </Box>
      <Box sx={{ width: '15%' }}>
        <DatePicker maxDate={new Date()} field="date" label="Date" actualValue={date} setActualValue={setDate} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Select
          id="action"
          name="action"
          inputLabel="Action"
          options={actionTypes}
          placeholder="Action"
          multiple
          selectAll={false}
          handleChange={(value) => {
            setAction(value);
          }}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Select
          name="itemType"
          placeholder="Item type"
          inputLabel="Item type"
          options={itemTypes}
          multiple
          selectAll={false}
          handleChange={(value) => {
            setItemType(value);
          }}
        />
      </Box>
    </Box>
  );
};

export default Filters;
