import type { TestContext } from 'yup';

export function uniqueValidation(this: TestContext, value: any[]) {
  try {
    const timeSet = new Set(value);

    if (timeSet.size !== value.length) {
      throw new Error('Only unique time values are allowed in the timetable');
    }

    return true;
  } catch (e) {
    // @ts-expect-error error type correction
    return this.createError({ message: String(e.message) });
  }
}
