import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { FC, useCallback, useRef } from 'react';
import { useProjects } from '../api/getProjects';
import type { Option } from 'types/shared';
import useListParams from 'hooks/useListParams';
import IconWT from 'assets/icon-welltech.svg?react';
import IconFC from 'assets/icon-fc.svg?react';
import IconYg from 'assets/icon-yg.svg?react';
import IconMB from 'assets/icon-mb.svg?react';
import IconOM from 'assets/icon-om.svg?react';
import IconWF from 'assets/icon-wf.svg?react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';

const FilterProjects: FC = () => {
  const iconsRef = useRef<Record<string, any>>({
    WT: <IconWT width={22} height={20} />,
    FC: <IconFC width={22} height={20} />,
    YG: <IconYg width={22} height={20} />,
    MB: <IconMB width={22} height={20} />,
    OM: <IconOM width={22} height={20} />,
    WF: <IconWF width={22} height={20} />,
    default: <IconWT width={22} height={20} />,
  });
  const location = useLocation();
  const isEditPage = location.pathname === '/metrics/edit';
  const { project, setProject } = useListParams();
  const { control, setValue } = useFormContext();

  const { data } = useProjects<Option[]>({
    config: {
      select: (data) => [
        ...data.filter((prj) => prj.id !== 0).map(({ name, id }) => ({ value: `${id}`, label: name })),
        { value: 'all_projects', label: 'All projects' },
      ],
    },
  });

  const handleChange = useCallback(
    (e: SelectChangeEvent<number>, onChange: (...event: any[]) => void) => {
      onChange(e.target.value);
      setValue('project', e.target.value);
      setProject(e.target.value as string);
    },
    [setProject, setValue]
  );

  return (
    <Controller
      name={'project'}
      control={control}
      render={({ field }) => {
        const { onChange } = field;
        return (
          <Select
            {...field}
            onChange={(e) => handleChange(e, onChange)}
            sx={(t) => ({
              '&': {
                minWidth: 68,
                minHeight: 25,
              },
              '& .MuiSelect-outlined.MuiSelect-select': {
                fontSize: t.typography.h3.fontSize,
                minWidth: 68,
                minHeight: 25,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            })}
            renderValue={(value) => (
              <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexWrap: 'nowrap' }}>
                <Box sx={{ mr: 1 }}>
                  {iconsRef.current[data.find((option) => option.value === value)?.label as string] ??
                    iconsRef.current.default}
                </Box>
                <Typography
                  sx={(t) => ({
                    fontSize: t.typography.h3.fontSize,
                    lineHeight: '23px',
                    height: '24px',
                  })}
                >
                  {data.find((option) => option.value === value)?.label}
                </Typography>
              </Box>
            )}
            MenuProps={{
              sx: {
                '& .MuiPaper-root': {
                  marginTop: 2,
                  minWidth: '200px !important',
                },
              },
            }}
          >
            {data.map((option) => (
              <MenuItem
                key={`project:${option.value}:${option.label}`}
                value={option.value}
                disabled={option.value === '0' && isEditPage}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    />
  );
};

export default FilterProjects;
