import { apiClient } from '../../../lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { EditGroupParams } from './types';
import { isAxiosError } from 'axios';

export const editGroup = async ({ group_id, ...params }: EditGroupParams): Promise<{}> => {
  const res = await apiClient.patch<{}>(`/groups/${group_id}`, params);
  if (isAxiosError(res)) {
    const error = new Error('axios error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseEditGroupOptions {
  config?: MutationConfig<typeof editGroup>;
}

export const useEditGroup = ({ config }: UseEditGroupOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['groups'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['metrics'],
        }),
      ]);

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: editGroup,
  });
};
