import type { RuleData } from '../types';

const orderRule = (ruleData: RuleData) => {
  const {
    name,
    type,
    level,
    description,
    facebook_ad_account_ids,
    notification_channels,
    filters,
    tasks,
    schedule_interval,
    timetable,
    schedule_timezone,
    notification_level,
    ...rest
  } = ruleData;
  return {
    name,
    type,
    level,
    description,
    facebook_ad_account_ids,
    notification_channels,
    filters,
    tasks,
    schedule_interval,
    timetable,
    schedule_timezone,
    notification_level,
    ...rest,
  };
};

export default orderRule;
