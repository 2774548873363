import { Box, Skeleton, Tab, Tabs } from "@mui/material"

const FilterStatusTabsSkeleton = () => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs>
        <Tab label={<Skeleton width={120} height={26} sx={{ transform: 'translateY(0)', borderRadius: '8px', }} />} value={'0'} />
        <Tab label={<Skeleton width={120} height={26} sx={{ transform: 'translateY(0)', borderRadius: '8px', }} />} value={'1'} />
        <Tab label={<Skeleton width={120} height={26} sx={{ transform: 'translateY(0)', borderRadius: '8px', }} />} value={'2'} />
        <Tab label={<Skeleton width={120} height={26} sx={{ transform: 'translateY(0)', borderRadius: '8px', }} />} value={'3'} />
      </Tabs>
    </Box>
  )
}

export default FilterStatusTabsSkeleton;
