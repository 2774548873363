import type { FC, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { PaperProps, SelectProps, SxProps } from '@mui/material';
import {
  Select as MuiSelect,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
  InputLabel,
  FormControl,
} from '@mui/material';
import type { Option, OptionValue } from 'types/shared';
import { Box } from '@mui/system';

export interface ControlledSelectProp extends Omit<SelectProps<OptionValue | OptionValue[]>, 'name' | 'variant'> {
  name: string;
  options: Option[];
  containerSx?: SxProps;
  inputLabel?: string;
  selectAll?: boolean;
  disabled?: boolean;
  PaperProps?: Partial<PaperProps<React.ElementType>>;
  handleChange?: (value: string[]) => void;
  footerElement?: ReactNode;
}
const Select: FC<ControlledSelectProp> = ({
  name,
  options,
  label,
  inputLabel,
  required,
  multiple = false,
  containerSx,
  disabled,
  selectAll = true,
  PaperProps = null,
  handleChange,
  footerElement = null,
  ...restProps
}) => {
  const { control, formState, watch, setValue, trigger } = useFormContext();
  const error = formState.errors[name];

  if (multiple) {
    const fieldValue: OptionValue[] = (watch(name) ?? []) as OptionValue[];
    const isAllSelected = options.length > 0 && fieldValue.length === options.length;

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => {
          const selectedOptionsLength = fieldValue.length;

          return (
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', ...containerSx }}>
              {label && !inputLabel && (
                <Typography
                  variant="subtitle2"
                  mb={1}
                  sx={(t) => ({ color: disabled ? t.palette.text.disabled : t.palette.text.primary })}
                >
                  {label}
                  {required && '*'}
                </Typography>
              )}
              <FormControl sx={{ flex: 1 }}>
                {inputLabel && !label && (
                  <InputLabel
                    id={`${label}_select`}
                    sx={{
                      backgroundColor: 'white',
                      '&:not(.Mui-focused):not(.MuiInputLabel-shrink)': { transform: 'translate(14px, 8px) scale(1)' },
                    }}
                  >
                    {inputLabel}
                  </InputLabel>
                )}
                <MuiSelect<OptionValue[] | OptionValue>
                  labelId={inputLabel ? `${inputLabel}_select` : undefined}
                  value={(value as OptionValue[] | undefined) ?? []}
                  onChange={(event) => {
                    if (
                      typeof event.target.value === 'object' &&
                      (event.target.value as string[]).includes('selectAll')
                    ) {
                      return;
                    }
                    onChange(event);
                    handleChange && handleChange(event.target.value as string[]);
                    trigger(name);
                  }}
                  multiple
                  sx={{ '& legend': { display: label ? 'none' : 'block' }, '& fieldset': label ? { top: 0 } : {} }}
                  fullWidth
                  renderValue={(selected) => (
                    <Box sx={{ position: 'absolute', right: 0, left: 0, pl: 1.5, pr: 4 }}>
                      <Typography
                        sx={(t) => ({
                          position: 'relative',
                          display: 'block',
                          fontSize: t.typography.body2.fontSize,
                          textOverflow: 'ellipsis',
                          width: '100%',
                          overflow: 'hidden',
                          boxSizing: 'border-box',
                        })}
                      >
                        {options
                          .filter((o) => (selected as unknown as OptionValue[]).includes(o.value))
                          .map((o) => o.label)
                          .join(', ')}
                      </Typography>
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      ...PaperProps,
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  error={!!error}
                  disabled={disabled}
                  {...restProps}
                  variant="outlined"
                >
                  {selectAll && (
                    <MenuItem
                      value="selectAll"
                      onClick={() => {
                        setValue(name, isAllSelected ? [] : options.map((o) => o.value));
                      }}
                      sx={{ p: 0 }}
                    >
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={selectedOptionsLength > 0 && selectedOptionsLength < options.length}
                      />
                      <ListItemText
                        sx={(t) => ({ display: 'flex', alignItems: 'center', color: t.palette.primary.main })}
                        primary="Select all"
                      />
                    </MenuItem>
                  )}
                  {options.map((o) => (
                    <MenuItem sx={{ p: 0 }} key={o.value} value={o.value}>
                      <Checkbox checked={fieldValue.includes(o.value)} />
                      <ListItemText primary={o.label} />
                    </MenuItem>
                  ))}
                </MuiSelect>
                {error?.message && (
                  <Typography variant="subtitle1" fontSize={12} sx={{ mt: '3px' }} color="error">
                    {error.message as string}
                  </Typography>
                )}
              </FormControl>
            </Box>
          );
        }}
      />
    );
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', ...containerSx }}>
            {label && !inputLabel && (
              <Typography variant="subtitle2" mb={1}>
                {label}
                {required && '*'}
              </Typography>
            )}
            <FormControl sx={{ flex: 1 }}>
              {!label && inputLabel && (
                <InputLabel
                  id={`${inputLabel}_select`}
                  sx={{
                    backgroundColor: 'white',
                    px: 0.5,
                    '&:not(.Mui-focused):not(.MuiInputLabel-shrink)': { transform: 'translate(14px, 8px) scale(1)' },
                  }}
                >
                  {inputLabel}
                </InputLabel>
              )}
              <MuiSelect<OptionValue[] | OptionValue>
                labelId={inputLabel ? `${inputLabel}_select` : undefined}
                {...field}
                // important because MUI elem doesn't see defaultValue from react-hook-form if element is empty by default
                value={field.value ? String(field.value) : ''}
                defaultValue={''}
                fullWidth
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                input={<OutlinedInput label={inputLabel ?? null} />}
                MenuProps={{
                  PaperProps: {
                    ...PaperProps,
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                error={!!error}
                disabled={disabled}
                {...restProps}
                variant="outlined"
                label={label ?? inputLabel}
              >
                {options.map((o) => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
                {footerElement}
              </MuiSelect>
              {error?.message && (
                <Typography variant="subtitle1" fontSize={12} sx={{ mt: '3px' }} color="error">
                  {error.message as string}
                </Typography>
              )}
            </FormControl>
          </Box>
        );
      }}
    />
  );
};

export default Select;
