import { Box, Divider, Skeleton, Table, TableBody, TableCell, TableRow } from "@mui/material"

const MetricDetailsSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flex: 1, flexGrow: 1, flexDirection: 'column', }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4, }}>
        <Skeleton width={260} height={32} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, }} />
        <Skeleton width={32} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, }} />
      </Box>
        <Box sx={{ mb: 4, }}>
          <Box>
            <Skeleton width={90} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1, }} />
          </Box>
          <Skeleton width={200} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, }} />
        </Box>
        <Divider sx={{ mb: 4, }} />
      <Box sx={{ mb: 4, }}>
      <Skeleton width={140} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1, }} />
        <Table>
          <TableBody>
            {Array.from(Array(4).keys()).map(i => (
              <TableRow
                key={`${i}`}
                sx={{ 'td': { p: 0, } }}
              >
                <TableCell align="left">
                  <Skeleton width={90} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1, }} />
                </TableCell>
                <TableCell align="left">
                  <Skeleton width={90} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1, }} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton width={90} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1, }} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}
export default MetricDetailsSkeleton;
