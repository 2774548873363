import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import LogsEmptyState from './LogsEmptyState';
import LogsTableHead from './LogsTableHead';
import LogsTableRow from './LogsTableRow';
import { useLogsList } from '../api/getLogs';
import useLogsQueryParams from '../hooks/useLogsQueryParams';
import { mapParamsToRequestDto } from '../utils/mapParamsToRequestDto';
import { useMe } from '../../users';
import LogsTableSkeleton from './LogsTableSkeleton';
import { isEmpty } from 'lodash-es';

const Logs = () => {
  const {
    params: { action, page, perPage, date, email, q, itemType },
    actions: { handleRowsPerPageChange, handlePageChange },
  } = useLogsQueryParams();

  const { data: user, isPending: isUserLoading } = useMe();

  const { data: logData, isPending: areLogsLoading } = useLogsList({
    config: {
      enabled: !isEmpty(user),
    },
    params: mapParamsToRequestDto({
      project: user ? user.projects : [],
      action,
      page,
      perPage,
      date,
      email,
      q,
      itemType,
    }),
  });

  const { data: logs, meta } = { ...logData };

  if (isUserLoading || areLogsLoading) {
    return <LogsTableSkeleton />;
  }

  return logs?.length ? (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box sx={{ display: 'flex', position: 'relative', flex: 1, width: '100%' }}>
        <TableContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Table
            sx={{
              '&': {
                position: 'relative',
                zIndex: 1,
                border: 'none',
                overflowX: 'auto',
                width: '100%',
              },
            }}
            stickyHeader
            size="small"
          >
            <LogsTableHead />
            <TableBody sx={{ 'tr:last-of-type': { '& td': { border: 0 } } }}>
              {logs.map((l) => (
                <LogsTableRow key={l.id} log={l} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ maxHeight: 60 }}>
        <TablePagination
          labelRowsPerPage="Rows per page:"
          component="div"
          count={Number(meta?.count)}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[25, 50, 100]}
          rowsPerPage={Number(meta?.limit)}
          sx={(t) => ({
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: `1px solid ${t.palette.grey[300]}`,
          })}
        />
      </Box>
    </Box>
  ) : (
    <LogsEmptyState />
  );
};

export default Logs;
