import type { UserEntry } from './types';
import { apiClient } from '../../../lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { CreateUserDTO } from '../types';
import type { MutationConfig } from 'lib/react-query';
import { isAxiosError } from 'axios';

export const createUser = async (body: CreateUserDTO): Promise<UserEntry> => {
  const res = await apiClient.post<UserEntry>(`/users`, body);

  if (isAxiosError(res)) {
    const error = new Error('Create user error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }

  return res.data;
};

interface UseCreateUserOptions {
  config?: MutationConfig<typeof createUser>;
}

export const useCreateUser = ({ config }: UseCreateUserOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries({
        queryKey: ['users'],
      });

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: createUser,
  });
};
