import { Box } from '@mui/system';
import {
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import UserForm from './UserForm';
import type { FC } from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import { userSchema } from '../validation/user.schema';
import type { UserFormData } from '../types';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import type { UserRole } from '../api';
import { useCreateUser, useUpdateUser } from '../api';
import { yupResolver } from '@hookform/resolvers/yup';
import UserFormSkeleton from './UserFormSkeleton';
import { isEmpty } from 'lodash-es';

interface UserDrawerProps {
  open: boolean;
  onClose: () => void;
}

const baseAccess = [
  'Find all rules in all projects',
  'View all rules in all projects',
  'Duplicate all rules in all projects',
];

const roleAccess: Record<string, string[]> = {
  user: ['Edit rules in his project', 'Pause, archive, launch, unarchive rules in his project'],
  supervisor: ['Edit rules in all projects', 'Pause, archive, launch, unarchive rules in all projects'],
  admin: [
    'Edit rules in all projects',
    'Pause, archive, launch, unarchive rules in all projects',
    'Can add, edit or delete all users, except of  Admins',
  ],
};

const UsersDrawer: FC<UserDrawerProps> = ({ open, onClose }) => {
  const t = useTheme();
  const [userId] = useQueryParam('userId', NumberParam);

  const isEdit = !!userId;
  const formMethods = useForm<UserFormData>({
    resolver: yupResolver(userSchema),
  });
  const { handleSubmit, reset, watch, setError } = formMethods;

  const { mutateAsync: createUser, isPending: isCreating } = useCreateUser({
    config: {
      onSuccess: () => {
        onClose();
      },
      onError: (err) => {
        setError('email', { message: err.cause?.message }, { shouldFocus: true });
      },
    },
  });
  const { mutateAsync: updateUser, isPending: isUpdating } = useUpdateUser({
    config: {
      onSuccess: () => {
        onClose();
      },
      onError: (err) => {
        setError('email', { message: err.message }, { shouldFocus: true });
      },
    },
  });

  const selectedRole = watch('role');

  const onSubmit = async ({ email, role, projects }: UserFormData) => {
    const body = {
      projects_ids: projects,
      email,
      role: role as UserRole,
    };

    if (isEdit) {
      await updateUser({ userId, body });
    } else {
      await createUser(body);
    }
  };

  useEffect(() => {
    reset({ email: '', projects: [], role: '' });
  }, [open, reset]);

  const isLoading = isCreating || isUpdating;

  const rulesToMap = useMemo(() => {
    if (!selectedRole) return [];

    return [...baseAccess, ...roleAccess[selectedRole]];
  }, [selectedRole]);

  return (
    <Drawer
      PaperProps={{ sx: { boxShadow: 'none', borderLeft: `1px solid ${t.palette.grey[50]}` } }}
      sx={{ boxShadow: 'none' }}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          width: 500,
          px: 4,
          py: 5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 5 }}>
            <Typography variant="h2">{isEdit ? 'Edit' : 'New'} user</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Suspense fallback={<UserFormSkeleton />}>
            <FormProvider {...formMethods}>
              <UserForm />
            </FormProvider>
          </Suspense>
          {!isEmpty(rulesToMap) && (
            <>
              <Typography variant="subtitle2" fontWeight={400} sx={{ mt: 2.5 }}>
                Permissions:
              </Typography>
              <List
                dense
                sx={(t) => ({
                  listStyleType: 'disc',
                  p: 0,
                  pl: 3,
                  '& .MuiListItem-root': {
                    display: 'list-item',
                    color: t.palette.text.secondary,
                    p: 0,
                  },
                })}
              >
                {rulesToMap.map((r) => (
                  <ListItem key={r}>
                    <ListItemText primary={r} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
        <Button
          onClick={handleSubmit(onSubmit, (err) => {
            console.log(err);
          })}
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          sx={{ minHeight: '56px' }}
        >
          {isLoading ? <CircularProgress size={40} sx={{ color: 'white' }} /> : isEdit ? 'Save' : 'Create'}
        </Button>
      </Box>
    </Drawer>
  );
};

export default UsersDrawer;
