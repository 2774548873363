import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect } from 'react';
import { isAxiosError } from 'axios';
import { useCreateFlow } from './createFlow';
import Input from '../../../components/common/Input';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { metricCreate } from '../../metrics/validation/metricCreate.schema';
import type { Option } from '../../../types/shared';
import { useQueryClient } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import type { FlowEntry } from './types';

interface FormData {
  name: string;
}

interface AddFlowDialogProps {
  isOpen: boolean;
  close: () => void;
  dialogValue: string;
  onChange: (value: Option) => void;
}

const AddFlowDialog: FC<AddFlowDialogProps> = ({ isOpen, close, dialogValue, onChange }) => {
  const queryClient = useQueryClient();
  const { watch } = useFormContext();
  const projectId = watch('project');

  const flowsKey = queryKeys.flows(projectId);

  const formMethods = useForm<FormData>({
    resolver: yupResolver(metricCreate),
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit, setValue, setError } = formMethods;

  const { mutateAsync: createFlow, isPending } = useCreateFlow({
    config: {
      onMutate: async (payload) => {
        const newFlowId = crypto.randomUUID();
        const previousFlows = queryClient.getQueryData(flowsKey);

        await queryClient.setQueryData(flowsKey, (old: FlowEntry[]) => {
          return [...old, { id: newFlowId, name: payload.name }];
        });

        return { previousFlows, newFlowId };
      },
      onSuccess: (_, variables) => {
        onChange({ value: variables.name, label: variables.name });
        close();
      },
      onError: async (e, _payload, context) => {
        await queryClient.setQueryData(flowsKey, (context as { previousFlows: FlowEntry[] }).previousFlows);
        if (isAxiosError(e) && e.response) {
          setError('name', { message: (e.response.data as { message: string }).message });
        }
      },
    },
  });

  const onSubmit = async ({ name }: FormData) => {
    await createFlow({ project_id: Number(projectId), name: name });
  };

  useEffect(() => {
    setValue('name', dialogValue);
  }, [dialogValue, setValue]);

  return (
    <Dialog maxWidth="xs" onClose={close} open={isOpen} disableRestoreFocus>
      <FormProvider {...formMethods}>
        <Stack sx={{ p: 2, width: 350 }} spacing={4}>
          <Box>
            <Typography variant="h5">Add Flow</Typography>
          </Box>
          <Box>
            <Input label="Flow name" name="name" />
          </Box>
          <Stack direction="row" sx={{ mt: 4 }} spacing={2}>
            <Button disabled={isPending} size="large" variant="outlined" onClick={close} color="primary" fullWidth>
              Cancel
            </Button>
            <LoadingButton
              size="large"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              fullWidth
              disabled={isPending}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
    </Dialog>
  );
};

export default AddFlowDialog;
