import { Box, Grid } from '@mui/material';
import { Suspense } from 'react';
import UsersTableSkeleton from './UsersTableSkeleton';
import UsersTable from './UsersTable';

const Users = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, pt: 4, width: '100%' }}>
      <Grid container spacing={2} sx={{ marginBottom: 2.5 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          {/*<UsersEmailFilter />*/}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
          {/*<UsersAccessFilter />*/}
        </Grid>
      </Grid>
      <Suspense fallback={<UsersTableSkeleton />}>
        <UsersTable />
      </Suspense>
    </Box>
  );
};

export default Users;
