import { Box, Skeleton } from "@mui/material";

const FiltersSkeleton = () => {
  return (
    <Box sx={{ py: 2, display: 'flex', flexDirection: 'row', alignItems: 'start', flexGrow: 1, gap: 1.5, maxHeight: 70, overflow: 'hidden', overflowX: 'auto' }}>
      {[...Array(8).keys()].map(i =>
        <Skeleton key={i} width={180} height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px', }} />
      )}
    </Box>
  )
}

export default FiltersSkeleton;
