import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';

interface DeletePresetPayload {
  id: number;
}

interface DeleteGroupResponse {
  success: boolean;
}

export const deleteGroup = async ({ id }: DeletePresetPayload): Promise<DeleteGroupResponse> => {
  const res = await apiClient.delete<DeleteGroupResponse>(`/groups/${id}`);
  if (isAxiosError(res)) {
    const error = new Error('axios error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

type MutationFnType = (data: DeletePresetPayload) => ReturnType<typeof deleteGroup>;

interface UseDeleteGroupOptions {
  config?: MutationConfig<MutationFnType>;
}

export const useDeleteGroup = ({ config }: UseDeleteGroupOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({
        queryKey: ['groups'],
      });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: deleteGroup,
  });
};
