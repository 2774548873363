import { Box, Chip, Drawer, SxProps, TableCell, Theme, Typography, styled } from '@mui/material';
import Input, { InputProps } from 'components/common/Input';
import { FC, ReactNode, memo } from 'react';

export const MetricInput = memo((props: InputProps) => <Input {...props} />);
export const MetricChip = memo(({ label, sx }: { label: string; sx?: SxProps<Theme> }) => (
  <Chip
    sx={{
      '&': {
        height: 32,
      },
      '& .MuiChip-label': {
        display: 'inline-block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 300,
      },
    }}
    label={label}
  />
));

const MetricsEmptyState = () => {
  return (
    <Box sx={{ flex: 1, flexGrow: 1, display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <Typography>Data not received</Typography>
    </Box>
  );
};

interface MetricDrawerProps {
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
}
export const MetricDrawer: FC<MetricDrawerProps> = ({ open, onClose, children }): ReactNode => (
  <Drawer
    anchor={'right'}
    open={open}
    onClose={onClose}
    PaperProps={{ sx: { width: '100%', maxWidth: 500, px: 2.5, py: 3, boxShadow: 'none' } }}
  >
    {children}
  </Drawer>
);

export const TableCellStickyStyled = styled(TableCell)(() => ({
  position: 'sticky',
  top: 0,
  zIndex: 4,
}));

export default MetricsEmptyState;
