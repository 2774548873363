import { Box, Typography } from '@mui/material';

const NoSelectedRules = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="body1" sx={(t) => ({ color: t.palette.text.primary })}>
        There are no selected rules
      </Typography>
    </Box>
  );
};

export default NoSelectedRules;
