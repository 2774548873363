import { apiClient } from '../../../lib/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { BulkActionRulesParams } from './types';
import { isAxiosError } from 'axios';

export const editRules = async (params: BulkActionRulesParams): Promise<{}> => {
  const res = await apiClient.patch<{}>(`/rules`, params);
  if (isAxiosError(res)) {
    const error = new Error('Edit rules error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseEditRulesOptions {
  config?: MutationConfig<typeof editRules>;
}

export const useEditRules = ({ config }: UseEditRulesOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...config,
    onSuccess: async (...args) => {
      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['platforms'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['rules'],
        }),
      ]);
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: editRules,
  });
};
