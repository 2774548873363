import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import LogsTableSkeletonHead from './LogsTableSkeletonHead';

const LogsTableSkeleton = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box sx={{ display: 'flex', position: 'relative', flex: 1, width: '100%' }}>
        <TableContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Table
            sx={{
              '&': {
                position: 'relative',
                zIndex: 1,
                border: 'none',
                overflowX: 'auto',
                width: '100%',
              },
            }}
            stickyHeader
            size="small"
          >
            <LogsTableSkeletonHead />
            <TableBody sx={{ 'tr:last-of-type': { '& td': { border: 0 } } }}>
              {[...Array(15).keys()].map((i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Skeleton width={120} height={32} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={120} height={32} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={100} height={32} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={60} height={32} />
                  </TableCell>
                  <TableCell>
                    <Skeleton width={60} height={32} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ flex: 1, maxHeight: 60, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Skeleton width={220} height={32} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
      </Box>
    </Box>
  );
};

export default LogsTableSkeleton;
