import { MenuItem, MenuList, Skeleton } from '@mui/material';

const MetricMenuGroupsSkeleton = () => {
  return (
    <MenuList sx={{ py: 0 }}>
      <MenuItem disabled>
        <Skeleton width={'100%'} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
      </MenuItem>
      <MenuItem disabled>
        <Skeleton width={'100%'} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
      </MenuItem>
      <MenuItem disabled>
        <Skeleton width={'100%'} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
      </MenuItem>
    </MenuList>
  );
};

export default MetricMenuGroupsSkeleton;
