import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import queryKeys from './queryKeys';
import queryString from 'query-string';
import type { LogDetails } from '../types';
import type { SynchronousQueryConfig } from 'lib/react-query';

export const getLogsDetails = async (id: number, signal?: AbortSignal): Promise<LogDetails> => {
  const res = await apiClient.get<LogDetails>(`/audit_logs/${id}`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseLogsDetailsOptions<TData> {
  id: number;
  config?: SynchronousQueryConfig<typeof getLogsDetails, TData>;
}

export const useLogsDetails = <TData = LogDetails>({ id, config }: UseLogsDetailsOptions<TData>) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getLogsDetails(id, signal),
    queryKey: queryKeys.logDetails(id),
  });
};
