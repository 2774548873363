import useListParams from 'hooks/useListParams';
import useChosenRules from './useChosenRules';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useEditRules } from '../api/editRules';
import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { closeSnackbar } from 'notistack';
import useRulesQueryParams from './useRulesQueryParams';

const useBulkPause = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: () => void } = {}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { setValue, reset } = useFormContext();
  const { tab } = useListParams();
  const { rulesIds, isChosenRules } = useChosenRules();
  const rulesQueryParams = useRulesQueryParams();

  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();

  const editRulesMutation = useEditRules({
    config: {
      onSuccess: (_, variables) => {
        closeSnackbar();
        setValue('all_rules_switched', tab === 'active');
        onShowAlert(variables.status === 'active' ? 'Rules unpaused' : 'Rules paused', {
          delayAlert: 5000,
          onUndo:
            variables?.status !== tab
              ? async () => {
                  await editRulesMutation.mutate({
                    ...variables,
                    status: variables.status === 'active' ? 'paused' : 'active',
                  });
                  onShowInfoAlert(`${variables.status === 'active' ? 'Pausing rules' : 'Unpausing rules'}`, {
                    isPending: true,
                    delayAlert: 10000,
                  });
                }
              : undefined,
        });
        reset();
        onSuccess?.();
      },
      onError: (err) => {
        reset();
        onShowInfoAlert(err.cause?.message || 'Error');
        setErrorMessage(err.cause?.message);
        onError && onError();
      },
    },
  });

  const onPause = useCallback(() => {
    closeSnackbar();
    editRulesMutation.mutate({
      status: tab === 'active' ? 'paused' : 'active',
      ...rulesQueryParams,
    });
  }, [closeSnackbar, rulesQueryParams, editRulesMutation, tab]);

  return {
    errorMessage,
    isChosenRules,
    isPending: editRulesMutation.isPending,
    isSuccess: editRulesMutation.isSuccess,
    rulesIds,
    onPause,
  };
};

export default useBulkPause;
