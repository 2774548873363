import type { FC } from 'react';
import { useCallback } from 'react';
import { Cancel, Search } from '@mui/icons-material';
import { Box, Button, Chip } from '@mui/material';
import { isEmpty } from 'lodash-es';
import useLogsQueryParams from '../hooks/useLogsQueryParams';
import { useLogsActionTypes } from '../api/getLogsActionTypes';
import { useFormContext } from 'react-hook-form';

const QValues = () => {
  const {
    params: { q },
    actions: { setQ },
  } = useLogsQueryParams();
  const { setValue } = useFormContext();

  return q?.length ? (
    <Chip
      size="small"
      label={q}
      icon={<Search sx={(t) => ({ width: 18, color: t.palette.primary.main, ml: 1 })} />}
      deleteIcon={<Cancel />}
      onDelete={() => {
        setQ('');
        setValue('q', '');
      }}
      sx={{ mb: 1 }}
    />
  ) : null;
};

const EmailValues = () => {
  const {
    params: { email },
    actions: { setEmail },
  } = useLogsQueryParams();
  const { setValue } = useFormContext();

  return email?.length ? (
    <Chip
      size="small"
      label={email}
      icon={<Search sx={(t) => ({ width: 18, color: t.palette.primary.main, ml: 1 })} />}
      deleteIcon={<Cancel />}
      onDelete={() => {
        setEmail('');
        setValue('email', '');
      }}
      sx={{ mb: 1 }}
    />
  ) : null;
};

const DateValues = () => {
  const {
    params: { date },
    actions: { setDate },
  } = useLogsQueryParams();
  const { setValue } = useFormContext();

  if (!date) return null;
  const [dateFrom, dateTo] = date.split(' - ');

  return (
    <>
      <Chip
        label={`From: ${dateFrom}`}
        deleteIcon={<Cancel />}
        onDelete={() => {
          setDate(undefined);
          setValue('date', '');
        }}
      />
      <Chip
        label={`To: ${dateTo}`}
        deleteIcon={<Cancel />}
        onDelete={() => {
          setDate(undefined);
          setValue('date', '');
        }}
      />
    </>
  );
};
const ActionValues = () => {
  const {
    params: { action },
    actions: { setAction },
  } = useLogsQueryParams();
  const { setValue } = useFormContext();

  const { data: actions } = useLogsActionTypes();

  return actions
    .filter((actionType) => (action as string[]).includes(actionType.id))
    .map((actionType) => (
      <Chip
        key={actionType.id}
        label={actionType.name}
        deleteIcon={<Cancel />}
        onDelete={() => {
          const currentActions = action.filter((i) => i !== actionType.id);
          setAction(currentActions);
          setValue('action', currentActions);
        }}
      />
    ));
};

const ItemTypeValues = () => {
  const {
    params: { itemType },
    actions: { setItemType },
  } = useLogsQueryParams();
  const { setValue } = useFormContext();

  return itemType.map((item) => (
    <Chip
      key={`ActionValues:${item}`}
      label={item}
      deleteIcon={<Cancel />}
      onDelete={() => {
        setItemType((prevValue) => prevValue?.filter((i) => i !== item));
        setValue('itemType', []);
      }}
    />
  ));
};

interface SearchValuesButtonProps {
  onClick: () => void;
  title: string;
}

const SearchValuesButton: FC<SearchValuesButtonProps> = ({ onClick, title }) => {
  return (
    <Button
      variant="text"
      color="inherit"
      sx={(t) => ({
        borderRadius: t.borderRadius.sm,
        backgroundColor: 'transparent',
        marginRight: 1,
        height: 24,
      })}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

const FilterValues: FC = () => {
  const {
    params: { q, date, itemType, action, email },
    actions: { setQ, setDate, setAction, setEmail, setItemType },
  } = useLogsQueryParams();

  const handleResetAll = useCallback(() => {
    setQ(undefined);
    setEmail(undefined);
    setAction(undefined);
    setDate(undefined);
    setItemType(undefined);
  }, [setQ, setDate, setAction, setEmail, setItemType]);

  const showResetAll = [q, action, date, itemType, email].some((param) => !isEmpty(param));

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', pb: 1 }}>
      {showResetAll ? <SearchValuesButton onClick={handleResetAll} title="Reset all" /> : null}
      <EmailValues />
      <QValues />
      <DateValues />
      <ActionValues />
      <ItemTypeValues />
    </Box>
  );
};

export default FilterValues;
