import { Box, keyframes } from '@mui/material';

const rotate = keyframes`
    from {
        transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
    }

    to {
        transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
    }
`;
const RunningRuleStatus = () => {
  return (
    <Box
      sx={{
        '&': {
          '--offset': '2.5px',
          background: '#F1F9F9',
          borderRadius: '10px',
          position: 'relative',
          height: 15,
          width: 24,
          minWidth: 24,
          maxWidth: '100%',
          overflow: 'hidden',
        },
        '&::before': {
          content: "''",
          background: 'conic-gradient(transparent 140deg, #00796B, rgba(0, 121, 107, 0.05))',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          aspectRatio: 1,
          width: '100%',
          animation: `${rotate} 2s linear infinite`,
        },
        '&::after': {
          content: "''",
          background: 'inherit',
          borderRadius: 'inherit',
          position: 'absolute',
          inset: '2.5px',
          height: 'calc(100% - 5px)',
          width: 'calc(100% - 5px)',
        },
      }}
    />
  );
};

export default RunningRuleStatus;
