import { Box, Button, Typography, styled } from '@mui/material';
import useListParams from 'hooks/useListParams';
import { useEffect } from 'react';
import BgAroundStars from 'assets/bg-around-stars.webp';
import IconAroundStars from 'assets/icon-around-stars.svg?react';

const ButtonStyled = styled(Button)(({ theme: t }) => ({
  color: t.palette.text.primary,
  backgroundColor: t.palette.action.selected,
  textTransform: 'capitalize',
  padding: '0px 8px',
  fontWeight: '400',
  '&:hover': {
    backgroundColor: t.palette.action.focus,
  },
}));

const RulesEmptyState = () => {
  const { statusState, newFlow, setNewFlow, setListId, setPlatform } = useListParams();

  const handleFlows = (listId?: string[]) => {
    setPlatform(1);
    setListId(listId);
    setNewFlow(undefined);
  };

  useEffect(() => {
    return () => {
      if (newFlow) {
        setNewFlow(undefined);
      }
    };
  }, [newFlow]);

  return !newFlow ? (
    <Box
      sx={(t) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        gap: 2,
        minHeight: 176,
        p: 2.5,
        background: t.palette.action.hover,
      })}
    >
      <IconAroundStars height={120} width={207} />
      <Typography variant="h4" sx={(t) => ({ color: t.palette.text.secondary })}>
        No {statusState?.join(', ')} rules
      </Typography>
    </Box>
  ) : (
    <Box sx={{ p: 2, display: 'flex', alignItems: 'start', width: '100%' }}>
      <Box
        sx={(t) => ({
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: 2,
          minHeight: 176,
          p: 2.5,
          background: `url(${BgAroundStars}) no-repeat top right ${t.palette.action.hover}`,
          borderColor: t.palette.action.focus,
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: t.borderRadius.md,
        })}
      >
        <Typography variant="h3" sx={(t) => ({ color: t.palette.text.primary })}>
          No rules in this flow
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <Typography variant="body1" sx={(t) => ({ color: t.palette.text.secondary })}>
            Start adding rules here or choose them from
          </Typography>
          <ButtonStyled onClick={() => handleFlows()} disableElevation variant="contained" size="small">
            All flows
          </ButtonStyled>
          <ButtonStyled onClick={() => handleFlows(['not_assigned'])} disableElevation variant="contained" size="small">
            Not assigned
          </ButtonStyled>
        </Box>
      </Box>
    </Box>
  );
};

export default RulesEmptyState;
