import { useQuery } from '@tanstack/react-query';
import type { SynchronousQueryConfig } from 'lib/react-query';
import type { ListResponse } from 'lib/api';
import { apiClient } from 'lib/api';
import queryKeys from './queryKeys';
import queryString from 'query-string';
import type { GetLogsParams, Log } from '../types';
import { isAxiosError } from 'axios';

export const getLogs = async (
  { params }: { params: GetLogsParams },
  signal?: AbortSignal
): Promise<ListResponse<Log>> => {
  const res = await apiClient.get<ListResponse<Log>>(`/audit_logs`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  if (isAxiosError(res)) {
    const error = new Error('Get logs error');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseLogsOptions<TData> {
  params: GetLogsParams;
  config?: SynchronousQueryConfig<typeof getLogs, TData>;
}

export const useLogsList = <TData = ListResponse<Log>>({ params, config }: UseLogsOptions<TData>) => {
  return useQuery({
    gcTime: 0,
    staleTime: 0,
    ...config,
    queryFn: ({ signal }) => getLogs({ params }, signal),
    queryKey: queryKeys.getLogs(params),
  });
};
