import { useQuery } from '@tanstack/react-query';
import type { QueryKey } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { SynchronousQueryConfig } from 'lib/react-query';
import queryString from 'query-string';

interface RuleFlowMetricsParams {
  flow_id: number;
  rules_ids: number[];
}

interface RuleFlowMetricEntry {
  rule_id: number;
  rule_name: string;
  old_value: string;
  new_value: string;
}

interface RuleFlowMetricPromise {
  data: RuleFlowMetricEntry[];
  rules_count: number;
  metrics_count: number;
}

export const getRulesFlowMetrics = async (
  { params }: { params: RuleFlowMetricsParams },
  signal?: AbortSignal
): Promise<RuleFlowMetricPromise> => {
  const res = await apiClient.get<RuleFlowMetricPromise>(`/rules_flows_metrics`, {
    signal,
    params,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  return res.data;
};

interface UseRulesFlowMetricsOptions<TData> {
  config?: SynchronousQueryConfig<typeof getRulesFlowMetrics, TData>;
  params: RuleFlowMetricsParams;
}

export const useRulesFlowMetrics = <TData = RuleFlowMetricPromise>({
  config,
  params,
}: UseRulesFlowMetricsOptions<TData>) => {
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getRulesFlowMetrics({ params }, signal),
    queryKey: ['rules_flows_metrics', Object.values(params).flat().join()] as QueryKey,
  });
};
