import type { FC } from 'react';
import { Box, List, ListItem, ListItemText, styled, Typography } from '@mui/material';
import type { RuleData, RuleEntry } from '../types';

type RuleDetailsBlockProps = Pick<RuleEntry, 'action_types' | 'level' | 'team' | 'project' | 'flows'> &
  Pick<RuleData, 'facebook_ad_account_ids'>;

export const ListItemStyled = styled(ListItem)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

export const ListItemPrimaryStyled = styled(ListItemText)(() => ({
  m: 0,
  width: '50%',
}));
export const ListItemSecondaryStyled = styled(ListItemText)(() => ({
  width: '20%',
  m: 0,
  '& .MuiListItemText-secondary': { lineHeight: 1.25 },
}));

const RuleDetailsBlock: FC<RuleDetailsBlockProps> = ({
  action_types,
  facebook_ad_account_ids,
  flows,
  project,
  team,
  level,
}: RuleDetailsBlockProps) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Details
      </Typography>
      <Box sx={{ px: 2, background: 'rgba(249, 250, 251, 1)', borderRadius: 1.5 }}>
        <List sx={{ '& li': { mb: 1.5 } }}>
          <ListItemStyled disablePadding>
            <ListItemSecondaryStyled secondary="Action type:" />
            <ListItemPrimaryStyled primary={action_types.map(({ name }) => name).join(', ')} />
          </ListItemStyled>
          <ListItemStyled disablePadding>
            <ListItemSecondaryStyled secondary="Level:" />
            <ListItemPrimaryStyled primary={level} />
          </ListItemStyled>
          <ListItemStyled disablePadding>
            <ListItemSecondaryStyled secondary="Team:" />
            <ListItemPrimaryStyled primary={team} />
          </ListItemStyled>
          <ListItemStyled disablePadding>
            <ListItemSecondaryStyled secondary="Projects:" />
            <ListItemPrimaryStyled primary={project} />
          </ListItemStyled>
          <ListItemStyled disablePadding>
            <ListItemSecondaryStyled secondary="Flow:" />
            <ListItemPrimaryStyled primary={flows.map(({ name }) => name).join(', ')} />
          </ListItemStyled>
          <ListItemStyled disablePadding>
            <ListItemSecondaryStyled secondary="Account:" />
            <ListItemPrimaryStyled primary={facebook_ad_account_ids.join(', ')} />
          </ListItemStyled>
          <ListItemStyled disablePadding>
            <ListItemSecondaryStyled secondary="Author:" />
            {/*//TODO add author email*/}
            <ListItemPrimaryStyled primary="unavailable" />
          </ListItemStyled>
        </List>
      </Box>
    </Box>
  );
};

export default RuleDetailsBlock;
