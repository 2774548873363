export const WEEK_DAY_VALUES = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const WEEK_DAYS = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

export const SIMPLE_LOGS = [
  'archive_rule',
  'pause_rule',
  'unpause_rule',
  'unarchive_rule_as_active',
  'unarchive_rule_as_paused',
  'run_rule_manually',
  'create_flow',
  'delete_flow',
  'create_metric',
  'create_metric_group',
  'delete_metric_group',
  'duplicate_rule',
];
