import type { SelectChangeEvent, SxProps } from '@mui/material';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import { useCallback } from 'react';
import type { OptionValue } from 'types/shared';
import { Controller, useFormContext } from 'react-hook-form';
import getDay from '../../features/rules/utils/getDay';
import { WEEK_DAYS } from '../../constants';

type OnChangeHandler = (...event: any[]) => void;

interface FilterWeekDaysProps {
  param: number[];
  setParam: (val: number[]) => void;
  containerSx?: SxProps;
}

const weekdays = Object.entries(WEEK_DAYS).map(([key, value]) => ({ value: Number(key), label: value }));

const FilterWeekDays: FC<FilterWeekDaysProps> = ({ param, setParam, containerSx }) => {
  const { today: todayNumber, tomorrow: tomorrowNumber } = getDay();

  const { control } = useFormContext();

  const handleChange = useCallback(
    (e: SelectChangeEvent<OptionValue[]>, onChange: OnChangeHandler) => {
      const value = e.target.value as number[];

      const r = value.includes(0) ? value.filter((value) => value !== 0) : value;
      setParam(r);
      onChange(r);
    },
    [setParam]
  );

  return (
    <Box sx={{ display: 'flex', minWidth: 148, maxWidth: 200, ...containerSx }}>
      <FormControl sx={{ display: 'flex', flexGrow: 1 }}>
        <InputLabel size="small" id="filter-weekday">
          Week days
        </InputLabel>
        <Controller
          name={'weekday'}
          control={control}
          render={({ field }) => {
            const { onChange, value } = field;
            return (
              <Select
                {...field}
                value={value && param}
                labelId="filter-weekday"
                id="select-weekday"
                multiple
                onChange={(e) => {
                  handleChange(e, onChange);
                }}
                input={<OutlinedInput id="select-weekday" label="Week days" />}
                renderValue={(selected) => (
                  <Typography
                    sx={(t) => ({
                      display: 'block',
                      fontSize: t.typography.body2.fontSize,
                      textOverflow: 'ellipsis',
                      width: '100%',
                      overflow: 'hidden',
                    })}
                  >
                    {weekdays
                      .filter((day) => selected.includes(day.value))
                      .map((day) => day.label)
                      .join(', ')}
                  </Typography>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: 200,
                      marginTop: 2,
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem sx={{ px: 0 }} value={todayNumber} key={`WeekDay:${todayNumber}.Today`}>
                  <Checkbox checked={param.some((i) => i === todayNumber)} />
                  <ListItemText primary="Today" />
                </MenuItem>
                <MenuItem sx={{ px: 0 }} value={tomorrowNumber} key={`WeekDay:${tomorrowNumber}.Tomorrow`}>
                  <Checkbox checked={param.some((i) => i === tomorrowNumber)} />
                  <ListItemText primary="Tomorrow" />
                </MenuItem>
                <Divider />
                {weekdays.map((day) => (
                  <MenuItem sx={{ px: 0 }} value={day.value} key={`WeekDay:${day.value}.${day.label}`}>
                    <Checkbox checked={param.some((i) => i === day.value)} />
                    <ListItemText primary={day.label} />
                  </MenuItem>
                ))}
              </Select>
            );
          }}
        />
      </FormControl>
    </Box>
  );
};

export default FilterWeekDays;
