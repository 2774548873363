import { ExpandMoreOutlined } from '@mui/icons-material';
import { Button, List, ListItem, MenuList, Popover, MenuItem, Skeleton, CircularProgress } from '@mui/material';
import { useAnchorMenu } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import useGroups from '../hooks/useGroups';
import { FC, Suspense, useCallback } from 'react';
import Checkbox from 'components/common/Checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import { addMetricsToGroupsSchema } from '../validation/addMetricsToGroups.schema';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useAddMetricsToGroups } from '../api/addMetricToGroup';
import useChosenMetrics from '../hooks/useChosenMetrics';
import useAccessModify from '../hooks/useAccessModify';

const valuesIntoArray = (obj: Record<string | number, boolean>) => [
  ...Object.entries(obj)
    .filter((m) => m[1])
    .map((i) => parseInt(i[0])),
];

const ListGroupsSkeleton = () => {
  return (
    <List>
      <ListItem>
        <Skeleton width={'100%'} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
      </ListItem>
      <ListItem>
        <Skeleton width={'100%'} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
      </ListItem>
      <ListItem>
        <Skeleton width={'100%'} height={22} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
      </ListItem>
    </List>
  );
};

const ListGroups: FC<{ onCloseMenu: () => void; chosenMetrics: Record<string, boolean> }> = ({
  onCloseMenu,
  chosenMetrics,
}) => {
  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();
  const addMetricsToGroups = useAddMetricsToGroups({
    config: {
      onSuccess: () => {
        onShowAlert('Successfully added');
        onCloseMenu();
      },
      onError: (e) => {
        onShowInfoAlert(`${e.cause ? (e.cause?.message as string) : 'Internal Error'}`);
        onCloseMenu();
      },
    },
  });

  const { data: groups } = useGroups({
    name: undefined,
  });

  const formFields = useForm({
    resolver: yupResolver(addMetricsToGroupsSchema),
    defaultValues: {
      groups: {},
    },
  });

  const { watch, reset } = formFields;

  const chosenGroups = watch('groups');
  const isChosenGroups = Object.values(chosenGroups).includes(true);

  const handleOnClose = useCallback(() => {
    onCloseMenu();
    reset();
  }, [onCloseMenu, reset]);

  const handleAddToGroups = useCallback(() => {
    addMetricsToGroups.mutate({
      metrics_ids: valuesIntoArray(chosenMetrics),
      groups_ids: valuesIntoArray(chosenGroups),
    });
  }, [chosenMetrics, addMetricsToGroups]);

  return (
    <FormProvider {...formFields}>
      <List sx={{ py: 0, width: '100%' }}>
        <ListItem sx={{ p: 0, m: 0, width: '100%' }}>
          <MenuList sx={{ width: '100%' }}>
            {groups.groups.map(({ id, name }) => (
              <MenuItem
                key={`group:${id}.${name}`}
                sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}
              >
                <Checkbox
                  name={`groups.${id}`}
                  controlLabelSx={{ my: 0, mx: -1, p: 0, cursor: 'pointer' }}
                  label={name}
                  disabled={addMetricsToGroups.isPending}
                />
              </MenuItem>
            ))}
          </MenuList>
        </ListItem>
        <ListItem sx={{ px: 1, m: 0, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="text" size="small" onClick={handleOnClose} disabled={addMetricsToGroups.isPending}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={!isChosenGroups || addMetricsToGroups.isPending}
            onClick={handleAddToGroups}
            startIcon={addMetricsToGroups.isPending && <CircularProgress color="inherit" size={18} />}
          >
            Add
          </Button>
        </ListItem>
      </List>
    </FormProvider>
  );
};

const MetricsAddToGroups = () => {
  const { accessMetrics } = useAccessModify();
  const { anchor, isOpen, handleCloseMenu, handleOpenMenu } = useAnchorMenu();
  const { isChosenMetrics, chosenMetrics, onResetMetrics } = useChosenMetrics();
  const onCloseMenu = () => {
    onResetMetrics();
    handleCloseMenu();
  };

  return accessMetrics.addToGroup ? (
    <>
      <Button
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          handleOpenMenu(e);
        }}
        endIcon={
          <ExpandMoreOutlined
            sx={(t) => ({
              fontSize: 14,
              color: t.palette.primary.main,
              transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
              transition: 'transform .1s',
            })}
          />
        }
        sx={{ lineHeight: '14px', visibility: isChosenMetrics ? 'visible' : 'hidden' }}
      >
        Add to group
      </Button>
      <Popover
        slotProps={{
          paper: {
            sx: { width: 220, py: 1 },
          },
        }}
        sx={{
          '& .MuiBackdrop-root': {
            opacity: '0 !important',
            transition: 'none !important',
          },
        }}
        anchorEl={anchor}
        open={isOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Suspense fallback={<ListGroupsSkeleton />}>
          <ListGroups onCloseMenu={onCloseMenu} chosenMetrics={chosenMetrics} />
        </Suspense>
      </Popover>
    </>
  ) : null;
};

export default MetricsAddToGroups;
