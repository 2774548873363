import { Box, Typography, styled } from "@mui/material";
import BgCoffee from "assets/bg-coffee.webp";

export const GradientBox = styled(Box)`
  padding: 16px 24px;
  background: url(${BgCoffee}) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-radius: 8px;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    justify-content: space-between;
    max-width: 1200px;
    flex: 2;
  }

  ${({ theme: t }) => t.breakpoints.down('md')} {
    justify-content: flex-start;
  }
`;

export const TitleStyled = styled(Typography)`
  color: #fff;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    width: min-content;
    font-size: 64px;
    line-height: 1.25;
  }

  ${({ theme: t }) => t.breakpoints.down('md')} {
    width: 100%;
    font-size: 42px;
    line-height: 1;
  }
`;

export const FormBox = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme: t }) => t.breakpoints.up('md')} {
    padding: 16px 40px;
  }

  ${({ theme: t }) => t.breakpoints.down('md')} {
    position: absolute;
    bottom: 24px;
    left: 24px;
    margin-right: 24px;
    max-width: 600px;
    padding: 40px 20px;
    border-radius: 12px;
    background-color: rgba(255,255,255,0.8)
  }
`;
