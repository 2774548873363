import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryConfig } from 'lib/react-query';
import { MetricParams, GroupsResponse } from './types';
import { apiClient } from 'lib/api';
import queryKeys from './queryKeys';
import queryString from 'query-string';

export const getGroups = async (
  { params }: { params: MetricParams },
  signal?: AbortSignal
): Promise<GroupsResponse> => {
  const res = await apiClient.get<GroupsResponse>(`/groups`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseGroupsOptions<TData> {
  params: MetricParams;
  config?: QueryConfig<typeof getGroups, TData>;
}

export const useGroupsList = <TData = GroupsResponse>({ params, config }: UseGroupsOptions<TData>) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getGroups({ params }, signal),
    queryKey: queryKeys.byFilterValues('groups', params),
  });
};
