import { Suspense, type FC } from "react";
import { Titled } from "react-titled";
import ProgressSkeleton from "components/layout/ProgressSkeleton";
import { Rules } from "features/rules";

const RulesPage: FC = () => {
  return (
    <>
      <Titled title={(title) => `AutoRules | ${title}`} />
      <Suspense fallback={<ProgressSkeleton />}>
        <Rules />
      </Suspense>
    </>
  );
};

export default RulesPage;
