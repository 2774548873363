import type { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface RuleEditorContainerProps {
  children: ReactNode;
}
const RuleEditorContainer: FC<RuleEditorContainerProps> = ({ children }) => {
  return (
    <Box
      sx={{
        border: `1px solid rgba(217, 217, 217, 1)`,
        borderRadius: 1.5,
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  );
};

export default RuleEditorContainer;
