import { type FC, Suspense } from 'react';
import { Titled } from 'react-titled';
import ProgressSkeleton from '../../components/layout/ProgressSkeleton';
import { Logs } from '../../features/logs';

const LogsPage: FC = () => {
  return (
    <>
      <Titled title={(title) => `AutoRules | ${title}`} />
      <Suspense fallback={<ProgressSkeleton />}>
        <Logs />
      </Suspense>
    </>
  );
};

export default LogsPage;
