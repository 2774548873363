import { useRuleGroups } from '../api';
import Select from '../../../components/common/Select';

const RuleGroupSelect = () => {
  const { data: ruleGroups } = useRuleGroups({
    config: {
      select: (data) => data.map(({ name, id }) => ({ value: id, label: name })),
    },
  });
  return <Select name="groupId" label="Rule group" selectAll={false} options={ruleGroups} />;
};

export default RuleGroupSelect;
