import { Box, Skeleton } from '@mui/material';

const RuleFormElementSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Skeleton width={90} height={18} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
      <Skeleton width={'100%'} height={40} sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }} />
    </Box>
  );
};

export default RuleFormElementSkeleton;
