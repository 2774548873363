import {
  Box,
  Button,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import { Suspense, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { OpenInNew as OpenInNewIcon, Close as CloseIcon } from '@mui/icons-material';
import { isEmpty } from 'lodash-es';
import { useMetricDetails } from '../api/getMetricDetails';
import MetricDetailsSkeleton from './MetricDetailsSkeleton';
import { MetricDrawer } from './MetricsComponents';
import useMetricRulesColors from '../hooks/useMetricRulesColors';
import type { RuleStatusValue } from 'features/rules';

const RuleStatusItem: FC<{ status: RuleStatusValue }> = ({ status }) => {
  const { colorSchema } = useMetricRulesColors({ status });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 24,
          height: 15,
          borderRadius: 12,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: colorSchema.borderColor,
          backgroundColor: colorSchema.backgroundColor,
          '&:before': (['paused', 'archived'] as RuleStatusValue[]).includes(status)
            ? {
                content: "''",
                width: 4,
                height: 4,
                backgroundColor: colorSchema.borderColor,
              }
            : {},
        }}
      ></Box>
      <Typography variant="body2" color={colorSchema.textColor} textTransform={'capitalize'}>
        {status}
      </Typography>
    </Box>
  );
};

const MetricDetailsContent: FC<{ id: number; onClose: () => void }> = ({ id, onClose }) => {
  if (!id) return null;
  const { data } = useMetricDetails({ id });

  return (
    <Box sx={{ display: 'flex', flex: 1, flexGrow: 1, flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
        <Typography variant="h3">{data.name}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {!isEmpty(data.description) && (
        <>
          <Box sx={{ mb: 4 }}>
            <Typography variant="body2" fontWeight="500" sx={{ mb: 2 }}>
              Description
            </Typography>
            <Typography variant="body2">{data.description}</Typography>
          </Box>
          <Divider sx={{ mb: 4 }} />
        </>
      )}
      <Box sx={{ mb: 4 }}>
        <Typography variant="body2" fontWeight="500" sx={{ mb: 2 }}>
          Usage in rules
        </Typography>
        <Table>
          <TableBody>
            {data.usage_in_rules.map((rule) => (
              <TableRow key={`${id}:${rule.status}.${rule.quantity_rules}`} sx={{ td: { p: 0 } }}>
                <TableCell align="left">
                  <RuleStatusItem status={rule.status.toLowerCase() as RuleStatusValue} />
                </TableCell>
                <TableCell align="left">{rule.quantity_rules} rules</TableCell>
                <TableCell align="right">
                  <Box sx={{ display: 'inline-block' }}>
                    <Tooltip title="Coming soon ..." placement="left" arrow>
                      <Box>
                        <Button disabled variant="text" color="primary" size="small" endIcon={<OpenInNewIcon />}>
                          View
                        </Button>
                      </Box>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

const MetricDetails = () => {
  const { setValue } = useFormContext();
  const idMetricDetails = useWatch({ name: 'id_metric_details' });

  const handleOnClose = useCallback(() => {
    setValue('id_metric_details', undefined);
  }, [setValue]);

  return (
    <MetricDrawer open={!!idMetricDetails} onClose={handleOnClose}>
      {idMetricDetails && (
        <Suspense fallback={<MetricDetailsSkeleton />}>
          <MetricDetailsContent id={idMetricDetails} onClose={handleOnClose} />
        </Suspense>
      )}
    </MetricDrawer>
  );
};

export default MetricDetails;
