import { Box, Skeleton } from '@mui/material';

const FilterValuesSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', mb: 2, gap: 1 }}>
      <Skeleton width={100} height={18} sx={{ transform: 'translateY(0)', borderRadius: '8px' }} />
      {[...Array(8).keys()].map((i) => (
        <Skeleton key={i} width={70} height={24} sx={{ transform: 'translateY(0)', borderRadius: '8px' }} />
      ))}
    </Box>
  );
};

export default FilterValuesSkeleton;
