import type { Theme } from '@mui/material';
import { Typography, styled } from '@mui/material';

const TitlePageStyles = styled(Typography)(
  ({ theme: t }: { theme: Theme }) => `
    line-height: 24px;
    font-size: ${t.typography.h3.fontSize}
`
);

export default TitlePageStyles;
