import type { RulesListParams } from '../types';
import type { QueryKey } from '@tanstack/react-query';
import { omit } from 'lodash-es';

export default {
  byFilterValues: (path: string, params: Partial<RulesListParams>) => {
    return [
      path,
      ...Object.keys(params)
        .sort()
        .map((k) => params[k as keyof Partial<RulesListParams>]),
    ] as QueryKey;
  },
  byFilterValuesForPlatforms: (path: string, params: Partial<RulesListParams>) => {
    return [
      path,
      ...Object.keys(omit(params, ['desc', 'order_by']))
        .sort()
        .map((k) => params[k as keyof Partial<RulesListParams>]),
    ] as QueryKey;
  },
  rule: (id: number) => ['rule-details', id],
  flows: (projectId: number) => ['flows', projectId] as QueryKey,
};
