import { useSuspenseQuery } from '@tanstack/react-query';
import type { QueryConfig } from 'lib/react-query';
import type { ListResponse } from 'lib/api';
import { apiClient } from 'lib/api';
import queryKeys from './queryKeys';
import queryString from 'query-string';
import type { Log } from '../types';
import type { LogActionType } from '../types';

export const getLogsActionTypes = async (signal?: AbortSignal): Promise<LogActionType[]> => {
  const res = await apiClient.get<LogActionType[]>(`/audit_logs/action_types`, {
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseLogsActionTypesOptions<TData> {
  config?: QueryConfig<typeof getLogsActionTypes, TData>;
}

export const useLogsActionTypes = <TData = LogActionType[]>({ config }: UseLogsActionTypesOptions<TData> = {}) => {
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getLogsActionTypes(signal),
    queryKey: queryKeys.getLogsActionTypes,
  });
};
