import { Typography } from '@mui/material';

const HighlightSearchTerm = ({
  originalText,
  searchTerm,
  replaceTerm,
}: {
  originalText: string;
  searchTerm: string;
  replaceTerm: string;
}) => {
  if (!searchTerm)
    return (
      <Typography component="span" variant="body2" sx={{ wordBreak: 'break-all', pr: 2, m: 0, whiteSpace: 'pre-wrap' }}>
        {originalText}
      </Typography>
    );

  // special character must be properly escaped in the regular expression
  const escapedString = searchTerm.replace(/[\W_]/g, '\\$&'); // $& means the whole matched string
  const highlightedText = originalText.replace(new RegExp(escapedString, 'gi'), (match) => {
    return `<span style="background-color: rgba(209, 221, 253, 1); color: rgba(61, 90, 254, 1)">${replaceTerm || match}</span>`;
  });

  return (
    <Typography
      component="span"
      variant="body2"
      sx={{ wordBreak: 'break-all', pr: 2, m: 0, whiteSpace: 'pre-wrap' }}
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    ></Typography>
  );
};

export default HighlightSearchTerm;
