import useListParams from 'hooks/useListParams';
import useChosenRules from './useChosenRules';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useEditRules } from '../api/editRules';
import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { closeSnackbar } from 'notistack';
import useRulesQueryParams from './useRulesQueryParams';
import { ArchiveStatusValue, RuleStatusValue } from '..';

const useBulkArchive = ({
  status,
  onSuccess,
  onError,
}: { status?: ArchiveStatusValue; onSuccess?: () => void; onError?: () => void } = {}) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { reset } = useFormContext();
  const { tab } = useListParams();
  const { rulesIds, isChosenRules } = useChosenRules();
  const rulesQueryParams = useRulesQueryParams();

  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();

  const editRulesMutation = useEditRules({
    config: {
      onSuccess: (_, variables) => {
        onSuccess?.();
        closeSnackbar();
        const rulesArchivedMessage = `${(variables.rules_ids as number[]).length > 1 ? 'Rules' : 'Rule'} ${variables?.status}`;
        onShowAlert(
          variables?.status === 'archived'
            ? rulesArchivedMessage
            : `${(variables?.status as RuleStatusValue) === 'archived' ? 'Rules archived' : 'Rules unarchived as'} ${variables?.status}`,
          {
            delayAlert: 5000,
            onUndo:
              variables?.status !== tab && tab !== 'all'
                ? async () => {
                    await editRulesMutation.mutate({
                      ...variables,
                      status: tab as RuleStatusValue,
                    });
                    onShowInfoAlert(
                      variables?.status === 'archived'
                        ? `${(variables?.status as RuleStatusValue) === 'archived' ? `Unarchiving rules` : 'Archiving rules'}`
                        : `Archiving rules`,
                      {
                        isPending: true,
                        delayAlert: 10000,
                      }
                    );
                  }
                : undefined,
          }
        );
      },
      onError: (err) => {
        closeSnackbar();
        reset();
        onShowInfoAlert(err.cause?.message || 'Error');
        setErrorMessage(err.cause?.message);
        onError?.();
      },
    },
  });

  const onArchiveManually = useCallback(() => {
    closeSnackbar();
    editRulesMutation.mutate({
      status: tab === 'archived' ? status : 'archived', // toggle between archived and unarchive
      ...rulesQueryParams,
    });
  }, [closeSnackbar, rulesQueryParams, editRulesMutation, status, tab]);

  return {
    errorMessage,
    isChosenRules,
    isPending: editRulesMutation.isPending,
    isSuccess: editRulesMutation.isSuccess,
    rulesIds,
    onArchiveManually,
  };
};

export default useBulkArchive;
