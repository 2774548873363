import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useChosenRules from '../hooks/useChosenRules';
import useFilterQueryValues from '../hooks/useFilterQueryValues';
import type { BulkDuplicatePayload } from '../api';
import { useBulkDuplicate, useCreateFlow, useRulesList } from '../api';
import { isEmpty, omit } from 'lodash-es';
import type { NotificationChannel, Rule, RuleStatusValue } from '../types';
import type { ModalProps } from 'types/shared';
import type { FC } from 'react';
import { Suspense, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import bulkDuplicateSchema from '../validation/bulkDuplicate.schema';
import { InfoOutlined } from '@mui/icons-material';
import { RuleStatusParameters } from './RulesComponents';
import { DiscardChangesDialog } from '..';
import { ProjectSelect } from 'features/projects';
import ChipInput from 'components/common/ChipInput';
import Input from 'components/common/Input';
import { observer } from 'mobx-react-lite';
import bulkDuplicateStore from '../stores/bulkDuplicateStore';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import { useModal } from 'hooks';
import { flowsModify } from '../validation/flowsModify.schema';
import RuleUIFormActionBlock from './RuleUIFormActionBlock';
import { queryClient } from 'lib/react-query';
import { toJS } from 'mobx';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import useListParams from 'hooks/useListParams';
import ReplaceName from './bulk/ReplaceName';
import SuspenseFallbackSkeleton from './bulk/SuspenseFallbackSkeleton';
import FlowsAutocomplete from './bulk/FlowsAutocomplete';
import NotificationChannelsSelect from './bulk/NotificationChannelsSelect';
import FlowMetrics from './bulk/FlowMetrics';
import RulesList from './bulk/RulesList';
import NoSelectedRules from './bulk/NoSelectedRules';

interface FormData {
  name: string | undefined;
  replaceWith: string | undefined;
  project: number | null;
  flow: string;
  description: string | null;
  notificationChannels: string;
  facebookAdAccountIds: any[];
  status: RuleStatusValue;
}

export const AddFlowForm: FC<{ close: () => void; project: string }> = ({ close, project }) => {
  const { onShowAlert } = useEnqueueSnackbar();

  const formMethods = useForm({
    resolver: yupResolver(flowsModify),
    defaultValues: {
      name: '',
    },
  });

  const { reset, setError } = formMethods;

  const createFlow = useCreateFlow({
    config: {
      onSuccess: () => {
        onShowAlert('New flow created');
        reset();
        close();
      },
      onError: (err) => {
        setError('name', { message: err.cause?.message }, { shouldFocus: true });
      },
    },
  });

  const onSubmit = useCallback(
    ({ name }: { name: string }) => {
      createFlow.mutate({
        project_id: parseInt(project),
        name,
      });
    },
    [createFlow, project]
  );

  return (
    <FormProvider {...formMethods}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Input name="name" label="Flow name" required />
        </Box>
        <Button
          startIcon={createFlow.isPending ? <CircularProgress size={16} color="inherit" /> : null}
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          disabled={createFlow.isPending}
        >
          Create
        </Button>
      </Box>
    </FormProvider>
  );
};

const BulkDuplicateForm: FC<Pick<ModalProps, 'close'>> = observer(({ close }) => {
  const { onShowAlert, onShowErrorAlert } = useEnqueueSnackbar();
  const { isOpen: isOpenDiscard, open: openDiscard, close: closeDiscard } = useModal();
  const { rulesIds, isAllRulesChosen } = useChosenRules();
  const params = useFilterQueryValues();
  const navigate = useNavigate();

  const { data: rules } = useRulesList<Rule[]>({
    params: omit(params, ['paginationRelatedParams']),
    config: {
      select: ({ data }) => {
        const filteredData = isAllRulesChosen ? data : data.filter(({ id }) => rulesIds.includes(id));
        return filteredData;
      },
    },
  });

  const storeRules = toJS(bulkDuplicateStore.list);
  const isRenamingApplied = bulkDuplicateStore.isRenamingApplied;

  useEffect(() => {
    !isEmpty(rules) && bulkDuplicateStore.initialRules(rules);
  }, [rules]);

  const formMethods = useForm<FormData>({
    defaultValues: {
      name: '',
      replaceWith: '',
      project: params.project_id ?? null,
      flow: '',
      description: null,
      notificationChannels: '',
      facebookAdAccountIds: [],
      status: 'paused',
    },
    // @ts-expect-error
    resolver: yupResolver(bulkDuplicateSchema),
    context: { isRenamingApplied },
  });

  const {
    reset,
    clearErrors,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = formMethods;

  const projectField = watch('project');

  useEffect(() => {
    if (projectField) {
      setValue('flow', '', { shouldValidate: false });
    }
  }, [projectField]);

  useEffect(() => {
    if (isRenamingApplied && !!errors?.name) {
      clearErrors('name');
    }
  }, [isRenamingApplied]);

  const duplicate = useBulkDuplicate({
    config: {
      onSuccess: (_, variables: BulkDuplicatePayload[]) => {
        const { flow_id, project_id, status } = variables[0];
        navigate({
          pathname: '/rules',
          search: createSearchParams({
            listId: `${flow_id}`,
            project: `${project_id}`,
            statusState: `${status}`,
            tab: `${status}`,
            platform: params.platform_id ?? 1,
          } as {}).toString(),
        });
        reset();
        close();
        onShowAlert('Success');
      },
      onError: (err) => {
        onShowErrorAlert(`${(err?.message || err?.cause?.message) ?? 'Error'}`);
      },
    },
  });

  const onDiscard = useCallback(() => {
    reset();
    closeDiscard();
    close();
  }, [reset, close, closeDiscard]);

  const onCloseDiscard = useCallback(() => {
    closeDiscard();
  }, [closeDiscard]);

  const onSubmit = ({
    status,
    flow: flow_id,
    notificationChannels,
    project: project_id,
    facebookAdAccountIds: facebook_ad_account_ids,
  }: FormData) => {
    if (!isRenamingApplied) {
      setValue('name', '', { shouldValidate: true });
      return;
    }

    const cachedNotificationData = queryClient.getQueriesData<NotificationChannel[]>({
      queryKey: ['notification_channels'],
    })[0][1];

    const notificationChanel = cachedNotificationData?.find((n) => n.id === notificationChannels);

    const payload: BulkDuplicatePayload[] = storeRules.map(({ id, name }) => ({
      id,
      name,
      status: status as RuleStatusValue,
      flow_id: parseInt(flow_id),
      project_id: project_id!,
      facebook_ad_account_ids,
      notification_channels: notificationChanel ? [notificationChanel] : [],
    }));

    duplicate.mutate(payload);
  };

  return (
    <Box
      sx={{ display: 'flex', flexGrow: 1, height: '100%', minWidth: 990, overflow: 'auto', flexWrap: 'nowrap', pt: 2 }}
    >
      {rules.length ? (
        <FormProvider {...formMethods}>
          <Box
            sx={(t) => ({
              display: 'flex',
              width: '100%',
              p: 0,
              flex: 1,
              borderRight: `1px solid ${t.palette.divider}`,
            })}
          >
            <TableContainer sx={{ width: '100%', p: 0, flex: 1, pr: 5 }}>
              <Table sx={{ '& th': { px: 0 }, '& td': { py: 0, px: 1 } }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell component="th" colSpan={2} sx={{ '&': { py: 0, verticalAlign: 'top' } }}>
                      Rules to be created
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <RulesList mode="duplicate" />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              pt: 0,
              pb: 16,
              pr: 8,
              pl: 5,
              flex: 1,
              width: '35%',
              maxWidth: 550,
              overflow: 'hidden',
              overflowY: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Parameters to edit
              </Typography>
              <Typography variant="caption" sx={(t) => ({ color: t.palette.text.secondary })}>
                <InfoOutlined
                  sx={(t) => ({
                    fontSize: 18,
                    color: t.palette.indigo.main,
                    mr: 0.5,
                    mb: -0.5,
                  })}
                />
                There cannot be two rules with the same name and conditions. Make changes to save new rules.
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
              <RuleStatusParameters name="status" sx={{ flexDirection: 'row', justifyContent: 'space-between' }} />
              <ReplaceName />
              <Suspense fallback={<SuspenseFallbackSkeleton />}>
                <ProjectSelect onlyUsersProjects name="project" label="Project" required filter={(p) => !!p.value} />
              </Suspense>
              <FlowsAutocomplete name="flow" projectFieldName="project" />
              <ChipInput name="facebookAdAccountIds" label="Account" required />
              <Suspense fallback={<SuspenseFallbackSkeleton />}>
                <NotificationChannelsSelect name="notificationChannels" label="Notification channel" required />
              </Suspense>
              <FlowMetrics />
            </Box>
          </Box>
          <DiscardChangesDialog isOpen={isOpenDiscard} onClose={onCloseDiscard} onDiscard={onDiscard} />
          <RuleUIFormActionBlock
            mode={['update']}
            project={{ id: projectField!, name: '' }}
            isPending={duplicate.isPending}
            onDiscard={openDiscard}
            hasChanges={true}
            onSave={handleSubmit(onSubmit)}
            submitTitle="Duplicate"
          />
        </FormProvider>
      ) : (
        <NoSelectedRules />
      )}
    </Box>
  );
});

export default BulkDuplicateForm;
