import { Box, Divider, Typography } from '@mui/material';
import type { FC } from 'react';
interface RuleMetricGroupLogProps {
  itemName: string;
  state: {
    groups: { id: number; name: string }[];
  };
}

const RuleMetricGroupLog: FC<RuleMetricGroupLogProps> = ({ state, itemName }) => {
  return (
    <Box sx={{ pt: 2 }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          Metric
        </Typography>
        <Typography>{itemName}</Typography>
        <Divider sx={{ mt: 2 }} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          Groups
        </Typography>
        {state.groups.map(({ name }) => (
          <>
            <Typography key={name}>{name}</Typography>
            <Divider sx={{ mt: 2 }} />
          </>
        ))}
      </Box>
    </Box>
  );
};

export default RuleMetricGroupLog;
