import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type { QueryConfig, SynchronousQueryConfig } from 'lib/react-query';
import type { PlatformsResponse } from './types';
import { apiClient } from 'lib/api';
import type { RulesListParams } from '../types';
import queryKeys from './queryKeys';
import queryString from 'query-string';
import { omit } from 'lodash-es';

type PlatformsParams = Omit<Partial<RulesListParams>, 'limit' | 'offset'>;

export const getPlatforms = async (
  { params }: { params: PlatformsParams },
  signal?: AbortSignal
): Promise<PlatformsResponse> => {
  const res = await apiClient.get<PlatformsResponse>(`/platforms`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UsePlatformsOptions<TData> {
  params: PlatformsParams;
  config?: QueryConfig<typeof getPlatforms, TData>;
}

interface UseSyncPlatformsOptions<TData> {
  params: PlatformsParams;
  config?: SynchronousQueryConfig<typeof getPlatforms, TData>;
}

export const usePlatforms = <TData = PlatformsResponse>({ params, config }: UsePlatformsOptions<TData>) => {
  const omittedParams = omit({ ...params }, [
    'limit',
    'offset',
    'platform_id',
    'list_id',
    'order_by',
    'desc',
    'paginationRelatedParams',
  ]);
  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getPlatforms({ params: omittedParams }, signal),
    queryKey: queryKeys.byFilterValuesForPlatforms('platforms', omittedParams),
  });
};

export const useSyncPlatforms = <TData = PlatformsResponse>({ params, config }: UseSyncPlatformsOptions<TData>) => {
  const omittedParams = omit({ ...params }, [
    'limit',
    'offset',
    'platform_id',
    'list_id',
    'order_by',
    'desc',
    'paginationRelatedParams',
  ]);
  return useQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getPlatforms({ params: omittedParams }, signal),
    queryKey: queryKeys.byFilterValuesForPlatforms('platforms', omittedParams),
  });
};
