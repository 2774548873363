import * as yup from 'yup';
import { timeFormatValidation } from '../../../utils/timeFormatValidation';
import { uniqueValidation } from '../../../utils/uniqueArray';
import { intervalHoursValidation } from '../../../utils/intervalHoursValidation';
import { intervalMinutesValidation } from '../../../utils/intervalMinutesValidation';

export const timeTableTimeSchema = yup.object().shape({
  time: yup.string().required().test('timeFormatValidation', timeFormatValidation).trim(),
});

export const timeTableUniqueTimeSchema = yup
  .array(yup.string().required())
  .required()
  .test('uniqueTimeValidation', uniqueValidation);

export const timeTableDaySchema = yup.array().of(yup.array(yup.string().required()).min(1));

export const intervalSchema = yup.object().shape({
  hours: yup
    .array(yup.number())
    .required()
    // .transform((val: string[]) => val.map(Number))
    .test('uniqueValidation', uniqueValidation)
    .test('hoursValidation', intervalHoursValidation),
  minutes: yup
    .array(yup.number())
    .required()
    .test('uniqueValidation', uniqueValidation)
    .test('minuteValidation', intervalMinutesValidation),
});
