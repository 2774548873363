import { Box, Grid, IconButton, List, Typography } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { useModal } from 'hooks';
import { useCallback, useState } from 'react';
import { getPersistedData, persistData } from 'utils/persist';
import useListParams from 'hooks/useListParams';
import GroupItem from './GroupItem';
import ListItemStyled from 'components/common/ListItemStyled';
import { AddGroup, DeleteGroup, EditGroup } from './GroupModify';
import useGroups from '../hooks/useGroups';
import type { GroupEntry } from '../api/types';
import useAccessModify from '../hooks/useAccessModify';

const Groups = () => {
  const { accessMetrics } = useAccessModify();
  const [deletionGroup, setDeletionGroup] = useState<GroupEntry | null>(null);
  const [editingGroup, setEditingGroup] = useState<GroupEntry | null>(null);
  const { groupId, setGroupId } = useListParams();
  const { data } = useGroups({ name: undefined });

  const { toggle: togglePlatforms, isOpen: isOpenPlatforms } = useModal(
    getPersistedData('directoryFilterVisibility') ?? true
  );

  const handleToggle = useCallback(() => {
    persistData('directoryFilterVisibility', !isOpenPlatforms);
    togglePlatforms();
  }, [persistData, togglePlatforms]);

  const handleAllMetrics = useCallback(() => {
    setGroupId(undefined);
  }, [setGroupId]);

  const toggleDeleteGroup = useCallback(
    (id?: number) => {
      setDeletionGroup((prev) => {
        if (!id || prev) return null;
        return data.groups.find((g) => g.id === id) || null;
      });
    },
    [setDeletionGroup, data]
  );

  const toggleEditGroup = useCallback(
    (id?: number) => {
      setEditingGroup((prev) => {
        if (!id || prev) return null;
        return data.groups.find((g) => g.id === id) || null;
      });
    },
    [setEditingGroup, data]
  );

  return (
    <Grid
      item
      sx={(t) => ({
        flex: 1,
        position: 'relative',
        maxWidth: !isOpenPlatforms ? 30 : 230,
        transition: 'all 0.2s',
        willChange: 'auto',
        borderTopLeftRadius: 12,
        borderTop: `1px solid ${t.palette.grey[300]}`,
        borderLeft: `1px solid ${t.palette.grey[300]}`,
        overflow: 'hidden',
      })}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 230,
          left: 0,
          top: 0,
          py: 1.5,
          px: !isOpenPlatforms ? 0 : 1.5,
          overflow: 'hidden',
          overflowY: 'auto',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
          {isOpenPlatforms && (
            <Typography sx={(t) => ({ fontSize: t.typography.body2.fontSize, fontWeight: 500 })}>Directory</Typography>
          )}
          <IconButton onClick={handleToggle}>
            {!isOpenPlatforms ? <MenuIcon sx={{ fontSize: 18 }} /> : <MenuOpenIcon sx={{ fontSize: 22 }} />}
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            visibility: isOpenPlatforms ? 'visible' : 'hidden',
          }}
        >
          <AddGroup />
          <List disablePadding>
            <ListItemStyled
              selected={!groupId}
              name={'All custom metrics'}
              quantity={data.all_metrics}
              onClick={handleAllMetrics}
              sx={{ '&.MuiListItemButton-root': { pl: accessMetrics.accessModify ? 3 : 1 } }}
            />
            {data.groups.map((item) => (
              <GroupItem
                key={`flow:${item.id}.${item.name}.${item.quantity}`}
                id={item.id}
                name={item.name}
                quantity={item.quantity}
                toggleDeleteGroup={() => {
                  toggleDeleteGroup(item.id);
                }}
                toggleEditGroup={() => {
                  toggleEditGroup(item.id);
                }}
                isAccessModify={accessMetrics.accessModify}
              />
            ))}
          </List>
        </Box>
      </Box>
      {deletionGroup && (
        <DeleteGroup
          isOpen={!!deletionGroup}
          close={toggleDeleteGroup}
          id={deletionGroup.id}
          name={deletionGroup.name}
          open={toggleEditGroup}
        />
      )}
      {editingGroup && (
        <EditGroup id={editingGroup.id} name={editingGroup.name} isOpen={!!editingGroup} close={toggleEditGroup} />
      )}
    </Grid>
  );
};

export default Groups;
