import type { QueryKey } from '@tanstack/react-query';
import type { ListResponse, PaginationParams, SortingParams } from 'lib/api';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { FilteringFields, UserEntry } from './types';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { UsersListResponse } from './types';

export interface GetUsersListOptions {
  params: PaginationParams & Partial<SortingParams> & Partial<FilteringFields>;
}

export const getUsersList = async (
  { params }: GetUsersListOptions,
  signal?: AbortSignal
): Promise<ListResponse<UserEntry>> => {
  const res = await apiClient.get<UsersListResponse>(`/users`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return res.data;
};

interface UseUsersListOptions {
  config?: QueryConfig<typeof getUsersList>;
  params: GetUsersListOptions['params'];
}

export const useUsersList = ({ config, params }: UseUsersListOptions) => {
  const { order_by, offset, limit, q, role, desc } = params;

  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getUsersList({ params }, signal),
    queryKey: ['users', offset, limit, q, role, order_by, desc] as QueryKey,
  });
};
