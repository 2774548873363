import { TableCell, TableHead, TableRow, Typography, styled } from '@mui/material';
import Checkbox from 'components/common/Checkbox';
import { isEmpty, mapValues } from 'lodash-es';
import { useEffect, type FC, useCallback } from 'react';
import useProjectConditions from '../hooks/useProjectConditions';
import { TableCellStickyStyled } from './MetricsComponents';
import useChosenMetrics from '../hooks/useChosenMetrics';

const AllMetricsCheckbox = () => {
  const { chosenMetrics, onSetMetrics, onSetIsAllMetrics } = useChosenMetrics();

  useEffect(() => {
    const allValues = Object.values({ ...chosenMetrics });
    const isAllMetrics = !isEmpty(allValues) && !allValues.some((i) => !Boolean(i));
    onSetIsAllMetrics(isAllMetrics);
  }, [chosenMetrics]);

  const handleOnChange = useCallback(
    (checked: boolean) => {
      const allMetricsValue = mapValues(chosenMetrics, () => checked);
      onSetMetrics(allMetricsValue);
    },
    [mapValues, onSetMetrics, chosenMetrics]
  );

  return <Checkbox name={`all_metrics`} controlLabelSx={{ '&': { p: 0, m: 0 } }} onChange={handleOnChange} />;
};

const MetricsTableHead: FC<{ columns: string[] }> = ({ columns }) => {
  const { isNotAssigned } = useProjectConditions();

  return (
    <TableHead sx={{ position: 'relative', zIndex: 6 }}>
      <TableRow sx={{ th: { height: 64, maxHeight: 64, px: 1 } }}>
        <TableCellStickyStyled sx={{ '&': { left: 0, width: 52, maxWidth: 52 } }}>
          <AllMetricsCheckbox />
        </TableCellStickyStyled>
        <TableCellStickyStyled align="left" sx={{ '&': { left: 54, width: 280, minWidth: 280, maxWidth: 280 } }}>
          <Typography variant="body2" sx={(t) => ({ fontWeight: t.typography.fontWeightMedium })}>
            Metric
          </Typography>
        </TableCellStickyStyled>
        {columns && columns.length > 1 ? (
          columns.map((name) => (
            <TableCell key={name} align="left" sx={{ minWidth: 42 }}>
              <Typography variant="body2" sx={(t) => ({ fontWeight: t.typography.fontWeightMedium })}>
                {name}
              </Typography>
            </TableCell>
          ))
        ) : (
          <TableCell align="left" sx={{ minWidth: 42 }}>
            {!isNotAssigned ? (
              <Typography variant="body2" sx={(t) => ({ fontWeight: t.typography.fontWeightMedium })}>
                Value
              </Typography>
            ) : null}
          </TableCell>
        )}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default MetricsTableHead;
