import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useEmergencyContext } from '..';
import { StopCircleOutlined } from '@mui/icons-material';

interface EmergencyLayoutProps {
  children: ReactNode;
}

const EmergencyLayout: FC<EmergencyLayoutProps> = ({ children }) => {
  const { isEmergency } = useEmergencyContext();

  return (
    <Box
      sx={(t) => ({
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        height: '100vh',
        zIndex: 1,
        gap: 0.75,
        p: 0.75,
        background: isEmergency
          ? t.palette.emergency.light
          : 'linear-gradient(113.18deg, #D1DDFD 0.62%, #D6CEF4 100.46%)',
        [t.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      })}
    >
      {isEmergency && (
        <Box
          sx={(t) => {
            const cornerBasicSx = {
              content: '""',
              position: 'absolute',
              top: 0,
              width: 8,
              height: 8,
              backgroundColor: '#FFDBE2',
              transform: 'scale(1)',
            };
            return {
              position: 'absolute',
              zIndex: 8,
              top: 6,
              left: 0,
              right: 0,
              py: 0.75,
              px: 1.5,
              gap: 0.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 'fit-content',
              marginX: 'auto',
              backgroundColor: t.palette.emergency.light,
              borderRadius: '0px 0px 8px 8px',
              pointerEvents: 'none',
              '&::before': {
                ...cornerBasicSx,
                left: -8,
                clipPath: 'path("M8 0H0C4.41828 0 8 3.58172 8 8L8 0Z")',
              },
              '&::after': {
                ...cornerBasicSx,
                right: -8,
                clipPath: 'path("M0 0H8C3.58172 0 0 3.58172 0 8V0Z")',
              },
            };
          }}
        >
          <StopCircleOutlined
            fontSize="small"
            color="error"
            sx={(t) => ({ color: t.palette.emergency.main, m: 0, p: 0 })}
          />
          <Typography variant="subtitle2" sx={(t) => ({ fontWeight: 500, color: t.palette.emergency.main })}>
            Emergency stop enabled
          </Typography>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default EmergencyLayout;
