import { Suspense, type FC } from 'react';
import { Titled } from 'react-titled';
import ProgressSkeleton from 'components/layout/ProgressSkeleton';
import { Metrics } from 'features/metrics/components';

const MetricsPage: FC = () => {
  return (
    <>
      <Titled title={(title) => `AutoRules | ${title}`} />
      <Suspense fallback={<ProgressSkeleton />}>
        <Metrics />
      </Suspense>
    </>
  );
};

export default MetricsPage;
