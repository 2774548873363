import { Controller, useFormContext } from 'react-hook-form';
import type { SxProps } from '@mui/material';
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import type { TextFieldProps } from '@mui/material/TextField/TextField';
import { type ChangeEvent, type FC, type ReactNode, type ReactElement } from 'react';
import { Close } from '@mui/icons-material';
import { isEmpty } from 'lodash-es';

export interface InputProps extends Omit<TextFieldProps, 'name'> {
  name: string;
  containerSx?: SxProps;
  titleSx?: SxProps;
  helperText?: ReactNode;
  inputStyle?: React.CSSProperties;
  error?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  additionalLabel?: ReactElement | null;
  clearButton?: () => void;
  innerLabel?: boolean;
}

const Input: FC<InputProps> = ({
  label,
  innerLabel,
  name,
  required,
  multiline,
  containerSx,
  titleSx,
  inputStyle,
  helperText,
  onChange,
  error,
  additionalLabel,
  clearButton,
  ...restProps
}) => {
  const { control, formState, trigger } = useFormContext();
  const formError = formState.errors[name];

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const { onChange: fieldChange } = field;
        return (
          <Box sx={containerSx}>
            {label && !innerLabel && (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="subtitle2" sx={{ mr: additionalLabel ? 1.25 : 0, ...titleSx }}>
                  {label}
                  {required && '*'}
                </Typography>
                {additionalLabel}
              </Box>
            )}
            <Box sx={{ flex: 1 }}>
              <TextField
                sx={{ display: 'block' }}
                variant="outlined"
                fullWidth
                {...field}
                {...restProps}
                {...(innerLabel && label ? { label } : null)}
                error={error || !!formError}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  fieldChange(e);
                  onChange ? onChange(e) : null;
                  trigger(name);
                }}
                multiline={multiline}
                inputProps={{
                  style: { alignItems: 'start', borderRadius: 0, ...inputStyle },
                }}
                InputProps={{
                  sx: {
                    'input::-webkit-inner-spin-button, input::-webkit-outer-spin-button': { appearance: 'none' },
                    '.MuiInputBase-input': { borderRadius: 0 },
                  },
                  inputProps: { min: 0 },
                  endAdornment: clearButton ? (
                    <InputAdornment position="end" sx={{ visibility: !isEmpty(field.value) ? 'visible' : 'hidden' }}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          clearButton();
                          field.onChange({ target: { value: '' } });
                        }}
                        sx={{ p: 0, width: 22, height: 22 }}
                      >
                        <Close sx={{ fontSize: 22 }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
                helperText={!formError?.message ? helperText : null}
                InputLabelProps={{
                  sx: {
                    '&:not(.Mui-focused):not(.MuiFormLabel-filled)': { transform: 'translate(14px, 8px) scale(1)' },
                  },
                }}
              />
              {formError?.message && (
                <Typography variant="subtitle1" fontSize={12} sx={{ mt: '3px', pl: 1.8 }} color="error">
                  {formError.message as string}
                </Typography>
              )}
            </Box>
          </Box>
        );
      }}
    />
  );
};

export default Input;
