import { CircularProgress, MenuItem, MenuList, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import useGroups from '../hooks/useGroups';
import { useAddMetricsToGroups } from '../api/addMetricToGroup';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';

interface MetricMenuGroups {
  metricId: number;
  closeMenus: () => void;
}

interface MenuListItemProps extends MetricMenuGroups {
  id: number;
  name: string;
}

const MenuListItem: FC<MenuListItemProps> = ({ closeMenus, metricId, id, name }) => {
  const { onShowAlert, onShowInfoAlert } = useEnqueueSnackbar();
  const addMetricsToGroups = useAddMetricsToGroups({
    config: {
      onSuccess: () => {
        onShowAlert('Successfully added');
        closeMenus();
      },
      onError: (e) => {
        console.log(e.response?.status);
        onShowInfoAlert(`${e.cause ? (e.cause?.message as string) : 'Internal Error'}`);
        closeMenus();
      },
    },
  });

  const handleAddToGroup = useCallback(
    (groupId: number) => {
      addMetricsToGroups.mutate({
        metrics_ids: [metricId],
        groups_ids: [groupId],
      });
    },
    [metricId, addMetricsToGroups]
  );
  return (
    <MenuItem
      disabled={addMetricsToGroups.isPending}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onClick={() => handleAddToGroup(id)}
    >
      <Typography
        sx={(t) => ({
          fontSize: t.typography.body2.fontSize,
          p: 0,
          m: 0,
          color: t.palette.text.primary,
        })}
      >
        {name}
      </Typography>
      {addMetricsToGroups.isPending && <CircularProgress size={18} />}
    </MenuItem>
  );
};

const MetricMenuGroups: FC<MetricMenuGroups> = ({ metricId, closeMenus }) => {
  const { data: groups } = useGroups({
    name: undefined,
  });

  return (
    <MenuList sx={{ py: 0 }}>
      {groups.groups.map(({ id, name }) => (
        <MenuListItem key={`menu:${id}.${name}`} name={name} id={id} metricId={metricId} closeMenus={closeMenus} />
      ))}
    </MenuList>
  );
};

export default MetricMenuGroups;
