import type { UserRole } from '../api';
import { useMe } from '../api';
import type { Project } from '../../projects';

export const PROJECT_DEPENDANT_ROLES: UserRole[] = ['user'];

type Entities = 'rules' | 'metrics' | 'users' | 'general';
export type Actions =
  | 'read'
  | 'update'
  | 'delete'
  | 'create'
  | 'create-to-some'
  | 'rule_start-stop'
  | 'rule_archive'
  | 'rule_run'
  | 'rule_bulk-actions'
  | 'rule_add-to-flow'
  | 'add-to-group'
  | 'remove-from-group'
  | 'emergency-mode';

type Access = Record<Entities, Actions[]>;

const fullMetricsAccess: Actions[] = ['read', 'update', 'delete', 'create', 'add-to-group', 'remove-from-group'];
const fullRuleAccess: Actions[] = [
  'read',
  'update',
  'delete',
  'create',
  'create-to-some',
  'rule_start-stop',
  'rule_archive',
  'rule_run',
  'rule_bulk-actions',
  'rule_add-to-flow',
];

const useMyAccess = (project?: Pick<Project, 'id'>): Access | undefined => {
  const { data: me, isPending } = useMe();

  if (isPending) return undefined;
  if (!me?.role) return { metrics: [], users: [], rules: [], general: [] };

  if (me.role === 'supervisor') {
    return { metrics: fullMetricsAccess, rules: fullRuleAccess, users: [], general: ['emergency-mode'] };
  }
  if (me.role === 'admin') {
    return {
      metrics: fullMetricsAccess,
      rules: fullRuleAccess,
      users: ['read', 'create', 'update', 'delete'],
      general: ['emergency-mode'],
    };
  }

  if (project && me.projects?.map((prj) => prj.id).includes(project.id)) {
    return { metrics: fullMetricsAccess, rules: fullRuleAccess, users: [], general: [] };
  }
  return { metrics: ['read'], rules: ['read', 'create-to-some'], users: [], general: [] };
};

export default useMyAccess;
