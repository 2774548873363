import { Alert, Box, Button, CircularProgress, IconButton, Skeleton, Typography } from '@mui/material';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { useModal } from 'hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { metricCreate } from '../validation/metricCreate.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/common/Input';
import { Suspense, useCallback, useEffect } from 'react';
import { useCreateMetric } from '../api/createMetric';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';
import useGroups from '../hooks/useGroups';
import Select from 'components/common/Select';
import useListParams from 'hooks/useListParams';
import { isUndefined } from 'lodash-es';
import { MetricDrawer } from './MetricsComponents';
import { useLocation, useNavigate } from 'react-router';
import useAccessModify from '../hooks/useAccessModify';

const GroupsList = () => {
  const { options: groups } = useGroups({
    name: undefined,
  });

  return (
    <Select
      label="Groups"
      name="groups_ids"
      size="small"
      multiple
      selectAll={false}
      options={groups}
      disabled={!groups.length}
    />
  );
};

const MetricCreateComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { project, newMetric, setNewMetric } = useListParams();
  const { onShowAlert } = useEnqueueSnackbar();
  const { open, close, isOpen } = useModal();

  const isShowFormValues = project !== 'all_projects' && project !== '0';

  const handleOpen = useCallback(() => {
    if (location.pathname !== '/metrics') {
      const params = Object.fromEntries(new URLSearchParams(location.search));
      const navParams = {
        ...params,
        newMetric: '1',
      };
      navigate(`.?${new URLSearchParams(navParams).toString()}`);
      return;
    } else {
      setNewMetric(true);
    }
  }, [location, navigate, setNewMetric]);

  const handleClose = useCallback(() => {
    setNewMetric(false);
  }, [setNewMetric]);

  useEffect(() => {
    if (newMetric && location.pathname === '/metrics') {
      open();
    } else {
      close();
    }
  }, [open, close, location, newMetric]);

  const formMethods = useForm({
    resolver: yupResolver(metricCreate),
    defaultValues: {
      name: '',
      description: '',
      value: '',
      groups_ids: [],
    },
  });

  const { handleSubmit, getValues, reset, setError } = formMethods;

  const createMetric = useCreateMetric({
    config: {
      onSuccess: () => {
        onShowAlert('New metric added');
        reset();
        handleClose();
      },
      onError: (err) => {
        setError('name', { message: err.cause?.message }, { shouldFocus: true });
      },
    },
  });

  const onSubmit = useCallback(() => {
    const { name, description, value, groups_ids } = getValues();
    createMetric.mutate({
      name,
      description: description ?? null,
      value: !isUndefined(value) ? parseFloat(value) : null,
      project_id: project === 'all_projects' ? null : parseInt(project as string),
      groups_ids: groups_ids ? (groups_ids as number[]) : [],
    });
  }, [createMetric]);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color="info"
        sx={(t) => ({
          '&': {
            width: 40,
            height: 40,
            backgroundColor: t.palette.primary.main,
          },
          '&:hover': {
            backgroundColor: t.palette.primary.dark,
          },
        })}
      >
        <AddIcon />
      </IconButton>
      <MetricDrawer open={isOpen} onClose={handleClose}>
        <FormProvider {...formMethods}>
          <Box sx={{ display: 'flex', flex: 1, flexGrow: 1, flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h2">New metric</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Alert severity="info" color="info">
              New metric will be added to all projects in Wellpilot. After that, it can be edited/deleted only by
              Supervisor or Admin.
            </Alert>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: 3, mb: 6 }}>
                <Input
                  label="Metric name"
                  name="name"
                  helperText={
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="caption">Web format: 'w_metric_name'</Typography>
                      <Typography variant="caption">App format: 'metric_name'</Typography>
                      <Typography variant="caption">No project name</Typography>
                    </Box>
                  }
                  required
                />
                {isShowFormValues ? (
                  <>
                    <Typography variant="body1" fontWeight={400}>
                      Parameters used only in this project:
                    </Typography>
                    <Input label="Value" name="value" type="number" />
                    <Input label="Description" name="description" />
                    <Suspense
                      fallback={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Skeleton
                            width={70}
                            height={22}
                            sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0, mb: 1.5 }}
                          />
                          <Skeleton
                            width={'100%'}
                            height={40}
                            sx={{ transform: 'translateY(0)', borderRadius: '8px', m: 0 }}
                          />
                        </Box>
                      }
                    >
                      <GroupsList />
                    </Suspense>
                  </>
                ) : null}
              </Box>
              <Button
                startIcon={createMetric.isPending ? <CircularProgress size={16} color="inherit" /> : null}
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                disabled={createMetric.isPending}
              >
                Create
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </MetricDrawer>
    </>
  );
};

const MetricCreate = () => {
  const { accessMetrics } = useAccessModify();
  return accessMetrics.accessModify ? <MetricCreateComponent /> : null;
};

export default MetricCreate;
