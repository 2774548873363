import { Box, Grid } from '@mui/material';
import { Suspense, type FC } from 'react';
import TitlePageStyles from 'components/common/TitlePageStyles';
import LogsTable from './LogsTable';
import useLogsQueryParams from '../hooks/useLogsQueryParams';
import FilterValues from './FilterValues';
import Filters from './Filters';
import LogDetailsDrawer from './LogDetailsDrawer';
import { NumberParam, useQueryParam } from 'use-query-params';
import LogsTableSkeleton from './LogsTableSkeleton';
import FiltersSkeleton from './FiltersSkeleton';
import { FormProvider, useForm } from 'react-hook-form';
import type { FilterFormData } from '../types';

const Logs: FC = () => {
  useLogsQueryParams();
  const [logId, setLogId] = useQueryParam('logId', NumberParam);
  const formMethods = useForm<FilterFormData>({
    defaultValues: {
      weekday: [],
    },
  });

  return (
    <>
      <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'end', mb: 1 }}>
        <TitlePageStyles>Logs</TitlePageStyles>
      </Box>
      <FormProvider {...formMethods}>
        <Box sx={{ display: 'flex', flexGrow: 1, pt: 1, justifyContent: 'start', flexDirection: 'column' }}>
          <Suspense fallback={<FiltersSkeleton />}>
            <Filters />
          </Suspense>
          <FilterValues />
          <Grid container flex={1}>
            <Grid
              item
              container
              sx={{
                flex: 1,
              }}
            >
              <Suspense fallback={<LogsTableSkeleton />}>
                <LogsTable />
                <LogDetailsDrawer
                  open={!!logId}
                  onClose={() => {
                    setLogId(undefined);
                  }}
                />
              </Suspense>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
};

export default Logs;
